import React from "react";
import { Typography } from "interfaces/typography";

interface ButtonProps {
  text: React.ReactNode,
  onClick: React.MouseEventHandler,
  className?: string,
  hoverText?: string,
  disabled?: boolean,
}

/**
 * Action button to do things
 */
export class Button extends React.Component<ButtonProps, {}> {
  render(): JSX.Element {
    const defaultClasses = ("cursor-pointer w-fit h-fit pt-1 pb-1 pl-2 pr-2 opacity-80 flex justify-center items-center text-center rounded-lg "
      + (this.props.disabled ? "bg-gray-100 text-gray-400" : "bg-white hover:opacity-100 hover:bg-slate-200"))
    // Later-defined classes win in CSS, but tailwind could output classes in any order.
    // So to make className override the above defaults, we have to find and delete the overridden classes.
    // The heuristic is just that everything up to the first hyphen is unique,
    // which is wrong for e.g. flex and flex-col but mostly true for padding and color classes.
    // If you need classes that are not unique by first word, explicitly include the erroneously replaced
    // class in props.className---for example, <Button className="flex flex-col" ...>
    const defaultClassesByFirstWord = Object.fromEntries(defaultClasses.split(/\s+/).map(c => [c.split('-', 1)[0], c]))
    for (const c of (this.props.className ?? "").split(/\s+/)) {
      if (!c.length) { continue }
      delete defaultClassesByFirstWord[c.split('-', 1)[0]]
    }
    const mergedClasses = [...Object.values(defaultClassesByFirstWord), this.props.className ?? ""]
    return <div
      onClick={this.props.disabled ? () => {} : this.props.onClick}
      className={mergedClasses.join(" ")}
      style={{'boxShadow': 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}
      title={this.props.hoverText}
    >
      {typeof this.props.text === 'string'
        ? <Typography variant="largeParagraph">{this.props.text}</Typography>
        : this.props.text}
    </div>
  }
}

/**
 * Navigation button. Note: use 〈, 〉,and ↺ as labels
 */
export class NavButton extends React.Component<{enabled: boolean, active: boolean, label: string, onClick: () => void}, {}> {
  render(): JSX.Element {
    // make styling
    let className = 'py-1 px-2 rounded-md drop-shadow-md '
    if (this.props.active)
      className += 'bg-lime-500'
    else if (this.props.enabled)
      className += 'bg-lime-300 hover:bg-lime-400 cursor-pointer'
    else
      className += 'bg-lime-50 text-slate-500'

    // the button is clickable if it enabled and not active
    const onClick = this.props.enabled && this.props.enabled ? this.props.onClick : undefined
    return <div className={className} onClick={onClick}>{this.props.label}</div>
  }
}