import React from "react";
import { Button } from "components/Button";
import { Typography } from "interfaces/typography";
import { MULTIDIALER_HOSTNAME, SHAKEN_STIR } from "cfg/endpoints";
import { simplePost } from "network";
import { getServicesManager } from "services";
import { logInfo } from "core";
import { Loader } from "components/Loader";

const _LOG_SCOPE = '[Trellus][SHAKEN/STIR]'

type FormTextRow = {
    name: string
    display_name: string
    value: string | undefined
    onUpdate: (newValue: string) => any
}

type FormSelectRow = {
    name: string
    display_name: string
    value: string | undefined
    onUpdate: (newValue: string) => any
    choices: [string, string][]
}

type FormRow = FormTextRow | FormSelectRow

interface Props {
    apiKey: string | null
    teamName: string | null
    teamId: string | null
    onSuccess?: () => void
    onFail?: () => void
    onCancel?: () => void
}

type State = {
    loading: boolean
    submissionInProgress: boolean
    submissionReceived: boolean
    showAlert?: boolean

    inputTeamId?: string
    inputBusinessName?: string
    inputStreetAddress?: string
    inputCity?: string
    inputStateOrRegion?: string
    inputPostalCode?: string
    inputIsoCountry?: string
    inputBusinessType?: string
    inputBusinessIndustry?: string
    inputBusinessRegistrationAuthority?: string
    inputBusinessRegistrationNumber?: string
    inputBusinessRegionsOfOperation?: string
    inputWebsiteUrl?: string
    inputRepresentativeFirstName?: string
    inputRepresentativeLastName?: string
    inputRepresentativeEmail?: string
    inputRepresentativePhoneNumber?: string
    inputRepresentativeBusinessTitle?: string
    inputRepresentativeJobPosition?: string
}

export class ShakenStirRegistration extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: true,
            submissionInProgress: false,
            submissionReceived: false,
            inputBusinessType: "Corporation",
            inputBusinessIndustry: "AUTOMOTIVE",
            inputBusinessRegistrationAuthority: "EIN",
            inputBusinessRegionsOfOperation: "USA_AND_CANADA",
            inputRepresentativeJobPosition: "Director",
        }
    }

    componentDidMount(): void {
        if (this.props.apiKey !== null) {
            if (this.props.teamId === null) {
                this.setState({loading: false})
            } else {
                this._reloadTeamData().finally(() => this.setState({loading: false}))
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        // eek maybe we should check submissionInProgress before doing any setState
        if (this.props.apiKey !== null && (this.props.apiKey !== prevProps.apiKey || this.props.teamId !== prevProps.teamId)) {
            if (this.props.teamId === null) {
                this.setState({loading: false, submissionInProgress: false, submissionReceived: false})
            } else {
                this.setState({loading: true, submissionInProgress: false, submissionReceived: false})
                this._reloadTeamData().finally(() => this.setState({loading: false}))
            }
        }
        if (this.state.submissionReceived && this.state.submissionReceived !== prevState.submissionReceived) {
            this.props.onSuccess?.()
        }
    }

    async _reloadTeamData() {
        // require api key and team id to load
        if (this.props.apiKey === null || this.props.teamId === null) { return }
        // default business name to team name
        if (this.props.teamName) { this.setState({inputBusinessName: this.props.teamName}) }
        // fetch data from server
        const previousData = await getServicesManager().getCustomerProfileData(this.props.teamId)
        if (previousData === null) { return }
        // if successful, fill all form fields
        this.setState({
            inputBusinessName: previousData.business_info?.attributes.business_name,
            inputBusinessType: previousData.business_info?.attributes.business_type,
            inputBusinessIndustry: previousData.business_info?.attributes.business_industry,
            inputBusinessRegistrationAuthority: previousData.business_info?.attributes.business_registration_identifier,
            inputBusinessRegistrationNumber: previousData.business_info?.attributes.business_registration_number,
            inputBusinessRegionsOfOperation: previousData.business_info?.attributes.business_regions_of_operation,
            inputWebsiteUrl: previousData.business_info?.attributes.website_url,
            inputStreetAddress: previousData.address?.street,
            inputCity: previousData.address?.city,
            inputStateOrRegion: previousData.address?.region ?? "",
            inputPostalCode: previousData.address?.postal_code,
            inputIsoCountry: previousData.address?.iso_country,
            inputRepresentativeFirstName: previousData.authorized_representative?.attributes.first_name,
            inputRepresentativeLastName: previousData.authorized_representative?.attributes.last_name,
            inputRepresentativeEmail: previousData.authorized_representative?.attributes.email,
            inputRepresentativePhoneNumber: previousData.authorized_representative?.attributes.phone_number,
            inputRepresentativeBusinessTitle: previousData.authorized_representative?.attributes.business_title,
            inputRepresentativeJobPosition: previousData.authorized_representative?.attributes.job_position,
        })
    }

    _renderLabel(item: FormRow) {
        return <div className="text-right" style={{width: "15em"}}>
            <label htmlFor={`stir_shaken_${item.name}`}>{item.display_name}</label>
        </div>
    }

    _renderField(item: FormRow) {
        return <div key={item.name} className="flex flex-row items-baseline gap-2 w-full">
            {this._renderLabel(item)}
            <div className="flex-grow">
                {'choices' in item
                    ? <select className="w-full p-2 rounded-md" id={`stir_shaken_${item.name}`} value={item.value} onChange={e => item.onUpdate(e.target.value)}>
                            {item.choices.map(([name, display_name]) => <option value={name} key={name}>{display_name}</option>)}
                        </select>
                    : <input className="w-full" id={`stir_shaken_${item.name}`} type="text" value={item.value} onChange={e => item.onUpdate(e.target.value)}/>}
            </div>
        </div>
    }

    _canSubmit(row: FormRow[]): boolean {
        return row.every(x => (x.value ?? "") !== "")
    }

    render() {
        if (this.state.loading) { return <Loader/> }

        const bizFields: FormRow[] = [
            {
                name: "business_name",
                display_name: "Name",
                value: this.state.inputBusinessName,
                onUpdate: x => this.setState({inputBusinessName: x}),
            },
            {
                name: "business_type",
                display_name: "Type",
                value: this.state.inputBusinessType,
                onUpdate: x => this.setState({inputBusinessType: x}),
                choices: [
                    "Sole Proprietorship",
                    "Partnership",
                    "Limited Liability Corporation",
                    "Corporation",
                    "Co-operative",
                    "Non-profit",
                ].map(x => [x, x]),
            },
            {
                name: "business_industry",
                display_name: "Industry",
                value: this.state.inputBusinessIndustry,
                onUpdate: x => this.setState({inputBusinessIndustry: x}),
                choices: [
                    "Automotive",
                    "Agriculture",
                    "Banking",
                    "Consumer",
                    "Education",
                    "Engineering",
                    "Energy",
                    "Oil and Gas",
                    "Fast-moving Consumer Goods",
                    "Financial",
                    "Fintech",
                    "Food and Beverage",
                    "Government",
                    "Healthcare",
                    "Hospitality",
                    "Insurance",
                    "Legal",
                    "Manufacturing",
                    "Media",
                    "Online",
                    "Raw Materials",
                    "Real Estate",
                    "Religion",
                    "Retail",
                    "Jewelry",
                    "Technology",
                    "Telecommunications",
                    "Transportation",
                    "Travel",
                    "Electronics",
                    "Not-for-profit",
                ].map(x => [x.toUpperCase().replaceAll(/[^A-Z0-9]/g, "_"), x]),
            },
            {
                name: "business_registration_authority",
                display_name: "Business Registration Authority",
                value: this.state.inputBusinessRegistrationAuthority,
                onUpdate: x => this.setState({inputBusinessRegistrationAuthority: x}),
                choices: [
                    ["EIN", "EIN"],
                    ["DUNS", "DUNS"],
                ],
            },
            {
                name: "business_registration_number",
                display_name: "Business Registration Number",
                value: this.state.inputBusinessRegistrationNumber,
                onUpdate: x => this.setState({inputBusinessRegistrationNumber: x}),
            },
            {
                name: "business_regions_of_operation",
                display_name: "Regions of Operation",
                value: this.state.inputBusinessRegionsOfOperation,
                onUpdate: x => this.setState({inputBusinessRegionsOfOperation: x}),
                choices: [
                    ['USA_AND_CANADA', 'USA and Canada'],
                    ['AFRICA', 'Africa'],
                    ['ASIA', 'Asia'],
                    ['EUROPE', 'Europe'],
                    ['LATIN_AMERICA', 'Latin America'],
                ],
            },
            {
                name: "website_url",
                display_name: "Website URL",
                value: this.state.inputWebsiteUrl,
                onUpdate: x => this.setState({inputWebsiteUrl: x}),
            },
        ]
        const addressFields: FormRow[] = [
            {name: "street_address", display_name: "Street Address",
             value: this.state.inputStreetAddress, onUpdate: x => this.setState({inputStreetAddress: x})},
            {name: "city", display_name: "City",
             value: this.state.inputCity, onUpdate: x => this.setState({inputCity: x})},
            {name: "state_or_region", display_name: "2-letter State or Region",
             value: this.state.inputStateOrRegion, onUpdate: x => this.setState({inputStateOrRegion: x})},
            {name: "postal_code", display_name: "Postal Code",
             value: this.state.inputPostalCode, onUpdate: x => this.setState({inputPostalCode: x})},
            {name: "iso_country", display_name: "2-letter Country Code",
             value: this.state.inputIsoCountry, onUpdate: x => this.setState({inputIsoCountry: x})},
        ]
        const repFields: FormRow[] = [
            {name: "representative_first_name", display_name: "First Name",
             value: this.state.inputRepresentativeFirstName, onUpdate: x => this.setState({inputRepresentativeFirstName: x})},
            {name: "representative_last_name", display_name: "Last Name",
             value: this.state.inputRepresentativeLastName, onUpdate: x => this.setState({inputRepresentativeLastName: x})},
            {name: "representative_email", display_name: "E-mail Address",
             value: this.state.inputRepresentativeEmail, onUpdate: x => this.setState({inputRepresentativeEmail: x})},
            {name: "representative_phone_number", display_name: "Phone Number",
             value: this.state.inputRepresentativePhoneNumber, onUpdate: x => this.setState({inputRepresentativePhoneNumber: x})},
            {name: "representative_business_title", display_name: "Job Title",
             value: this.state.inputRepresentativeBusinessTitle, onUpdate: x => this.setState({inputRepresentativeBusinessTitle: x})},
            {
                name: "representative_job_position",
                display_name: "Job Level",
                value: this.state.inputRepresentativeJobPosition,
                onUpdate: x => this.setState({inputRepresentativeJobPosition: x}),
                choices: [
                    ["Director", "Director"],
                    ["GM", "GM"],
                    ["VP", "VP"],
                    ["CEO", "CEO"],
                    ["CFO", "CFO"],
                    ["General Counsel", "General Counsel"],
                ],
            },
        ]
        const allFields: FormRow[] = [...bizFields, ...addressFields, ...repFields]
        const canSubmit = this._canSubmit(allFields)
        const submitDisabled = this.state.submissionInProgress || this.state.submissionReceived || !canSubmit
        return <div className='flex flex-col items-center gap-2'>
            {this.state.showAlert && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <strong className="font-bold">Error:</strong>
                <span className="block sm:inline">Please fill out all fields before submitting.</span>
                </div>}
            <Typography variant="h2">SHAKEN/STIR enrollment for {this.props.teamName}</Typography>

            <Typography variant="h3">About the company</Typography>
            {bizFields.map(x => this._renderField(x))}

            <Typography variant="h3">Postal Address</Typography>
            {addressFields.map(x => this._renderField(x))}

            <Typography variant="h3">Authorized Representative</Typography>
            {repFields.map(x => this._renderField(x))}

            <div className="flex flex-row items-center justify-center w-full gap-2">
                <Button text={<>Cancel</>} className="grow h-8 bg-red-600 hover:bg-red-400 text-white font-bold" onClick={() => this.props.onCancel?.()} />
                <Button
                    text={<>{this.state.submissionInProgress ? <Loader color="#fff"/>
                             : this.state.submissionReceived ? "✔"
                             : canSubmit ? "Submit"
                             : "Please fill out all fields"}</>}
                    className={("grow h-8 text-white font-bold") + (submitDisabled ? " bg-gray-400" : " bg-green-600 hover:bg-green-400")}
                    disabled={submitDisabled}
                    onClick={async () => {
                        if (!canSubmit) {
                            this.setState({'showAlert': true})
                            setTimeout(() => this.setState({'showAlert': false}), 5000)
                            return
                        }

                        const payload = Object.fromEntries(allFields.map(x => [x.name, x.value ?? ""]))
                        payload['team_id'] = this.props.teamId ?? ""
                        payload['api_key'] = this.props.apiKey ?? ""
                        this.setState({submissionInProgress: true, submissionReceived: false})
                        try {
                            await simplePost(`https://${MULTIDIALER_HOSTNAME}/${SHAKEN_STIR}`, payload)
                            this.setState({submissionReceived: true})
                        } catch (e) {
                            logInfo(`${_LOG_SCOPE} unexpected error submitting profile`, e)
                        }
                        this.setState({submissionInProgress: false})
                    }}
                />
            </div>
        </div>
    }
}
