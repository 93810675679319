import React, { useState, useRef, useEffect } from 'react';
import { Check, ChevronDown, X, Search, ChevronRight } from 'lucide-react';

interface Option {
  value: string;
  label: string;
  children?: Option[];
}

interface HierarchicalSelectProps {
  options: Option[];
  value: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
}

export function SimplifiedHierarchicalSelect({ options, value, onChange, placeholder = 'Select options...' }: HierarchicalSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const toggleGroup = (e: React.MouseEvent, groupValue: string) => {
    e.stopPropagation();
    setExpandedGroups(prev =>
      prev.includes(groupValue)
        ? prev.filter(v => v !== groupValue)
        : [...prev, groupValue]
    );
  };

  const isGroupSelected = (option: Option): boolean => {
    if (!option.children) return value.includes(option.value);
    return value.includes(option.value);
  };

  const isGroupPartiallySelected = (option: Option): boolean => {
    if (!option.children) return false;
    if (value.includes(option.value)) return false;
    return option.children.some(child => 
      value.includes(child.value) || (child.children && (isGroupSelected(child) || isGroupPartiallySelected(child)))
    );
  };

  const getAllChildValues = (option: Option): string[] => {
    if (option.children) {
      return [option.value];
    }
    return [option.value];
  };

  const toggleOption = (option: Option) => {
    if (option.children) {
      const childValues = getAllChildValues(option);
      const allSelected = isGroupSelected(option);
      
      const newValue = allSelected
        ? value.filter(v => !childValues.includes(v))
        : [...new Set([...value, ...childValues])];
      
      onChange(newValue);
    } else {
      const newValue = value.includes(option.value)
        ? value.filter(v => v !== option.value)
        : [...value, option.value];
      onChange(newValue);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsOpen(false);
    }
  };

  const findSelectedGroups = (opts: Option[]): Option[] => {
    const selected: Option[] = [];
    
    const traverse = (options: Option[]) => {
      options.forEach(option => {
        if (option.children) {
          if (isGroupSelected(option)) {
            selected.push(option);
          } else if (isGroupPartiallySelected(option)) {
            traverse(option.children);
          }
        } else if (value.includes(option.value)) {
          selected.push(option);
        }
      });
    };
    
    traverse(opts);
    return selected;
  };

  const matchesSearch = (option: Option): boolean => {
    if (option.label.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    if (option.children) {
      return option.children.some(child => matchesSearch(child));
    }
    return false;
  };

  const shouldShowOption = (option: Option): boolean => {
    if (search === '') return true;
    return matchesSearch(option);
  };

  const renderOption = (option: Option, level = 0) => {
    if (!shouldShowOption(option)) return null;

    const isSelected = option.children ? isGroupSelected(option) : value.includes(option.value);
    const isPartiallySelected = option.children && isGroupPartiallySelected(option);
    const isExpanded = expandedGroups.includes(option.value);
    const showChildren = option.children && (isExpanded || search !== '');

    return (
      <div key={option.value}>
        <div
          className={`px-2 py-1.5 cursor-pointer flex items-center gap-1.5 hover:bg-gray-50 text-sm ${
            option.label.toLowerCase().includes(search.toLowerCase()) ? 'bg-blue-50/50' : ''
          }`}
          style={{ paddingLeft: `${level * 12 + 8}px` }}
          onClick={() => toggleOption(option)}
        >
          {option.children && (
            <ChevronRight
              size={14}
              className={`shrink-0 transform transition-transform ${showChildren ? 'rotate-90' : ''}`}
              onClick={(e) => toggleGroup(e, option.value)}
            />
          )}
          <div className={`shrink-0 w-4 h-4 border rounded flex items-center justify-center ${
            isSelected ? 'bg-blue-500 border-blue-500' : 
            isPartiallySelected ? 'bg-blue-200 border-blue-200' : 'border-gray-300'
          }`}>
            {(isSelected || isPartiallySelected) && <Check size={12} className="text-white" />}
          </div>
          <span className="truncate">{option.label}</span>
        </div>
        {option.children && showChildren && (
          <div>
            {option.children.map(child => renderOption(child, level + 1))}
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (search !== '') {
      setExpandedGroups(options.map(option => option.value));
    }
  }, [search, options]);

  const selectedItems = findSelectedGroups(options);

  return (
    <div className="relative w-full" ref={containerRef}>
      <div
        className="border rounded-lg bg-white px-2 py-1.5 min-h-[38px] cursor-pointer flex flex-wrap gap-1 items-center"
        onClick={() => {
          setIsOpen(!isOpen);
          setTimeout(() => inputRef.current?.focus(), 0);
        }}
      >
        <div className="flex flex-wrap gap-1 items-center flex-1 min-w-0">
          {selectedItems.length > 0 ? (
            selectedItems.map(item => (
              <span
                key={item.value}
                className="bg-blue-100 text-blue-800 rounded px-1.5 py-0.5 text-sm flex items-center gap-1 max-w-full"
              >
                <span className="truncate">{item.label}</span>
                <X
                  size={14}
                  className="shrink-0 cursor-pointer hover:text-blue-600"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleOption(item);
                  }}
                />
              </span>
            ))
          ) : (
            <span className="text-gray-500 truncate">{placeholder}</span>
          )}
        </div>
        <ChevronDown size={18} className="shrink-0 text-gray-400 ml-auto" />
      </div>

      {isOpen && (
        <div className="absolute z-50 w-full mt-1 bg-white border rounded-lg shadow-lg">
          <div className="p-1.5 border-b">
            <div className="relative">
              <Search size={18} className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                ref={inputRef}
                type="text"
                className="w-full pl-7 pr-2 py-1.5 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Search options..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className="max-h-60 overflow-auto">
            {options.map(option => renderOption(option))}
          </div>
        </div>
      )}
    </div>
  );
}