'use client'

import React, { useState, useRef, useEffect, KeyboardEvent, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { Check, ChevronDown, ChevronRight, X } from 'lucide-react'
import { HierarchicalOption, ItemOption, GroupOption, OptionType } from './cfg'


type HierarchicalMultiSelectProps = {
  options: HierarchicalOption[]
  onChange: (selectedOptions: ItemOption[]) => void
  selectedOptions?: ItemOption[]
  placeholder?: string
}

function isGroup(option: HierarchicalOption): option is GroupOption {
  return option.type === OptionType.GROUP
}

function getAllItems(options: HierarchicalOption[]): ItemOption[] {
  return options.reduce<ItemOption[]>((acc, option) => {
    if (isGroup(option)) {
      return [...acc, ...getAllItems(option.children)]
    }
    return [...acc, option]
  }, [])
}

function getParentGroups(options: HierarchicalOption[]): Map<string, GroupOption[]> {
  const parentMap = new Map<string, GroupOption[]>()
  
  function traverse(option: HierarchicalOption, parents: GroupOption[] = []) {
    if (isGroup(option)) {
      option.children.forEach(child => {
        if (isGroup(child)) {
          traverse(child, [...parents, option])
        } else {
          parentMap.set(child.value, parents)
        }
      })
    } else {
      parentMap.set(option.value, [])
    }
  }
  
  options.forEach(option => traverse(option))
  return parentMap
}

function calculateDropdownPosition(
  triggerElement: HTMLElement,
  dropdownElement: HTMLElement,
  padding: number = 8
) {
  const viewportHeight = window.innerHeight
  const triggerRect = triggerElement.getBoundingClientRect()
  const dropdownRect = dropdownElement.getBoundingClientRect()

  const spaceBelow = viewportHeight - triggerRect.bottom - padding
  const spaceAbove = triggerRect.top - padding

  let position: 'bottom' | 'top' = 'bottom'
  let maxHeight = Math.min(300, spaceBelow)

  if (spaceBelow < 100 && spaceAbove > spaceBelow) {
    position = 'top'
    maxHeight = Math.min(300, spaceAbove)
  }

  return {
    position,
    maxHeight,
    width: triggerRect.width,
    left: triggerRect.left + window.scrollX,
    top: position === 'bottom' ? triggerRect.bottom + window.scrollY : undefined,
    bottom: position === 'top' ? window.innerHeight - triggerRect.top + window.scrollY : undefined
  }
}

export function HierarchicalMultiSelect({
  options,
  onChange,
  selectedOptions = [],
  placeholder = 'Select options...',
}: HierarchicalMultiSelectProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set())
  const [inputValue, setInputValue] = useState('')
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [highlightedIndex, setHighlightedIndex] = useState(-1)
  const [isFiltering, setIsFiltering] = useState(false)
  const [dropdownStyles, setDropdownStyles] = useState({
    position: 'bottom' as 'bottom' | 'top',
    maxHeight: 300,
    width: 0,
    left: 0,
    top: 0,
    bottom: undefined as number | undefined
  })

  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const listRef = useRef<HTMLDivElement>(null)
  const optionRefs = useRef<(HTMLDivElement | null)[]>([])
  const animationFrameRef = useRef<number>()

  // Memoize the parent groups mapping
  const parentGroups = useMemo(() => getParentGroups(options), [options])

  // Calculate selected groups based on selected options
  const selectedGroups = useMemo(() => {
    const groups = new Map<string, GroupOption>()
    
    function findSelectedGroups(option: HierarchicalOption, selectedItems: Set<string>) {
      if (isGroup(option)) {
        const allChildren = getAllItems([option])
        if (allChildren.every(child => selectedItems.has(child.value))) {
          groups.set(option.value, option)
        }
        option.children.forEach(child => {
          if (isGroup(child)) {
            findSelectedGroups(child, selectedItems)
          }
        })
      }
    }
    
    const selectedSet = new Set(selectedOptions.map(opt => opt.value))
    options.forEach(option => findSelectedGroups(option, selectedSet))
    
    return groups
  }, [selectedOptions, options])

  const [portalNode] = useState(() => {
    if (typeof document !== 'undefined') {
      const node = document.createElement('div')
      node.style.position = 'fixed'
      node.style.left = '0'
      node.style.top = '0'
      node.style.width = '100%'
      node.style.height = '0'
      node.style.overflow = 'visible'
      node.style.zIndex = '9999'
      return node
    }
    return null
  })

  useEffect(() => {
    if (portalNode) {
      document.body.appendChild(portalNode)
      return () => {
        document.body.removeChild(portalNode)
      }
    }
  }, [portalNode])

  const updatePosition = () => {
    if (!containerRef.current || !listRef.current || !isOpen) return

    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current)
    }

    animationFrameRef.current = requestAnimationFrame(() => {
      const newStyles = calculateDropdownPosition(containerRef.current!, listRef.current!)
      //@ts-ignore
      setDropdownStyles(newStyles)
    })
  }

  useEffect(() => {
    if (isOpen) {
      updatePosition()
      window.addEventListener('scroll', updatePosition, true)
      window.addEventListener('resize', updatePosition)
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current)
      }
      window.removeEventListener('scroll', updatePosition, true)
      window.removeEventListener('resize', updatePosition)
    }
  }, [isOpen])

  useEffect(() => {
    if (isFiltering) {
      const filtered = options.map(option => {
        if (isGroup(option)) {
          const filteredChildren = option.children.filter(child => 
            child.label.toLowerCase().includes(inputValue.toLowerCase())
          )
          return filteredChildren.length > 0 ? { ...option, children: filteredChildren } : null
        }
        return option.label.toLowerCase().includes(inputValue.toLowerCase()) ? option : null
      }).filter((option): option is HierarchicalOption => option !== null)
      setFilteredOptions(filtered)
    } else {
      setFilteredOptions(options)
    }
  }, [inputValue, options, isFiltering])

  const toggleGroup = (group: GroupOption, e: React.MouseEvent) => {
    e.stopPropagation()
    setExpandedGroups(prev => {
      const next = new Set(prev)
      if (next.has(group.value)) {
        next.delete(group.value)
      } else {
        next.add(group.value)
      }
      return next
    })
    setTimeout(updatePosition, 0)
  }

  const toggleOption = (option: ItemOption) => {
    const isSelected = selectedOptions.some(item => item.value === option.value)
    const newSelection = isSelected
      ? selectedOptions.filter(item => item.value !== option.value)
      : [...selectedOptions, option]
    onChange(newSelection)
    setInputValue('')
    setIsFiltering(false)
    inputRef.current?.focus()
    setTimeout(updatePosition, 0)
  }

  const toggleGroupSelection = (group: GroupOption, e: React.MouseEvent) => {
    e.stopPropagation()
    const isSelected = selectedOptions.some(item => item.value === group.value)
    const newSelection = isSelected
      ? selectedOptions.filter(item => item.value !== group.value)
      : [...selectedOptions.filter(item => !getAllItems([group]).some(child => child.value === item.value)), { label: group.label, value: group.value, type: OptionType.GROUP }]
    onChange(newSelection)
    setTimeout(updatePosition, 0)
  }

  const removeOption = (optionValue: string) => {
    if (selectedGroups.has(optionValue)) {
      const group = selectedGroups.get(optionValue)!
      const groupItems = getAllItems([group])
      const newSelection = selectedOptions.filter(item =>
        !groupItems.some(groupItem => groupItem.value === item.value)
      )
      onChange(newSelection)
    } else {
      const newSelection = selectedOptions.filter(item => item.value !== optionValue)
      onChange(newSelection)
    }
    setTimeout(updatePosition, 0)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      setIsOpen(false)
      setHighlightedIndex(-1)
    } else if (e.key === 'Backspace' && inputValue === '' && selectedOptions.length > 0) {
      const lastOption = selectedOptions[selectedOptions.length - 1]
      removeOption(lastOption.value)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        listRef.current &&
        !listRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
        setHighlightedIndex(-1)
        setIsFiltering(false)
        setInputValue('')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const renderOption = (option: HierarchicalOption, level: number = 0) => {
    const paddingLeft = `${level * 16 + 16}px`

    if (isGroup(option)) {
      const isExpanded = expandedGroups.has(option.value)
      const groupItems = getAllItems([option])
      const allSelected = groupItems.every(item => 
        selectedOptions.some(selected => selected.value === item.value)
      )
      const someSelected = !allSelected && groupItems.some(item => 
        selectedOptions.some(selected => selected.value === item.value)
      )

      return (
        <div key={option.value}>
          <div
            className="flex cursor-pointer items-center px-4 py-2.5 text-[13px] hover:bg-gray-50"
            style={{ paddingLeft }}
            onClick={(e) => toggleGroupSelection(option, e)}
          >
            <button
              type="button"
              className="mr-2 p-0.5 hover:bg-gray-100 rounded"
              onClick={(e) => toggleGroup(option, e)}
            >
              <ChevronRight
                size={14}
                className={`text-gray-400 transition-transform ${isExpanded ? 'rotate-90' : ''}`}
              />
            </button>
            <Check
              size={16}
              className={`mr-2.5 ${allSelected ? 'text-blue-600' : someSelected ? 'text-blue-400' : 'invisible'}`}
            />
            <span className="font-medium">{option.label}</span>
          </div>
          {isExpanded && (
            <div>
              {option.children.map(child => renderOption(child, level + 1))}
            </div>
          )}
        </div>
      )
    }

    return (
      <div
        key={option.value}
        //@ts-ignore
        ref={(el) => (optionRefs.current[option.value] = el)}
        className={`flex cursor-pointer items-center px-4 py-2.5 text-[13px] hover:bg-gray-50 ${
          selectedOptions.some((item) => item.value === option.value)
            ? 'text-blue-600'
            : 'text-gray-900'
        }`}
        style={{ paddingLeft }}
        onClick={() => toggleOption(option)}
        role="option"
        aria-selected={selectedOptions.some((item) => item.value === option.value)}
      >
        <Check
          size={16}
          className={`mr-2.5 ${
            selectedOptions.some((item) => item.value === option.value)
              ? 'text-blue-600'
              : 'invisible'
          }`}
          aria-hidden="true"
        />
        {option.label}
      </div>
    )
  }

  const renderSelectedOptions = () => {
    const selectedTags: JSX.Element[] = []
    const handledItems = new Set<string>()

    // First, render selected groups
    Array.from(selectedGroups.values()).forEach(group => {
      selectedTags.push(
        <span
          key={group.value}
          className="inline-flex items-center gap-1 rounded-md bg-blue-50 px-2 py-0.5 text-[13px] text-blue-600"
        >
          {group.label}
          <button
            type="button"
            onClick={() => removeOption(group.value)}
            aria-label={`Remove ${group.label}`}
            className="focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <X size={14} className="cursor-pointer text-blue-600 hover:text-blue-700" />
          </button>
        </span>
      )

      // Mark all items in this group as handled
      getAllItems([group]).forEach(item => handledItems.add(item.value))
    })

    // Then, render remaining individual items that aren't part of a selected group
    selectedOptions.forEach(option => {
      if (!handledItems.has(option.value)) {
        // Check if the item's parent group is selected
        const parents = parentGroups.get(option.value) || []
        const isParentSelected = parents.some(parent => selectedGroups.has(parent.value))
        
        if (!isParentSelected) {
          selectedTags.push(
            <span
              key={option.value}
              className="inline-flex items-center gap-1 rounded-md bg-blue-50 px-2 py-0.5 text-[13px] text-blue-600"
            >
              {option.label}
              <button
                type="button"
                onClick={() => removeOption(option.value)}
                aria-label={`Remove ${option.label}`}
                className="focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <X size={14} className="cursor-pointer text-blue-600 hover:text-blue-700" />
              </button>
            </span>
          )
        }
      }
    })

    return selectedTags
  }

  const dropdownContent = isOpen && portalNode && (
    <div
      ref={listRef}
      className={`absolute overflow-auto rounded-xl border border-gray-200 bg-white shadow-lg ${
        dropdownStyles.position === 'top' ? 'flex flex-col-reverse' : ''
      }`}
      style={{
        maxHeight: `${dropdownStyles.maxHeight}px`,
        width: `${dropdownStyles.width}px`,
        left: `${dropdownStyles.left}px`,
        top: dropdownStyles.position === 'bottom' ? dropdownStyles.top : 'auto',
        bottom: dropdownStyles.position === 'top' ? dropdownStyles.bottom : 'auto',
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {filteredOptions.map(option => renderOption(option))}
      {filteredOptions.length === 0 && (
        <div className="px-4 py-2.5 text-[13px] text-gray-500">No options available</div>
      )}
    </div>
  )

  return (
    <>
      <div className="relative w-full" ref={containerRef}>
        <div
          className="flex min-h-[40px] w-full cursor-text items-center rounded-xl border border-gray-200 bg-white px-3 py-1.5 text-[13px] shadow-sm hover:border-gray-300 focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500"
          onClick={() => {
            setIsOpen(true)
            inputRef.current?.focus()
          }}
        >
          <div className="flex flex-1 flex-wrap gap-1.5">
            {renderSelectedOptions()}
            <input
              ref={inputRef}
              type="text"
              className="flex-1 border-none bg-transparent p-0.5 text-[13px] outline-none placeholder:text-gray-400"
              placeholder={selectedOptions.length === 0 ? placeholder : ''}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value)
                setIsFiltering(true)
                setIsOpen(true)
              }}
              onKeyDown={handleKeyDown}
              onFocus={() => setIsOpen(true)}
            />
          </div>
          <ChevronDown
            size={18}
            className={`ml-2 shrink-0 text-gray-400 transition-transform duration-200 ${
              isOpen ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
        </div>
      </div>
      {portalNode && createPortal(dropdownContent, portalNode)}
    </>
  )
}