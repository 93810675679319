import React, { Fragment } from "react"

// the orientation the label shows up with, e.g. a 'right' label is to the right of a base element
export type LabelOrientation = 'left' | 'top' | 'right' | 'bottom' | 'top left' | 'top right' | 'bottom left' | 'bottom right'

export interface LabelProps {
  labelOrientation: LabelOrientation, 
  labelHeader?: string,
  labelText?: string | JSX.Element,
  labelClasses?: string,
}

/**
 * Label with a pointing triangle
 */
export class Label extends React.Component<LabelProps, {}> {
  render(): JSX.Element {
    // if you want to set a custom background color, put both a bg- and a border- class in labelClasses
    // so that tailwind knows to generate both.
    const bgColor = ((this.props.labelClasses ?? 'border-slate-100'  // border-slate-100 is a hack for tailwind
                      ).split(/\s+/).filter(x => x.startsWith('bg-')).pop() ?? 'bg-slate-100').substr(3)

    // create element containing layout payload
    const cornerStyle = {
      'left': 'rounded-md',
      'top': 'rounded-md',
      'right': 'rounded-md',
      'bottom': 'rounded-md',
      'top left': 'rounded-tl-md rounded-tr-md rounded-bl-md rounded-br-none',
      'top right': 'rounded-tl-md rounded-tr-md rounded-bl-none rounded-br-md',
      'bottom left': 'rounded-tl-md rounded-tr-none rounded-bl-md rounded-br-md',
      'bottom right': 'rounded-tl-none rounded-tr-md rounded-bl-md rounded-br-md',
    }[this.props.labelOrientation]
    const labelElement = (
      <div className={`p-2 ${this.props.labelClasses} bg-${bgColor} ${cornerStyle}`}>
        {this.props.labelHeader && <div className="text-sm font-bold">{this.props.labelHeader}</div>}
        {this.props.labelText && <div className="text-xs w-36">{this.props.labelText}</div>}
      </div>
    )

    // render with a pointing triangle
    switch (this.props.labelOrientation) {
      case 'left':
        return (
          <div className="flex items-center">
            {labelElement}
            <div className={`w-2 border-solid border-${bgColor} border-l-8 border-y-transparent border-y-8 border-r-0`}></div>
          </div>
        )
      case 'top':
        return (
          <div className="flex flex-col items-center">
            {labelElement}
            <div className={`h-2 border-solid border-${bgColor} border-t-8 border-x-transparent border-x-8 border-b-0`}></div>
          </div>
        )
      case 'right':
        return (
          <div className="flex items-center">
            <div className={`border-solid border-${bgColor} border-r-8 border-y-transparent border-y-8 border-l-0`}></div>
            {labelElement}
          </div>
        )
      case 'bottom':
        return (
          <div className="flex flex-col items-center">
            <div className={`border-solid border-${bgColor} border-b-8 border-x-transparent border-x-8 border-t-0`}></div>
            {labelElement}
          </div>
        )
      case 'top left':
      case 'top right':
      case 'bottom left':
      case 'bottom right':
        return labelElement
      default:
        const _exhaustive: never = this.props.labelOrientation
        return _exhaustive
    }
  }
}

// note: the typescript type engine for generic matching is typically not smart enough to match types if T \ LabelProps is nonempty
// Use withHoverLabel<MyComponentProps & LabelProps>(Component) to help it out
export function withHoverLabel<P extends LabelProps> (Component: React.ComponentType<Omit<P, keyof LabelProps>>): React.ComponentType<P> {
  return class extends React.Component<P, {hovering: boolean}> {
    _handleMouseOverBound: () => void
    _handleMouseOutBound: () => void

    constructor(props: P) {
      super(props)
      this.state = {hovering: false}
      this._handleMouseOverBound = this._handleMouseOver.bind(this)
      this._handleMouseOutBound = this._handleMouseOut.bind(this)
    }

    _handleMouseOver(): void {
      this.setState({hovering: true})
    }

    _handleMouseOut(): void {
      this.setState({hovering: false})
    }

    _labelPositioningWrapper(orientation: LabelOrientation): string {
      switch (orientation) {
        case 'left':
          return 'top-1/2 left-0 -translate-x-full -translate-y-1/2'
        case 'top':
          return 'top-0 left-1/2 -translate-x-1/2 -translate-y-full'
        case 'right':
          return 'top-1/2 right-0 translate-x-full -translate-y-1/2'
        case 'bottom':
          return 'bottom-0 left-1/2 -translate-x-1/2 translate-y-full'
        case 'top left':
          return 'top-0 left-0 -translate-x-full -translate-y-full'
        case 'top right':
          return 'top-0 right-0 translate-x-full -translate-y-full'
        case 'bottom left':
          return 'bottom-0 left-0 -translate-x-full translate-y-full'
        case 'bottom right':
          return 'bottom-0 right-0 translate-x-full translate-y-full'
        default:
          const _exhaustive: never = orientation
          return _exhaustive
      }
    }

    render() {
      const {labelHeader, labelText, labelOrientation, labelClasses, ...passThroughProps} = this.props
      return (
        <div className="relative">
          <div onMouseOver={this._handleMouseOverBound} onMouseOut={this._handleMouseOutBound}>
            <Component {...passThroughProps}></Component>
          </div>
          {(this.state.hovering) && (
            <div className={`absolute ${this._labelPositioningWrapper(labelOrientation)} z-10`}>
              <Label labelHeader={labelHeader} labelText={labelText} labelOrientation={labelOrientation} labelClasses={labelClasses}></Label>
            </div>
          )}
        </div>
      )
    }
  }
}

type FragmentProps = {
    children: any,
}

export const HoverWrapper = withHoverLabel<FragmentProps & LabelProps>(Fragment)
