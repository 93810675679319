import { EXTENSION_ID, MESSAGE_TYPES } from 'cfg/endpoints';
import { Loader } from 'components/Loader';
import { DEFAULT_LANGUAGE_SETTING, EMAIL_FREQUENCY_SETTING, PROSPECT_RECORDING_SETTING, SettingsResult, SettingsUpdate } from 'interfaces/services';
import { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { getServicesManager } from 'services';
import { RootState } from 'store';

export enum CHROME_SETTINGS {
  TRELLUS_MODE = 'Lite Mode',
  TRELLUS_SIDE = 'Popup to right',
  EMBEDDED_COACHING = 'Embedded coaching',
  EMBEDDED_COACHING_NOT_HIGHLIGHT = 'Highlight embedded coaching?',
}

export const CHROME_SETTINGS_TO_REQUEST_MESSAGE: {[k in CHROME_SETTINGS]: string} = {
  [CHROME_SETTINGS.TRELLUS_MODE]: MESSAGE_TYPES.EXTERNAL_TO_BACKGROUND_REQUEST_IS_LITE_MODE_SETTING,
  [CHROME_SETTINGS.TRELLUS_SIDE]: MESSAGE_TYPES.EXTERNAL_TO_BACKGROUND_REQUEST_POPUP_DIRECTION_SETTING,
  [CHROME_SETTINGS.EMBEDDED_COACHING]: MESSAGE_TYPES.EXTERNAL_TO_BACKGROUND_REQUEST_IS_EMBEDDED_COACHIG_ENABLED,
  [CHROME_SETTINGS.EMBEDDED_COACHING_NOT_HIGHLIGHT]: MESSAGE_TYPES.EXTERNAL_TO_BACKGROUND_REQUEST_IS_EMBEDDED_COACHING_NO_HIGHLIGHT,
}

export const RESPONSE_TO_CHROME_SETTING_VALUE: {[k in CHROME_SETTINGS]: (response: any) => boolean} = {
  [CHROME_SETTINGS.TRELLUS_MODE]: (response) => response && response['is_lite_mode_setting'],
  [CHROME_SETTINGS.TRELLUS_SIDE]: (response) => response &&  response['is_popup_to_right_setting'],
  [CHROME_SETTINGS.EMBEDDED_COACHING]: (response) => response &&  response['embedded_coaching'],
  [CHROME_SETTINGS.EMBEDDED_COACHING_NOT_HIGHLIGHT]: (response) => response && response['embedded_coaching_no_highlight'],
}

export enum BACKEND_SETTINGS {
  DEFAULT_AUDIO_SETTINGS = 'Persist audio recording',
  REALTIME_TRANSCRIPTION = 'Real-time transcription',
  TRANSCRIPTION_RECORDING = 'Persist transcription',
  LANGUAGE_SETTING = 'Default language',
  BILINGUAL_MODE = 'Bilingual mode',
  EMAIL_SETTINGS = 'Email settings',
  EMAIL_NOTIFICATION_SETTINGS = 'Notify on review changes?',
  DATA_SEARCH_ENABLED = 'Search for Linkedin info?',
  DISABLE_AUTODIALER = 'Disable Autodialer? (WILL ADJUST FOR TEAM)',
}


type SettingsPageProps = {
  isAdmin: boolean | null
}

type Setting = {
  backendSetting?: BACKEND_SETTINGS
  chromeSetting?: CHROME_SETTINGS
}

type SettingSection = {
  settings: Setting[]
  label: string
}

const DEFAULT_LANGUAGE_SETTING_TO_LABEL: {[key in DEFAULT_LANGUAGE_SETTING]: string} = {
  [DEFAULT_LANGUAGE_SETTING.ENGLISH]: 'English',
  [DEFAULT_LANGUAGE_SETTING.SPANISH]: 'Spanish',
  [DEFAULT_LANGUAGE_SETTING.FRENCH]: "French"
}

const EMAIL_FREQUENCY_SETTING_TO_LABEL: {[key in EMAIL_FREQUENCY_SETTING]: string} = {
  [EMAIL_FREQUENCY_SETTING.DAILY]: 'Daily',
  [EMAIL_FREQUENCY_SETTING.TWICE_A_WEEK]: 'Twice a week',
  [EMAIL_FREQUENCY_SETTING.WEEKLY]: 'Weekly',
  [EMAIL_FREQUENCY_SETTING.NONE]: 'Never'
}

const PROSPECT_RECORDING_SETTING_TO_LABEL: {[key in PROSPECT_RECORDING_SETTING]: string} = {
  [PROSPECT_RECORDING_SETTING.ALL]: 'All calls',
  [PROSPECT_RECORDING_SETTING.NONE]: 'No calls',
  [PROSPECT_RECORDING_SETTING.ONE_PARTY_ONLY]: 'All 1-party calls',
  [PROSPECT_RECORDING_SETTING.DIALER]: "Mirror dialer settings"
}

function GeneralSettingsImpl({ isAdmin }: SettingsPageProps) {
  const [chromeSettings, setChromeSettings] = useState<Map<CHROME_SETTINGS, boolean>>(new Map())
  const [settingsResult, setSettingsResult] = useState<SettingsResult | null>(null)
  
  const isChromeEnabled = () => { return chrome !== undefined && chrome.runtime !== undefined && chrome.runtime.sendMessage !== undefined } 
  const [chromeEnabled, setChromeEnabled] = useState<boolean>(isChromeEnabled())
  
  const getChromeSettings = async() => {
    if (!chromeEnabled) return
    for (const setting of Object.values(CHROME_SETTINGS)) {
      try {
        const response = await chrome.runtime.sendMessage(EXTENSION_ID, {
        type: CHROME_SETTINGS_TO_REQUEST_MESSAGE[setting],
        })
        if (!response) {
          setChromeEnabled(false)
          return
        }
        setChromeSettings((prev) => {
          const newMap = new Map(prev)
          newMap.set(setting, RESPONSE_TO_CHROME_SETTING_VALUE[setting](response))
          return newMap
        })
      } catch (error) {
        setChromeEnabled(false)
        return
      }
    }
  }

  useEffect(() => {
    getChromeSettings()
    getServicesManager().getSettings().then((settings) => {
      if (!settings) return
      setSettingsResult(settings)
    })
  }, [])

  const updateChromeSetting = (setting: CHROME_SETTINGS, value: boolean) => { 
    let valueToSet = value
    switch (setting) {
      case CHROME_SETTINGS.TRELLUS_MODE:
        chrome.runtime.sendMessage(EXTENSION_ID, {
          'type': MESSAGE_TYPES.EXTERNAL_TO_BACKGROUND_SET_IS_LITE_MODE, 
          'is_lite_mode_setting': value, 
          'coaching_page': false
        })
        break
      case CHROME_SETTINGS.TRELLUS_SIDE:
        chrome.runtime.sendMessage(EXTENSION_ID, {
          'type': MESSAGE_TYPES.EXTERNAL_TO_BACKGROUND_SET_POPUP_DIRECTION_IS_RIGHT, 
          'is_popup_to_right_setting': value
        })
        break 
      case CHROME_SETTINGS.EMBEDDED_COACHING:
        chrome.runtime.sendMessage(EXTENSION_ID, {
          'type': MESSAGE_TYPES.EXTERNAL_TO_BACKGROUND_SET_IS_EMBEDDED_COACHING_ENABLED, 
          'embedded_coaching': value, 
        })
        break
      case CHROME_SETTINGS.EMBEDDED_COACHING_NOT_HIGHLIGHT:
        valueToSet = !value
        chrome.runtime.sendMessage(EXTENSION_ID, {
          'type': MESSAGE_TYPES.EXTERNAL_TO_BACKGROUND_SET_IS_EMBEDDED_COACHING_NO_HIGHLIGHT, 
          'embedded_coaching_no_highlight': valueToSet, 
        })
        break
    }
    setChromeSettings((prev) => {
      const newMap = new Map(prev)
      newMap.set(setting, valueToSet)
      return newMap
    })
  }
  const renderChromeSetting = (setting: CHROME_SETTINGS) => {
    let value = chromeSettings.get(setting) ?? false
    if (setting === CHROME_SETTINGS.EMBEDDED_COACHING_NOT_HIGHLIGHT) value = (!value) && (chromeSettings.get(CHROME_SETTINGS.EMBEDDED_COACHING) ?? false)
    return <SettingRow label={setting} type="toggle" value={value} setValue={(x) => updateChromeSetting(setting, x as boolean)} />
  }

  const settingIsApplicableForIsTeam = (setting: BACKEND_SETTINGS) => {
    switch (setting) {
      case BACKEND_SETTINGS.DEFAULT_AUDIO_SETTINGS:
      case BACKEND_SETTINGS.REALTIME_TRANSCRIPTION:
      case BACKEND_SETTINGS.TRANSCRIPTION_RECORDING: 
      case BACKEND_SETTINGS.DATA_SEARCH_ENABLED: 
      case BACKEND_SETTINGS.DISABLE_AUTODIALER:
        return true
      default: return false
    }
  }

  const updateSettingsResult = (updates: Map<BACKEND_SETTINGS, string | boolean>) => {
    const settingUpdate: SettingsUpdate = {'is_team': Array.from(updates.keys()).some((key) => settingIsApplicableForIsTeam(key as BACKEND_SETTINGS)) && isAdmin === true}
    for (const [key, value] of updates) {
      switch (key) {
        case BACKEND_SETTINGS.DEFAULT_AUDIO_SETTINGS: settingUpdate['default_prospect_recording'] = value as PROSPECT_RECORDING_SETTING; break
        case BACKEND_SETTINGS.REALTIME_TRANSCRIPTION: settingUpdate['default_prospect_streaming_transcription'] = value as PROSPECT_RECORDING_SETTING; break
        case BACKEND_SETTINGS.TRANSCRIPTION_RECORDING: settingUpdate['default_prospect_transcript_persistence'] = value as PROSPECT_RECORDING_SETTING; break
        case BACKEND_SETTINGS.LANGUAGE_SETTING: settingUpdate['default_language'] = value as DEFAULT_LANGUAGE_SETTING; break
        case BACKEND_SETTINGS.BILINGUAL_MODE: settingUpdate['additional_language'] = value as DEFAULT_LANGUAGE_SETTING; break
        case BACKEND_SETTINGS.EMAIL_SETTINGS: settingUpdate['email_frequency'] = value as EMAIL_FREQUENCY_SETTING; break
        case BACKEND_SETTINGS.DATA_SEARCH_ENABLED: settingUpdate['linkedin_finder_disabled'] = value as boolean; break
        case BACKEND_SETTINGS.EMAIL_NOTIFICATION_SETTINGS: settingUpdate['notify_on_review_status_change'] = value as boolean; break
        case BACKEND_SETTINGS.DISABLE_AUTODIALER: settingUpdate['autodialer_disabled'] = value as boolean; break
    }
  }
  //@ts-ignore
  setSettingsResult((prev) => {
    // remove all undefined update keys
    const prev_copy = {...prev}
    for (const [key, value] of Object.entries(settingUpdate)) {
      if (value === undefined) continue
      // @ts-ignore
      prev_copy[key] = value
    }
    return prev_copy
  })
  getServicesManager().postSettings(settingUpdate)
  }

  const getOpposingLanguage =(setting: DEFAULT_LANGUAGE_SETTING) => {
    switch (setting) {
      case DEFAULT_LANGUAGE_SETTING.ENGLISH: return DEFAULT_LANGUAGE_SETTING.SPANISH
      case DEFAULT_LANGUAGE_SETTING.SPANISH: return DEFAULT_LANGUAGE_SETTING.ENGLISH
      case DEFAULT_LANGUAGE_SETTING.FRENCH: return DEFAULT_LANGUAGE_SETTING.ENGLISH
    }
  }

  const renderBackendSetting = (setting: BACKEND_SETTINGS) => {
    switch (setting) {
      case BACKEND_SETTINGS.DEFAULT_AUDIO_SETTINGS:
      case BACKEND_SETTINGS.REALTIME_TRANSCRIPTION:
      case BACKEND_SETTINGS.TRANSCRIPTION_RECORDING:
        const defaultvalue = setting === BACKEND_SETTINGS.DEFAULT_AUDIO_SETTINGS ? PROSPECT_RECORDING_SETTING.ONE_PARTY_ONLY : PROSPECT_RECORDING_SETTING.ALL
        const setValue = setting === BACKEND_SETTINGS.DEFAULT_AUDIO_SETTINGS ? settingsResult?.default_prospect_recording : setting === BACKEND_SETTINGS.REALTIME_TRANSCRIPTION ? settingsResult?.default_prospect_streaming_transcription : settingsResult?.default_prospect_transcript_persistence 
        const value = setValue ?? defaultvalue
        return <SettingRow label={setting} 
          type="select" 
          options={Object.values(PROSPECT_RECORDING_SETTING).map((v) => {return {label: PROSPECT_RECORDING_SETTING_TO_LABEL[v], value: v}})}
          value={value}
          setValue={(value) => updateSettingsResult(new Map([[setting, value]]))} 
          />
      case BACKEND_SETTINGS.LANGUAGE_SETTING:
        return <SettingRow 
        label={setting} 
        options={Object.values(DEFAULT_LANGUAGE_SETTING).map((v) => {return {label: DEFAULT_LANGUAGE_SETTING_TO_LABEL[v], value: v}})}
        value={settingsResult?.default_language ?? DEFAULT_LANGUAGE_SETTING.ENGLISH}
        setValue={(value) => updateSettingsResult(new Map([[setting, value]]))}
        />
      case BACKEND_SETTINGS.EMAIL_SETTINGS:
        return <SettingRow label={setting} 
          options={Object.values(EMAIL_FREQUENCY_SETTING).map((v) => {return {label: EMAIL_FREQUENCY_SETTING_TO_LABEL[v], value: v}})}
          value={settingsResult?.email_frequency ?? EMAIL_FREQUENCY_SETTING.DAILY}
          setValue={(value) => updateSettingsResult(new Map([[setting, value]]))}
          />
      case BACKEND_SETTINGS.BILINGUAL_MODE:
        return <SettingRow label={setting} 
        type="toggle" 
        value={settingsResult?.additional_language != null && settingsResult?.additional_language !== settingsResult?.default_language} 
        setValue={(value) => updateSettingsResult(new Map([[setting, value ? getOpposingLanguage(settingsResult?.default_language ?? DEFAULT_LANGUAGE_SETTING.ENGLISH) : (settingsResult?.default_language ?? DEFAULT_LANGUAGE_SETTING.ENGLISH) ]]))}
        />
      case BACKEND_SETTINGS.DATA_SEARCH_ENABLED:
        return <SettingRow label={setting} type="toggle" 
        value={!settingsResult?.linkedin_finder_disabled ?? true} 
        setValue={(value) => updateSettingsResult(new Map([[setting, !value]]))}
        />
      case BACKEND_SETTINGS.EMAIL_NOTIFICATION_SETTINGS:
        return <SettingRow 
        label={setting} type="toggle" 
        value={settingsResult?.notify_on_review_status_change ?? false} 
        setValue={(value) => updateSettingsResult(new Map([[setting, value]]))} 
      />
      case BACKEND_SETTINGS.DISABLE_AUTODIALER:
        return <SettingRow 
        label={setting} 
        type="toggle" 
        value={settingsResult?.autodialer_disabled ?? false} 
        setValue={(value) => updateSettingsResult(new Map([[setting, value]]))}
        />
      default:
        return
   }
  }

  const renderSetting = (setting: Setting) => {
    if (setting.chromeSetting) return renderChromeSetting(setting.chromeSetting)
    if (setting.backendSetting) return renderBackendSetting(setting.backendSetting)
    return null
  }

  const renderSettingSection = (section: SettingSection) => {
    return (
      <SettingsSection title={section.label}>
        {section.settings.map((setting) => renderSetting(setting))}
      </SettingsSection>
    )
  }

  const getSettingsSection = () => {
    const extensionSettings: SettingSection = {
      label: 'Extension Settings',
      settings: [
        { chromeSetting: CHROME_SETTINGS.TRELLUS_MODE },
        { chromeSetting: CHROME_SETTINGS.TRELLUS_SIDE },
        { chromeSetting: CHROME_SETTINGS.EMBEDDED_COACHING },
        { chromeSetting: CHROME_SETTINGS.EMBEDDED_COACHING_NOT_HIGHLIGHT },
      ]
    }

    let audioSettings: SettingSection;
    if (isAdmin) {
      audioSettings = {
        label: 'Audio Settings',
        settings: [
          { backendSetting: BACKEND_SETTINGS.DEFAULT_AUDIO_SETTINGS },
          { backendSetting: BACKEND_SETTINGS.TRANSCRIPTION_RECORDING },
          { backendSetting: BACKEND_SETTINGS.REALTIME_TRANSCRIPTION },
          { backendSetting: BACKEND_SETTINGS.LANGUAGE_SETTING },
          { backendSetting: BACKEND_SETTINGS.BILINGUAL_MODE },
        ]
      }
    } else {
      audioSettings = {
        label: 'Audio Settings',
        settings: [
          { backendSetting: BACKEND_SETTINGS.DEFAULT_AUDIO_SETTINGS },
          { backendSetting: BACKEND_SETTINGS.LANGUAGE_SETTING },
          { backendSetting: BACKEND_SETTINGS.BILINGUAL_MODE },
        ]
      }
    }

    let otherSettings: SettingSection
    if (isAdmin) {
      otherSettings = {
        label: 'Other Settings',
        settings: [
          { backendSetting: BACKEND_SETTINGS.EMAIL_SETTINGS },
          { backendSetting: BACKEND_SETTINGS.EMAIL_NOTIFICATION_SETTINGS },
          { backendSetting: BACKEND_SETTINGS.DATA_SEARCH_ENABLED },
          { backendSetting: BACKEND_SETTINGS.DISABLE_AUTODIALER },
        ]
      }
    } else {
      otherSettings = {
        label: 'Other Settings',
        settings: [
          { backendSetting: BACKEND_SETTINGS.EMAIL_SETTINGS },
          { backendSetting: BACKEND_SETTINGS.DATA_SEARCH_ENABLED },
        ]
      }
    }
    if (!chromeEnabled) return [audioSettings, otherSettings]
    return [extensionSettings, audioSettings, otherSettings]  
  }

  if (!settingsResult || isAdmin === null) return <Loader />

  return (
    <div className="h-full w-full bg-gray-50 p-4 sm:p-5 overflow-auto">
        <div className="space-y-6 mx-auto max-w-5xl">
          {getSettingsSection().map((section) => renderSettingSection(section))}
        </div>
    </div>
  )
}

function SettingsSection({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <section className="relative z-10 rounded-lg border border-gray-200 bg-white p-6 shadow-sm">
      <h2 className="mb-6 text-lg font-medium text-gray-800">{title}</h2>
      <div className="space-y-5">{children}</div>
    </section>
  )
}

type SettingRowProps = {
  label: string
  options?: { label: string; value: string }[]
  value: boolean | string | null
  setValue: (value: boolean | string) => void
  type?: 'select' | 'toggle'
}
function SettingRow({ label, options, value, setValue, type = 'select' }: SettingRowProps) {
  return (
    <div className="flex items-center justify-between gap-4">
      <label className="text-sm font-medium text-gray-700">{label}</label>
      {type === 'select' && options && (
        <select
          value={value as string}
          onChange={(e) => setValue(e.target.value)}
          className="rounded-md border border-gray-300 bg-white px-3 py-2 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}
      {type === 'toggle' && (
        <button
          onClick={() => setValue(!value)}
          className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
            value ? 'bg-blue-600' : 'bg-gray-200'
          }`}
        >
          <span
            className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
              value ? 'translate-x-6' : 'translate-x-1'
            }`}
          />
        </button>
      )}
    </div>
  )
}

const ReduxWrapped = connect((state: RootState) => {
  return {
    isAdmin: state.adminWrite.value ? state.adminWrite.value.team_ids.length > 0 : null,
  }
})(GeneralSettingsImpl)

export { ReduxWrapped as GeneralSettings}