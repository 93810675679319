import { searchIcon } from "icons"
import React from "react"

interface Props {
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    value?: string
    placeholder?: string
    defaultValue?: string
}

export class SearchBox extends React.Component<Props, {}> {
    render(): JSX.Element {
        return <div className='relative w-full'>
            <div className='absolute' style={{'top': '50%', 'left': '2px', 'transform': 'translateY(-50%)', 'height': '20px', 'width': '20px'}}>{searchIcon}</div>
            <input
                {...{'placeholder': 'Search', ...this.props}}
                className='w-full'
                type='text'
                style={{'borderRadius': '5px', 'height': '30px', 'paddingLeft': '30px', 'fontFamily': 'Calibri,Arial,Helvetica,sans-serif', 'fontSize': '12px'}} />
        </div>
    }
}
