import { ValueType } from "cfg/column";
import { MetricDefinition, MetricFormat, OVERALL_SCORE_TYPE } from "cfg/score";
import { Typography } from "interfaces/typography";
import React from "react";

type DebouncerScoreInfoProps = {
    title: string
    content: JSX.Element
    customStyle?: React.CSSProperties
    onHoverTextInfo?: string
}

type DebouncerScoreInfoState = {
    hovered: boolean
}

const CHECKMARK_SVG = <svg width="8" viewBox="0 0 436 322" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M150.159 250.724L400.312 0.246094L435.692 35.5784L150.181 321.458L0.324219 171.601L35.6796 136.244L150.159 250.724Z" fill="currentColor"/>
</svg>


const X_MARK_SVG = <svg width="8" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M494.957 400.018L792.088 102.906C802.637 92.3553 802.637 75.2814 792.088 64.734L735.302 7.92708C730.244 2.87298 723.381 0.0273438 716.217 0.0273438C709.052 0.0273438 702.189 2.87298 697.131 7.92708L400 305.061L102.87 7.92708C97.8122 2.87298 90.9489 0.0273438 83.7836 0.0273438C76.62 0.0273438 69.7551 2.87298 64.6993 7.92708L7.91319 64.734C-2.63773 75.2814 -2.63773 92.3553 7.91319 102.906L305.044 400.02L7.94271 697.096C-2.60301 707.647 -2.60301 724.721 7.94271 735.269L64.7322 792.075C69.7863 797.13 76.6513 799.975 83.8166 799.975C90.9819 799.975 97.8452 797.13 102.904 792.075L399.999 494.976L697.095 792.075C702.152 797.13 709.015 799.975 716.181 799.975C723.348 799.975 730.211 797.13 735.265 792.075L792.055 735.269C802.6 724.721 802.6 707.647 792.055 697.096L494.957 400.018Z" fill="currentColor"/>
</svg>


export class DebouncerScoreInfo extends React.Component<DebouncerScoreInfoProps, DebouncerScoreInfoState> {
    constructor(props: DebouncerScoreInfoProps) {
        super(props)
        this.state = {hovered: false}
    }

    render() {
        return (
        <div 
        onMouseEnter={() => this.setState({hovered: true})}
        onMouseLeave={() => this.setState({hovered: false})}
        className="flex flex-col">
            <div className="flex flex-row gap-1 items-center p-1"
            style={{
                cursor: this.props.onHoverTextInfo ? 'pointer' : 'default',
                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                borderRadius: '8px',
                ...(this.props.customStyle ?? {})        
            }}
            >
                <Typography fontWeight={550}>
                    {`${this.props.title}:`}
                </Typography>
                {this.props.content}
            </div>
            {this.props.onHoverTextInfo ? <div className="relative" style={{display: this.state.hovered ? 'flex' : 'flex'}} >
                <div style={{
                    display: this.state.hovered ? 'flex' : 'none',
                    position: 'absolute',
                    zIndex: 100,
                    backgroundColor: 'white',
                    padding: '8px',
                    borderRadius: '8px',
                    minWidth: '150px',
                    maxWidth: '200px',
                    width: 'fit-content',
                    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                }}>
                    <Typography variant="largeParagraph">
                        {this.props.onHoverTextInfo}
                    </Typography>
                </div>
            </div> : null}
        </div>)
    }

}

type ScoreProps = {
    score: MetricFormat
}
    

export class Score extends React.Component<ScoreProps> {
    constructor(props: ScoreProps) {
        super(props)
    }

    renderScoreContent() {
        return (
        <Typography>
            {`${this.props.score.overall_score ?? 0}${this.props.score.overall_score_type === OVERALL_SCORE_TYPE.PERCENTAGE ? "%" : ""}`}
        </Typography>
        )
    }

    _getScoreColor() {
        if (this.props.score.overall_score == null) return 'lightgray'
        const number = parseInt(this.props.score.overall_score.toString())
        if (number < 25) return '#ff000052'
        if (number < 40) return '#efff0052'
        if (number < 60) return '#7fcb6652'
        return '#55b5369c'
    }

    renderSuccessStatus(v: boolean) {
        return <div 
        className={("w-4 h-4 opacity-75 p-0.5 flex justify-center items-center text-center")} 
        style={{'borderRadius': '50%', 'backgroundColor': v ? 'green' : 'red', 'color': 'white'}}>
            {v ? CHECKMARK_SVG : X_MARK_SVG}
        </div>
    }

    renderBox(title: string, content: JSX.Element, customBgColor?: string, onHoverTextInfo?: string) {
        return <DebouncerScoreInfo title={title} content={content} customStyle={{backgroundColor: customBgColor}} onHoverTextInfo={onHoverTextInfo}/>
    }

    renderMetricContent(m: MetricDefinition) {
        switch (m.metric_type) {
            case ValueType.FLOAT:
                return <Typography>{`${m.value}/${m.max_value}`}</Typography>
            case ValueType.BOOLEAN:
                return this.renderSuccessStatus(Boolean(m.value))
            default:
                return <Typography>{m.value}</Typography>
        }
    }

    renderMetricInfo(m: MetricDefinition) {
        const content = this.renderMetricContent(m)
        return this.renderBox(m.metric_name, content, undefined, m.text)
    }

    render() {
        if (!this.props.score.score_is_applicable) return null
        return (<div className='flex flex-row items-center gap-1 w-full flex-grow flex-wrap'> 
            {this.props.score.overall_score === null ? null : this.renderBox(this.props.score.overall_score_name ?? 'Score', this.renderScoreContent(), this.props.score.overall_score_type === OVERALL_SCORE_TYPE.PERCENTAGE ? this._getScoreColor() : undefined, this.props.score.overall_explanation ?? undefined)}
            {this.props.score.metrics.map((m: MetricDefinition) => this.renderMetricInfo(m))}
        </div>)
    }
}

