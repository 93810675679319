import { useEffect, useState } from 'react'
import { Phone, X, Loader, CheckCircle, AlertTriangle } from 'lucide-react'
import { STIRSHAKENComponent } from './STIRSHAKENComponent';
import { getServicesManager } from 'services';
import { OptionalTwilioNumber, RegisteredTwilioNumber } from 'interfaces/db';
import { MultiDialerQuotaInfo, UserDataResult } from 'interfaces/services';
import { convertFromReduxSafeUserState } from 'lib/redux/store';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { sleep } from 'core';
import { NumberSearch } from './NumberSearch';
import { convertOptionalNumberToTwilioNumber, format_number } from './cfg';

type NumberRegistrationProps = {
  user: UserDataResult | null
}

function NumberManagementImpl({ user }: NumberRegistrationProps) {
  const [purchasingNumbers, setPurchasingNumbers] = useState<string[]>([])
  const [maxSelections, setMaxSelections] = useState(5)
  const [selectedNumbers, setSelectedNumbers] = useState<RegisteredTwilioNumber[]>([])
  const [confirmingReleaseNumber, setConfirmingReleaseNumber] = useState<string | null>(null)
  const [releaseNumberProgress, setReleaseNumberProgress] = useState<string | null>(null)
  const [multiDialerQuota, setMultiDialerQuota] = useState<MultiDialerQuotaInfo | null>(null)

  // on component mount
  useEffect(() => {
    getServicesManager().getMyTwilioNumbers().then((numbers) => {
      setSelectedNumbers(numbers ?? [])
  })

  getServicesManager().getMultidialerQuota().then((quota) => {
    setMultiDialerQuota(quota)
    if (quota) setMaxSelections(quota.subscription.max_phone_numbers_per_user)
  })
  }, [])

  if (!user) return <Loader />
  if (!multiDialerQuota) return <Loader />

  const handlePurchase = async (twilio_number: OptionalTwilioNumber, countryCode?: string) => {
    if (!user) return
    const number = twilio_number.phone_number
    if (selectedNumbers.length >= maxSelections) {
      alert(`You can only select up to ${maxSelections} numbers.`)
      return
    }
    setPurchasingNumbers([...purchasingNumbers, number])
    try {
      const success = await getServicesManager().registerTwilioNumber(twilio_number, countryCode)
      if (!success) {
        setPurchasingNumbers(purchasingNumbers.filter(n => n !== number))
        alert('Failed to purchase number.')
        return
      } else {
        setSelectedNumbers([...selectedNumbers, convertOptionalNumberToTwilioNumber(twilio_number, user)])
        setPurchasingNumbers(purchasingNumbers.filter(n => n !== number))
      }
    } catch (error) {
    } finally {
      setPurchasingNumbers(purchasingNumbers.filter(n => n !== number))
    }
  }

  return (
    <div className="container mx-auto p-4 bg-gray-50 min-h-screen">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div className="shadow-sm bg-white border-gray-200 border rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4 text-gray-700 flex items-center justify-between">
            <span className="flex items-center">
              <Phone className="mr-2" size={20} />
              Your Numbers
            </span>
            <span className="text-sm font-normal text-gray-500">
              {selectedNumbers.length}/{maxSelections} Selected
            </span>
          </h2>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50">
                  <th className="p-2 text-left font-semibold">Number</th>
                  <th className="p-2 text-left font-semibold">Spam</th>
                  <th className="p-2 text-left font-semibold"></th>
                </tr>
              </thead>
              <tbody>
                {selectedNumbers.map(({ rep_phone_value, nomorobo_spamscore }) => (
                  <tr key={rep_phone_value} className="border-b">
                    <td className="p-2">{format_number(rep_phone_value)}</td>
                    <td className="p-2">
                      {nomorobo_spamscore && nomorobo_spamscore > 0.5 ? (
                        <AlertTriangle className="text-yellow-500" size={16} />
                      ) : (
                        <CheckCircle className="text-green-500" size={16} />
                      )}
                    </td>
                    <td className="p-2 text-right">
                      <X className="text-red-500 inline-block cursor-pointer" size={16}
                         onClick={() => { setReleaseNumberProgress(null); setConfirmingReleaseNumber(rep_phone_value); }} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <STIRSHAKENComponent />

        {confirmingReleaseNumber === null ? null :
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 overflow-y-auto">
          <div className="bg-white rounded-lg p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <h2 className="text-xl font-semibold mb-4 text-gray-700 flex items-center">Unregister {format_number(confirmingReleaseNumber)}?</h2>
            <p className="my-4">You may not be able to get it back.</p>
            <div className="w-full flex flex-row gap-2">
              <button type="button"
                className="p-2 rounded-md font-bold w-1/2 text-center bg-gray-200 hover:bg-gray-100 disabled:bg-gray-100 text-gray-900 transition duration-200"
                disabled={releaseNumberProgress !== null}
                onClick={() => setConfirmingReleaseNumber(null)}
              >
                Cancel
              </button>
              <button type="button"
                className="p-2 rounded-md font-bold flex-grow flex flex-row items-center justify-center gap-2 bg-red-600 hover:bg-red-400 disabled:bg-red-400 text-white transition duration-200"
                disabled={releaseNumberProgress !== null}
                onClick={async () => {
                  setReleaseNumberProgress("Unregistering...")
                  const releasingNumber = confirmingReleaseNumber
                  const errorMessage = await getServicesManager().unregisterTwilioNumber(confirmingReleaseNumber)
                  setReleaseNumberProgress(errorMessage ?? "Unregistered!")
                  if (!errorMessage) { setSelectedNumbers(selectedNumbers.filter(x => x.rep_phone_value !== confirmingReleaseNumber)) }
                  await sleep(errorMessage ? 2000 : 1000)
                  if (confirmingReleaseNumber === releasingNumber) {
                    setConfirmingReleaseNumber(null)
                    setReleaseNumberProgress(null)
                  }
                }}
              >
                {releaseNumberProgress !== null && releaseNumberProgress.endsWith('...') ? <Loader className="mr-1 animate-spin" size={16} /> : null}
                {releaseNumberProgress ?? `Unregister ${format_number(confirmingReleaseNumber)}`}
              </button>
            </div>
          </div>
        </div>}
      </div>

      <NumberSearch 
        disabled={selectedNumbers.length >= maxSelections}
        handlePurchase={(number, countryCode) => handlePurchase(number, countryCode)}
        purchasingNumbers={purchasingNumbers}
        quota={multiDialerQuota}
        registeredNumbers={selectedNumbers}
      />
    </div>
  )
}

const ReduxWrapped = connect((state: RootState) => {
  return {
      user: convertFromReduxSafeUserState(state.user),
  }
})(NumberManagementImpl)

export { ReduxWrapped as NumberManagementIndividual}

