import { UserPickerBucket, sortUsers, UserPickerImpl } from "components/UserPicker"
import { groupByKeys } from "core"
import { UserGroup } from "interfaces/db"
import { UserDataResult, VisibleAccountsResult } from "interfaces/services"
import { UserGroupInfo, convertFromReduxSafeUserResult, convertFromReduxSafeVisibleAccounts, convertFromReduxSafeUserState } from "lib/redux/store"
import { connect } from "react-redux"
import { RootState } from "store"
import { HierarchicalMultiSelect } from "./HierarchicalMultiSelect"
import { HierarchicalOption, OptionType } from "./cfg"
import { SimplifiedHierarchicalSelect } from "./SimplifiedHierarchicalMultiSelect"

export type MultiUserSelect = {
    user_id: string | null,
    user_group_id: string | null
    team_id: string | null
}

type MultiUserSelectorProps = {
    useComplicatedMount?: boolean
    user: UserDataResult | null
    users: UserDataResult[]
    userIdToUser: Map<string, UserDataResult>
    sortedGroupInfo: UserGroupInfo[]
    groupIdToSortedIdx: Record<string, number>
    unassignedUsers: UserDataResult[]
    selectedUserOrGroups: MultiUserSelect[]
    onSelectedUserOrGroupsChange: (selected: MultiUserSelect[]) => void
}

function MultiUserSelectorImpl({ useComplicatedMount, user, users, userIdToUser, sortedGroupInfo, groupIdToSortedIdx, unassignedUsers, 
    selectedUserOrGroups, 
    onSelectedUserOrGroupsChange }: MultiUserSelectorProps) {
    const rootGroupInfos = sortedGroupInfo.filter(g => g.parentIdxs.length === 0).sort((a, b) => a.group.user_group_name.localeCompare(b.group.user_group_name)).filter((x) => {
        const has_active_users = x.allUserIds.some((user_id) => {
            const user = userIdToUser.get(user_id)
            return user && user.team_is_active && user.can_dial
        })
        return has_active_users
    })
    const constructOptions = (group: UserGroup): HierarchicalOption => {
        const groupInfo = sortedGroupInfo[groupIdToSortedIdx[group.user_group_id]]
        const options: HierarchicalOption[] = []
        const subGroups = groupInfo.subgroupIdxs.map((idx) => sortedGroupInfo[idx].group)
        const subGroupOptions = subGroups.map((g) => constructOptions(g))
        options.push(...subGroupOptions)
        const users = groupInfo.directUserIds.map((user_id) => userIdToUser.get(user_id)).filter((u) => u && u.team_is_active && u.can_dial).map((u) => u as UserDataResult)
        if (users.length > 0) {
            options.push(...users.map((u) => {
                return {
                    'label': u.user_name,
                    'value': u.user_id,
                    'type': OptionType.ITEM
                }
            }))
        }
        return {
            'children': options,
            'label': group.user_group_name,
            'value': group.user_group_id,
            'type': OptionType.GROUP
        }
    }

    const options: HierarchicalOption[] = []
    if (user && userIdToUser.size > 0) {  
        options.push({
        'label': 'Overall Team',
        'value': user.team_id,
        'type': OptionType.ITEM
        })
    }
    if (rootGroupInfos.length > 0) { options.push(...rootGroupInfos.map((x) => constructOptions(x.group))) }
    if (rootGroupInfos.length > 0 && unassignedUsers.length > 0) {
        options.push({
            'children': unassignedUsers.map((u) => {
                return {
                    'label': u.user_name,
                    'value': u.user_id,
                    'type': OptionType.ITEM
                }
            }),
            'label': 'Unassigned',
            'value': '__unassigned__',
            'type': OptionType.GROUP
        })
    } else if (unassignedUsers.length > 0) {
        options.push(...unassignedUsers.map((u) => {
            return {
                'label': u.user_name,
                'value': u.user_id,
                'type': OptionType.ITEM
            }
        }))
    }

    const convertToSelected = (userOrGroup: MultiUserSelect): HierarchicalOption | null => {
        if (userOrGroup.team_id) {
            return {
                'label': 'Overall Team',
                'value': userOrGroup.team_id,
                'type': OptionType.ITEM
            }
        } else if (userOrGroup.user_id) {
            const user = userIdToUser.get(userOrGroup.user_id)
            return {
                'label': user?.user_name ?? 'Unknown',
                'value': userOrGroup.user_id,
                'type': OptionType.ITEM
            }
        } else if (userOrGroup.user_group_id) {
            const group = sortedGroupInfo[groupIdToSortedIdx[userOrGroup.user_group_id]].group
            return {
                'label': group.user_group_name,
                'value': userOrGroup.user_group_id,
                'type': OptionType.GROUP
            }
        }
        return null
    }
    const getSelected = (): HierarchicalOption[] => {
        // if none and can use overall team, return that
        // if (!selectedUserOrGroups || selectedUserOrGroups.length === 0 && user && userIdToUser.size > 0) return [convertToSelected({'user_id': null, 'user_group_id': null, 'team_id': user?.team_id ?? ''}) as HierarchicalOption] 
        return selectedUserOrGroups.map((x) => convertToSelected(x)).filter((x) => x !== null) as HierarchicalOption[]
    }
    const selected = getSelected()
    const onChange = (selected: HierarchicalOption[]) => {
        onSelectedUserOrGroupsChange(selected.map((x) => {
            if (x.label === 'Overall Team') {
                return { 'user_id': null, 'user_group_id': null, 'team_id': x.value }
            } else if (x.type === OptionType.GROUP) {
                return { 'user_id': null, 'user_group_id': x.value, 'team_id': null }
            } else if (x.type === OptionType.ITEM) {
                return { 'user_id': x.value, 'user_group_id': null, 'team_id': null }
            }
            return { 'user_id': null, 'user_group_id': null, 'team_id': null }
        }))
    }
    return useComplicatedMount ? <HierarchicalMultiSelect options={options} selectedOptions={selected} onChange={onChange} /> : <SimplifiedHierarchicalSelect 
    options={options} 
    value={selected.map((x) => x.value)}
    onChange={(values) => {
        // it's grabbing 
        const selected: HierarchicalOption[] = []
        for (const v of values) {
            const matching_user = users.find((u) => u.user_id === v)
            const matching_group = sortedGroupInfo.find((g) => g.group.user_group_id === v)?.group
            if (matching_user) { selected.push({ 'label': matching_user.user_name, 'value': matching_user.user_id, 'type': OptionType.ITEM }) }
            else if (matching_group) { selected.push({ 'label': matching_group.user_group_name, 'value': matching_group.user_group_id, 'type': OptionType.GROUP }) }
            else if (v === user?.team_id) { selected.push({ 'label': 'Overall Team', 'value': user.team_id, 'type': OptionType.ITEM }) }
        }
        onChange(selected)
    }} />   
}

export const MultiUserSelector = connect((state: RootState) => {
    const users = state.visibleAccounts.value?.users.map(u => { return convertFromReduxSafeUserResult(u) }).filter((u) => u.can_dial && u.team_is_active)
    const userIdToGroups = groupByKeys(
        state.userGroupInfo.memberships.filter(m => !m.member_is_group),
        m => m.member_id,
        m => state.userGroupInfo.sortedGroupInfo[state.userGroupInfo.groupIdToSortedIdx[m.user_group_id]].group,
    )
    return {
        user: convertFromReduxSafeUserState(state.user),
        users: users ?? [],
        userIdToUser: new Map(state.visibleAccounts.value?.users?.map(u => [u.user_id, convertFromReduxSafeUserResult(u)])),
        sortedGroupInfo: state.userGroupInfo.sortedGroupInfo,
        groupIdToSortedIdx: state.userGroupInfo.groupIdToSortedIdx,
        unassignedUsers: sortUsers(users?.filter(user => !userIdToGroups.get(user.user_id)?.length && user.team_is_active && user.can_dial) ?? []),
    }
})(MultiUserSelectorImpl)