import { Loader } from "components/Loader";
import { PromptOnLeave } from "components/PromptOnLeave";
import { sleep } from "core";
import { Typography } from "interfaces/typography";
import React from "react";

export type SyncGroup = {
    groupId: string
    groupName: string 
    tempGroupId?: string
}

export type SyncGroupMembership = {
    itemId: string, 
    tempItemId?: string,
    groupId: string
    tempGroupId?: string
}

export type SyncItem = {
    itemId: string,
    tempItemId?: string,

    // some folks use item membership, some do not for sync engines
    groupId?: string,
    tempGroupId?: string,
    
    properties: any
}

export type SyncSubItem = {
    itemId: string,
    tempItemId?: string,
    subItemId: string
    tempSubItemId?: string,
    properties: any
}

type SyncState = {
    groups: SyncGroup[] | null,
    savedGroups: SyncGroup[] | null,

    groupMemberships: SyncGroupMembership[] | null
    savedGroupMemberships: SyncGroupMembership[] | null

    items: SyncItem[] | null
    savedItems: SyncItem[] | null

    subItems: SyncSubItem[] | null
    savedSubItems: SyncSubItem[] | null
    isSaving: boolean
}

type SyncProps = {
    getGroups: () => Promise<SyncGroup[] | null>
    getGroupMemberships?: () => Promise<SyncGroupMembership[] | null>
    getItems: () => Promise<SyncItem[] | null>
    getSubItems: () => Promise<SyncSubItem[] | null>

    addGroup: (group: SyncGroup) => Promise<SyncGroup | null>
    addGroupMembership?: (groupMembership: SyncGroupMembership) => Promise<boolean>
    addItem: (item: SyncItem) => Promise<SyncItem | null>
    addSubItem: (subItem: SyncSubItem) => Promise<SyncSubItem | null>

    updateGroup: (group: SyncGroup) => Promise<boolean>
    updateItem: (item: SyncItem) => Promise<boolean>
    updateSubItem: (subItem: SyncSubItem, previousSubItem: SyncSubItem) => Promise<boolean>

    removeGroup: (group: SyncGroup) => Promise<boolean>
    removeGroupMembership?: (groupMembership: SyncGroupMembership) => Promise<boolean>
    removeItem: (item: SyncItem) => Promise<boolean>
    removeSubItem: (subItem: SyncSubItem) => Promise<boolean>

    compareGroupProperties: (a: SyncGroup, b: SyncGroup) => boolean
    compareItemProperties: (a: SyncItem, b: SyncItem) => boolean
    compareSubItemProperties: (a: SyncSubItem, b: SyncSubItem) => boolean

    constructDefaultGroup: () => SyncGroup
    constructDefaultGroupMemberships?: (group: SyncGroup, item: SyncItem) => SyncGroupMembership
    constructDefaultItem: (group: SyncGroup) => SyncItem
    constructDefaultSubItem: (item: SyncItem) => SyncSubItem

    renderSubItems: (item: SyncItem, subItems: SyncSubItem[], onSubItemModification: (subItemId: string, properties: any) => void, onSubItemRemoval: (subItemId: string) => void, onSubItemAddition: () => void) => JSX.Element
    renderItemInfo: (item: SyncItem, onItemModification: (properties: any) => void, subItems: SyncSubItem[], onSubItemModification: (subItemId: string, properties: any) => void) => JSX.Element
    renderGroupInfo: (group: SyncGroup, onGroupNameModification: (groupName: string) => void) => JSX.Element

    showSubItemsOnNewLine?: boolean
    addDefaultSubItemOnItemCreation?: boolean
}

export const DEFAULT_ID = 'Default'

export class SyncEngine extends React.Component<SyncProps, SyncState> {
    _syncTimer: NodeJS.Timeout | null = null

    constructor(props: SyncProps) {
        super(props)
        this.state = {
            items: null,
            savedItems: null,
            subItems: null,
            savedSubItems: null,
            groups: null,
            savedGroups: null,
            groupMemberships: null,
            savedGroupMemberships: null,
            isSaving: false,
        }
    }

    componentDidMount(): void {
        this.props.getGroups().then((v: SyncGroup[] | null) => {
            if (!v) return
            this.setState({groups: v, savedGroups: v})
        })

        if (this.props.getGroupMemberships) {
            this.props.getGroupMemberships().then((v: SyncGroupMembership[] | null) => {
                if (!v) return
                this.setState({groupMemberships: v, savedGroupMemberships: v})
            })
        }

        this.props.getItems().then((v: SyncItem[] | null) => {
            if (!v) return
            this.setState({items: v, savedItems: v})
        })

        this.props.getSubItems().then((v: SyncSubItem[] | null) => {
            if (!v) return
            this.setState({subItems: v, savedSubItems: v})
        })
    }

    componentDidUpdate(prevProps: Readonly<SyncProps>, prevState: Readonly<SyncState>, snapshot?: any): void {
        if (!prevState.isSaving && this.state.isSaving) {
            this.handleOnSave()
        }
    }

    _hasPendingGroup() {
        if (!this.state.groups || !this.state.savedGroups) return null
        const newGroup = this.state.groups.find((v) => v.groupId === DEFAULT_ID)
        if (newGroup) return true
        const removedGroup = this.state.savedGroups.find((v) => this.state.groups?.find((x) => x.groupId === v.groupId) === undefined) 
        if (removedGroup) return true
        const modifiedGroup = this.state.savedGroups.find((v) => this.state.groups?.find((x) => x.groupId === v.groupId && !this.props.compareGroupProperties(x, v)) !== undefined)
        if (modifiedGroup) return true
        return false
    }

    _hasPendingItem() {
        if (!this.state.items || !this.state.savedItems) return null 
        const newKeyword = this.state.items.find((v) => v.itemId === DEFAULT_ID)
        if (newKeyword) return true
        const removedKeyword = this.state.savedItems.find((v) => this.state.items?.find((x) => x.itemId === v.itemId) === undefined) 
        if (removedKeyword) return true
        const modifiedKeyword = this.state.savedItems.find((v) => this.state.items?.find((x) => x.itemId === v.itemId && !this.props.compareItemProperties(x, v)) !== undefined)
        if (modifiedKeyword) return true
        return false
    }

    _hasPendingSubItem() {
        if (!this.state.subItems || !this.state.savedSubItems) return null 
        const newKeyword = this.state.subItems.find((v) => v.subItemId === DEFAULT_ID)
        if (newKeyword) return true
        const removedKeyword = this.state.savedSubItems.find((v) => this.state.subItems?.find((x) => x.subItemId === v.subItemId) === undefined) 
        if (removedKeyword) return true
        const modifiedKeyword = this.state.savedSubItems.find((v) => this.state.subItems?.find((x) => x.subItemId === v.subItemId && !this.props.compareSubItemProperties(x, v)) !== undefined)
        if (modifiedKeyword) return true
        return false
    }

    _hasPending() {
        return this._hasPendingGroup() || this._hasPendingItem() || this._hasPendingSubItem()
    }

    _renderMinusButton(onClick: () => void): JSX.Element {
        return <div onClick={onClick.bind(this)}
        className={"cursor-pointer w-4 h-4 flex justify-center items-center text-center"} 
        style={{
            'boxShadow': 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            'borderRadius': '50%',
            'backgroundColor': 'grey'
        }}
        >
            <Typography variant="largeParagraph">
                    {"x"}
            </Typography>
        </div>
    }


    _renderIsPending() {
        const hasPending = this._hasPending()
        return ( <div onClick={() => {if (this._hasPending()) this.setState({'isSaving': true})}} 
        className={"w-fit h-fit pt-1 pb-1 pl-2 pr-2 flex justify-center items-center text-center " + (hasPending ?  "cursor-pointer bg-yellow-300 hover:bg-slate-200" : 'cursor-not-allowed bg-green-200 opacity-80')} 
            style={{
                'boxShadow': 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                'borderRadius': '10px',
            }}
            >
            <Typography variant="largeParagraph">
                {this.state.isSaving ? 'Saving' : hasPending ? 'Save' : 'Saved!'}
            </Typography>
            </div>
        )
    }

    async handleOnSave() {
        await this._onSave()
        this.setState({'isSaving': false})
    }


    async _onSave() {
        if (!this.state.groups || !this.state.savedGroups || 
            !this.state.items || !this.state.savedItems || 
            !this.state.subItems || !this.state.savedSubItems || 
            (this.props.getGroupMemberships && (!this.state.groupMemberships || !this.state.savedGroupMemberships)) ||
            !this._hasPending()) {
            return
        }
        // save the updated groups first...
        const newGroupsSaved: SyncGroup[] = []
        const removedGroupsSaved: SyncGroup[] = []
        const modifiedGroupsSaved: SyncGroup[] = []
        
        const newGroups = this.state.groups.filter((v) => v.groupId === DEFAULT_ID)
        const removedGroups = this.state.savedGroups.filter((v) => this.state.groups?.find((x) => x.groupId === v.groupId) === undefined) 
        const modifiedGroups = this.state.savedGroups.filter((v) => this.state.groups?.find((x) => x.groupId === v.groupId && !this.props.compareGroupProperties(x, v)) !== undefined)
        
        for (const group of newGroups) {
            const newGroup = await this.props.addGroup(group)
            if (!newGroup) {
                return
            }
            newGroupsSaved.push({...newGroup, 'tempGroupId': group.tempGroupId})
        }

        for (const group of removedGroups) {
            const success = await this.props.removeGroup(group)
            if (!success) {
                return
            } 
            removedGroupsSaved.push(group)
        }

        for (const group of modifiedGroups) {
            // find matching group 
            const matchingGroup = this.state.groups.find((x) => x.groupId === group.groupId)
            if (!matchingGroup) continue
            const success = await this.props.updateGroup(matchingGroup)
            if (!success) {
                return
            }
            modifiedGroupsSaved.push(matchingGroup)
        }

        // create a map from temp_group_id to group_id
        const temp_group_id_to_db_id = Object.fromEntries(newGroupsSaved.map((v) => {return [v.tempGroupId ?? '', v.groupId]}))

        const newItemsSaved: SyncItem[] = []
        const removedItemsSaved: SyncItem[] = []
        const modifiedItemsSaved: SyncItem[] = []

        const newItems = this.state.items.filter((v) => v.itemId === DEFAULT_ID)
        const removedItems = this.state.savedItems.filter((v) => this.state.items?.find((x) => x.itemId === v.itemId) === undefined) 
        const modifiedItems = this.state.savedItems.filter((v) => this.state.items?.find((x) => x.itemId === v.itemId && !this.props.compareItemProperties(x, v)) !== undefined)

        for (const item of newItems) {
            let targetItem = item
            if (!this.props.getGroupMemberships && item.groupId === DEFAULT_ID && targetItem.tempGroupId && temp_group_id_to_db_id[targetItem.tempGroupId]) {
                targetItem = {...targetItem, 'groupId': temp_group_id_to_db_id[targetItem.tempGroupId]}
            }
            const newItem = await this.props.addItem(targetItem)
            if (!newItem) {
                return
            }
            newItemsSaved.push({...newItem, 'tempItemId': item.tempItemId, 'tempGroupId': item.tempGroupId})
        }

        for (const item of removedItems) {
            const success = await this.props.removeItem(item)
            if (!success) {
                return
            } 
            removedItemsSaved.push(item)
        }


        for (const item of modifiedItems) {
            const matchingItem = this.state.items.find((x) => x.itemId === item.itemId)
            if (!matchingItem) continue
            const success = await this.props.updateItem(matchingItem)
            if (!success) {
                return
            } 
            modifiedItemsSaved.push(matchingItem)
        }

        const temp_item_id_to_db_id = Object.fromEntries(newItemsSaved.map((v) => {return [v.tempItemId ?? '', v.itemId]}))

        // for any group memberships that are new 
        let newGroupMemberships: SyncGroupMembership[] | null = null 
        let removedGroupMemberships: SyncGroupMembership[] | null = null 

        if (this.props.addGroupMembership && this.props.getGroupMemberships && this.props.removeGroupMembership &&
            this.state.groupMemberships && this.state.savedGroupMemberships) {
            newGroupMemberships = this.state.groupMemberships.filter((v) => this.state.savedGroupMemberships?.find((x) => (x.groupId === v.groupId && x.itemId === v.itemId)) === undefined)
            removedGroupMemberships = this.state.savedGroupMemberships.filter((v) => this.state.groupMemberships?.find((x) => (x.groupId === v.groupId && x.itemId === v.itemId)) === undefined)

            for (const newMembership of newGroupMemberships) {
                const keywordId = newMembership.itemId != DEFAULT_ID ? newMembership.itemId : newMembership.tempItemId ? temp_item_id_to_db_id[newMembership.tempItemId] : null
                if (!keywordId) continue
                const groupId = newMembership.groupId != DEFAULT_ID ? newMembership.groupId : newMembership.tempGroupId ? temp_group_id_to_db_id[newMembership.tempGroupId] : null
                if (!groupId) continue
                const success = await this.props.addGroupMembership({'groupId': groupId, 'itemId': keywordId})
                if (!success) {
                    return
                }
            }
    
            for (const removedGroupMembership of removedGroupMemberships) {
                const success = await this.props.removeGroupMembership(removedGroupMembership)
                if (!success) {
                    return
                }
            }
        }

        const newSubItemsSaved: SyncSubItem[] = []
        const removedSubItemsSaved: SyncSubItem[] = []
        const modifiedSubItemsSaved: SyncSubItem[] = []

        const newSubItems = this.state.subItems.filter((v) => v.subItemId === DEFAULT_ID)
        const removedSubItems = this.state.savedSubItems.filter((v) => this.state.subItems?.find((x) => x.subItemId === v.subItemId) === undefined) 
        const modifiedSubItems = this.state.savedSubItems.filter((v) => this.state.subItems?.find((x) => x.subItemId === v.subItemId && !this.props.compareSubItemProperties(x, v)) !== undefined)

        for (const subItem of newSubItems) {
            let targetItem = subItem
            if (targetItem.itemId == DEFAULT_ID) {
                if (!subItem.tempItemId || !temp_item_id_to_db_id[subItem.tempItemId]) continue
                targetItem = {...targetItem, 'itemId': temp_item_id_to_db_id[subItem.tempItemId]}
            }
            const newSubItem = await this.props.addSubItem(targetItem)
            if (!newSubItem) {
                return
            }

            newSubItemsSaved.push({...newSubItem, 'tempItemId': targetItem.tempItemId, 'tempSubItemId': targetItem.tempSubItemId})
        }

        for (const subItem of removedSubItems) {
            const success = await this.props.removeSubItem(subItem)
            if (!success) {
                return
            } 
            removedSubItemsSaved.push(subItem)
        }

        for (const subItem of modifiedSubItems) {
            const matchingSubItem = this.state.subItems.find((x) => x.subItemId === subItem.subItemId)
            if (!matchingSubItem) continue
            const success = await this.props.updateSubItem(matchingSubItem, subItem) 
            if (!success) {
                return
            } 
            modifiedSubItemsSaved.push(matchingSubItem)
        }

        this.setState((state) => {
                if (!state.groups || !state.savedGroups || 
                    !state.items || !state.savedItems || 
                    !state.subItems || !state.savedSubItems || 
                    (this.props.getGroupMemberships && (!state.groupMemberships || !state.savedGroupMemberships))) {
                    return null
                }

                // add the new groups and remove the filtered groups
                const removedGroupIds = removedGroupsSaved.map((v) => v.groupId)
                let savedGroupsUpdate: SyncGroup[] = [...state.savedGroups.filter((v) => !removedGroupIds.includes(v.groupId)), ...newGroupsSaved]
                // add the modified groups

                savedGroupsUpdate = savedGroupsUpdate.map((v) => {
                    const modifiedGroup = modifiedGroupsSaved.find((x) => x.groupId === v.groupId)
                    return modifiedGroup ?? v
                })

                const groupsUpdate: SyncGroup[] = state.groups.map((v) => {
                    const newGroup = newGroupsSaved.find((x) => x.tempGroupId && x.tempGroupId === v.tempGroupId)
                    return {...v, 'groupId': newGroup ? newGroup.groupId : v.groupId}
                })

                let groupMembershipsUpdate: SyncGroupMembership[] | null = null
                let savedGroupMembershipsUpdate: SyncGroupMembership[] | null = null
                if (state.groupMemberships && state.savedGroupMemberships && removedGroupMemberships && newGroupMemberships) {
                    savedGroupMembershipsUpdate = [...state.savedGroupMemberships.filter((v) => removedGroupMemberships!.find((x) => x.groupId === v.groupId && x.itemId === v.itemId) === undefined), ...newGroupMemberships] 
                    savedGroupMembershipsUpdate = savedGroupMembershipsUpdate.map((v) => {
                        const modifiedId = newItemsSaved.find((x) => x.tempItemId && x.tempItemId === v.tempItemId)
                        return {...v, 'itemId': modifiedId ? modifiedId.itemId : v.itemId}
                    })

                    groupMembershipsUpdate = state.groupMemberships.map((v) => {
                        const newGroup = newGroupsSaved.find((x) => x.tempGroupId && x.tempGroupId === v.tempGroupId)
                        const newItem = newItemsSaved.find((x) => x.tempItemId && x.tempItemId === v.tempItemId)
                        return {...v, 'keyword_group_id': newGroup ? newGroup.groupId : v.groupId, 'itemId': newItem ? newItem.itemId : v.itemId}
                    })
                }


                const removeItemIds = removedItemsSaved.map((v) => v.itemId)
                let saveItemsUpdate: SyncItem[] = [...state.savedItems.filter((v) => !removeItemIds.includes(v.itemId)), ...newItemsSaved]
                saveItemsUpdate = saveItemsUpdate.map((v) => {
                    const modifiedKeyword = modifiedItemsSaved.find((x) => x.itemId === v.itemId)
                    return modifiedKeyword ?? v
                })

                const itemsUpdate: SyncItem[] = state.items.map((v) => {
                    const newKeyword = newItemsSaved.find((x) => x.tempItemId && x.tempItemId === v.tempItemId)
                    return {...v, 'itemId': newKeyword ? newKeyword.itemId : v.itemId}
                })

                const removedSubItemIds = removedSubItemsSaved.map((v) => v.subItemId)
                let savedSubItemsUpdate: SyncSubItem[] = [...state.savedSubItems.filter((v) => !removedSubItemIds.includes(v.subItemId)), ...newSubItemsSaved]
                savedSubItemsUpdate = savedSubItemsUpdate.map((v) => {
                    const modifiedKeywordPhrase = modifiedSubItemsSaved.find((x) => x.subItemId === v.subItemId)
                    return modifiedKeywordPhrase ?? v
                })

                const subItemUpdate: SyncSubItem[] = state.subItems.map((v) => {
                    const newItem = newItemsSaved.find((x) => x.tempItemId && x.tempItemId === v.tempItemId)
                    const newSubItem = newSubItemsSaved.find((x) => x.tempSubItemId && x.tempSubItemId === v.tempSubItemId)
                    return {...v, 'itemId': newItem ? newItem.itemId : v.itemId, 'subItemId': newSubItem ? newSubItem.subItemId : v.subItemId}
                })

                return {
                    savedGroups: savedGroupsUpdate,
                    groups: groupsUpdate,

                    groupMemberships: groupMembershipsUpdate,
                    savedGroupMemberships: savedGroupMembershipsUpdate,

                    items: itemsUpdate,
                    savedItems: saveItemsUpdate,

                    subItems: subItemUpdate,
                    savedSubItems: savedSubItemsUpdate,
                }
        })
    }


    _onSubItemAddition(item: SyncItem) {
        this.setState((state) => {
            if (!state.subItems) return null
            const newSubItem: SyncSubItem = this.props.constructDefaultSubItem(item)
            return {
                'subItems': [...state.subItems, newSubItem]
            }
        })
    }

    _onSubItemModification(subItemId: string, properties: any) {
        this.setState((state) => {
            if (!state.subItems) return null
            const subItemIdx = state.subItems.findIndex((v) => v.subItemId === subItemId || v.tempSubItemId === subItemId)
            if (subItemIdx === -1) return null
            return {
                'subItems': [...state.subItems.slice(0, subItemIdx), {...state.subItems[subItemIdx], properties: properties} ,...state.subItems.slice(subItemIdx + 1)] 
            }
        })
    }

    _onSubItemRemoval(subItemId: string) {
        this.setState((state) => {
            if (!state.subItems) return null
            const subItemIdx = state.subItems.findIndex((v) => v.subItemId === subItemId || v.tempSubItemId === subItemId)
            if (subItemIdx === -1) return null
            return {
                'subItems': [...state.subItems.slice(0, subItemIdx),...state.subItems.slice(subItemIdx + 1)] 
            }
        })
    }

    _onItemModification(itemId: string, properties: any) {
        this.setState((state) => {
            if (!state.items) return null
            const keywordIdx = state.items.findIndex((v) => v.itemId === itemId || v.tempItemId === itemId)
            if (keywordIdx === -1) return null
            return {
                'items': [...state.items.slice(0, keywordIdx), {...state.items[keywordIdx], 'properties': properties} ,...state.items.slice(keywordIdx + 1)] 
            }
        })
    }

    _onItemRemoval(itemId: string) {
        this.setState((state) => {
            if (!state.items || !state.subItems || !state.savedSubItems || (this.props.getGroupMemberships && (!state.savedGroupMemberships || !state.groupMemberships))) return null
            const keywordIdx = state.items.findIndex((v) => v.itemId === itemId || v.tempItemId === itemId)
            if (keywordIdx === -1) return null
            // removing a keyword from the db, will automatically remove it from memberships and automatically remove the prhases associated with it. 
            const updatedSubItems: SyncSubItem[] = [...state.subItems].filter((v) => v.tempItemId !== itemId && v.itemId !== itemId)
            const updatedSubItemsSaved: SyncSubItem[] = [...state.savedSubItems].filter((v) => v.itemId !== itemId)
            const updatedGroupMemberships: SyncGroupMembership[] | null = state.groupMemberships ? [...state.groupMemberships].filter((v) => v.tempItemId !== itemId && v.itemId !== itemId) : null 
            const updatedGroupMembershipsSaved: SyncGroupMembership[] | null = state.savedGroupMemberships ? [...state.savedGroupMemberships].filter((v) => v.itemId !== itemId) : null

            return {
                items:  [...state.items.slice(0, keywordIdx),...state.items.slice(keywordIdx + 1)],
                subItems: updatedSubItems,
                savedSubItems: updatedSubItemsSaved,
                groupMemberships: updatedGroupMemberships,
                savedGroupMemberships: updatedGroupMembershipsSaved
            }
        })
    }

    _renderSubItems(item: SyncItem, itemId: string) {
        if (!this.state.items || !this.state.subItems) return null
        const subItems = this.state.subItems.filter((v) => v.itemId === itemId || v.tempItemId === itemId)
        return this.props.renderSubItems(item, subItems, this._onSubItemModification.bind(this), this._onSubItemRemoval.bind(this), () => this._onSubItemAddition.bind(this)(item))
    }

    _renderItem(item: SyncItem) {
        if (!this.state.items || !this.state.subItems) return null
        const itemId = item.itemId != DEFAULT_ID ? item.itemId : item.tempItemId
        if (!itemId) return null
        const subItems = this.state.subItems.filter((v) => v.itemId === itemId || v.tempItemId === itemId)
        return <div className="p-3 w-full flex flex-col flex-wrap gap-3 items-center"
        style={{
            'backgroundColor': 'white',
            'borderRadius': '10px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
        }}
        >
            <div className="w-full flex flex-row justify-between items-top">
                <div className="w-full flex flex-row gap-3">
                {this.props.renderItemInfo(item, (properties: any) => this._onItemModification.bind(this)(itemId, properties), subItems, this._onSubItemModification.bind(this))}
                {this.props.showSubItemsOnNewLine ? null : this._renderSubItems.bind(this)(item, itemId)}
                </div>
                {this._renderMinusButton(() => this._onItemRemoval.bind(this)(itemId))}
            </div>
            {this.props.showSubItemsOnNewLine ? this._renderSubItems.bind(this)(item, itemId) : null}
        </div>
    }

    _renderAddButton(text: string, onClick: () => void) {
        return <div onClick={onClick.bind(this)} className={"cursor-pointer bg-white w-fit h-fit pt-1 pb-1 pl-2 pr-2 opacity-80 hover-opacity-100 flex justify-center items-center text-center hover:bg-slate-200"} 
        style={{
            'boxShadow': 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            'borderRadius': '10px',
        }}
        >
            <Typography variant="largeParagraph">
                {text}
            </Typography>
        </div>
    }
    

    _addItem(group: SyncGroup) {
        this.setState((state) => {
            if (!state.items) return null
            const newItem: SyncItem = this.props.constructDefaultItem(group) 
            const newGroupMembership: SyncGroupMembership | null =  this.props.constructDefaultGroupMemberships ? this.props.constructDefaultGroupMemberships(group, newItem) : null
            const newSubItem: SyncSubItem | null  = this.props.addDefaultSubItemOnItemCreation ? this.props.constructDefaultSubItem(newItem) : null
            return {
                items: [...(state.items), newItem],
                groupMemberships: state.groupMemberships && newGroupMembership ? [...state.groupMemberships, newGroupMembership] : state.groupMemberships,
                subItems: state.subItems && newSubItem ? [...state.subItems, newSubItem] : state.subItems,
            }
        })
    }

    _onGroupNameModification(groupId: string, groupName: any) {
        this.setState((state) => {
            const viewIdx = (state.groups ?? []).findIndex((v) => (groupId === v.groupId || groupId === v.tempGroupId))
            if (viewIdx === -1 || !state.groups) return null
            return {
                'groups': [...state.groups.slice(0, viewIdx), {...state.groups[viewIdx], 'groupName': groupName} ,...state.groups.slice(viewIdx + 1)] 
            }
        })
    }

    _onGroupRemoval(groupId: string) {
        this.setState((state) => {
            if (!state.items || !state.subItems || !state.groups || !state.savedItems || !state.savedSubItems || 
                (this.props.getGroupMemberships && (!state.savedGroupMemberships || !state.groupMemberships))) return null
            const groupIdx = state.groups.findIndex((v) => v.groupId === groupId || v.tempGroupId === groupId)
            if (groupIdx === -1) return null

            // all keyword memberships that match
            let matchingMemberships: SyncGroupMembership[] | null = null
            let matchingKeywords: SyncItem[] = []
            
            let updatedMemberships: SyncGroupMembership[] | null = null
            let updatedMembershipsSaved: SyncGroupMembership[] | null = null

            if (this.props.getGroupMemberships && state.groupMemberships && state.savedGroupMemberships) {
                matchingMemberships = [...(state.groupMemberships ?? [])].filter((v) => v.tempGroupId === groupId || v.groupId === groupId)
                // all keywords that match
                matchingKeywords = [...state.items].filter((v) => matchingMemberships && matchingMemberships.some((x) => {
                const matchingId = x.itemId != DEFAULT_ID ? x.itemId : x.tempItemId
                if (!matchingId) return false
                return v.itemId === matchingId || v.tempItemId === matchingId                
                }))
                updatedMemberships = [...state.groupMemberships].filter((v) => v.groupId !== groupId && v.tempGroupId !== groupId)
                updatedMembershipsSaved = [...state.savedGroupMemberships].filter((v) => v.groupId !== groupId)
            } else {
                matchingKeywords = [...state.items].filter((v) => v.groupId === groupId || v.tempGroupId === groupId)
            }


            // get the keyword updates
            const updatedItems = [...state.items].filter((v) => matchingKeywords.find((x) => {
                const matchingId = x.itemId != DEFAULT_ID ? x.itemId : x.tempItemId
                if (!matchingId) return false
                return v.itemId === matchingId || v.tempItemId === matchingId
            }) === undefined)

            const updatedItemsSaved = [...state.savedItems].filter((v) => matchingKeywords.find((x) => {
                return v.itemId === x.itemId
            }) === undefined)

            const updatedSubItems = [...state.subItems].filter((v) => matchingKeywords.find((x) => {
                const matchingId = x.itemId != DEFAULT_ID ? x.itemId : x.tempItemId
                if (!matchingId) return false
                return v.itemId === matchingId || v.tempItemId === matchingId
            }) === undefined)

            const updatedSubItemsSaved = [...state.savedSubItems].filter((v) => matchingKeywords.find((x) => {
                return v.itemId === x.itemId 
            }) === undefined)

            return {
                groups: [...state.groups.slice(0, groupIdx), ...state.groups.slice(groupIdx + 1)],
                groupMemberships: updatedMemberships,
                savedGroupMemberships: updatedMembershipsSaved,
                items: updatedItems,
                savedItems: updatedItemsSaved,
                subItems: updatedSubItems,
                savedSubItems: updatedSubItemsSaved
            }
        })
    }

    _renderGroup(group: SyncGroup) {
        const groupId = group.groupId != DEFAULT_ID ? group.groupId : group.tempGroupId
        if (!groupId) return null
        let items: SyncItem[] = []
        if (this.props.getGroupMemberships) {
            const syncGroupMemberships  = (this.state.groupMemberships ?? []).filter((v) => v.tempGroupId === groupId || v.groupId === groupId)
            items = (this.state.items ?? []).filter((v) => syncGroupMemberships.some((x) => {
                const itemId = x.itemId != DEFAULT_ID ? x.itemId : x.tempItemId
                if (!itemId) return false
                return v.itemId === itemId || v.tempItemId === itemId
            }))
        } else {
            items = (this.state.items ?? []).filter((v) => v.groupId === groupId || v.tempGroupId === groupId)
        }
        return <div className={"w-full h-fit p-2 flex flex-col gap-3"} 
        style={{
            backgroundColor: 'rgb(3 33 78 / 19%)',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            'borderRadius': '10px',
        }}
        >
        <div className="w-full flex flex-row justify-between items-top">
            {this.props.renderGroupInfo(group, (properties: any) => this._onGroupNameModification.bind(this)(groupId, properties))}
            {this._renderMinusButton(() => this._onGroupRemoval.bind(this)(groupId))}
        </div>
        {items.map((v) => this._renderItem(v))}
        {this._renderAddButton('Add', () => this._addItem.bind(this)(group))}
        </div>
    }

    _addNewGroup() {
        this.setState((state) => {
            const newGroup: SyncGroup = this.props.constructDefaultGroup()
            return {
                groups: [...(state.groups ?? []), newGroup]
            }
        })
    }

    _renderAddGroupButton() {
        return <div onClick={() => this._addNewGroup.bind(this)()} className={"cursor-pointer bg-white w-fit h-fit p-2 opacity-80 hover-opacity-100 flex justify-center items-center text-center hover:bg-slate-200"} 
        style={{
            'boxShadow': 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            'borderRadius': '10px',
        }}
        >
            <Typography variant="largeParagraph">
                {"(+) Add Group"}
            </Typography>
        </div>
    }

    render() {
        if (!this.state.groups|| !this.state.items || !this.state.subItems || !this.state.savedGroups || !this.state.savedItems || !this.state.savedSubItems || (this.props.getGroupMemberships && (!this.state.groupMemberships || !this.state.savedGroupMemberships))) return <Loader />
        return <div className=" p-3 h-full w-full flex flex-col gap-5 items-center">
            <PromptOnLeave when={this._hasPending() ?? false} message={'You have unsaved changes. Sure you want to leave'}/>
            <div className="w-full flex flex-col gap-2">
            {this.state.groups ? this.state.groups.map((v) => this._renderGroup(v)) : null}
            </div>
            <div className="sticky bottom-2 w-full justify-center flex items-center" style={{

            }}>
                <div className="p-4 w-fit flex-row flex items-center gap-2" style={{
                'backgroundColor': 'white',
                'borderRadius': '10px',
                'boxShadow': 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                }}>
                    {this._renderAddButton('(+) Add', () => this._addNewGroup.bind(this)())}
                    {this._renderIsPending()}
                </div>
            </div>
        </div>
    }
}