
export type Option = {
    value: string | number
    label: string
  }
  
export function calculateDropdownPosition(
    triggerElement: HTMLElement,
    dropdownElement: HTMLElement,
    padding: number = 8
  ) {
    const viewportHeight = window.innerHeight
    const triggerRect = triggerElement.getBoundingClientRect()
    const dropdownRect = dropdownElement.getBoundingClientRect()
  
    const spaceBelow = viewportHeight - triggerRect.bottom - padding
    const spaceAbove = triggerRect.top - padding
  
    let position: 'bottom' | 'top' = 'bottom'
    let maxHeight = Math.max(100, Math.min(300, spaceBelow))
  
    if (spaceBelow < 100 && spaceAbove > spaceBelow) {
      position = 'top'
      maxHeight = Math.max(100, Math.min(300, spaceAbove))
    }
  
    return {
      position,
      maxHeight,
      width: triggerRect.width,
      left: triggerRect.left + window.scrollX,
      top: position === 'bottom' ? triggerRect.bottom + window.scrollY : undefined,
      bottom: position === 'top' ? window.innerHeight - triggerRect.top + window.scrollY : undefined
    }
  }

  export type BaseOption = {
    value: string
    label: string
  }
  
  export enum OptionType {
    GROUP = 'group',
    ITEM = 'item',
  }
  
  export type GroupOption = BaseOption & {
    type: OptionType
    children: (GroupOption | ItemOption)[]
  }
  
  export type ItemOption = BaseOption & {
    type: OptionType
  }
  
  export type HierarchicalOption = GroupOption | ItemOption
  