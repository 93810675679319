import { OptionalTwilioNumber, RegisteredTwilioNumber } from "interfaces/db"
import { MoreHorizontal, Phone, Search, Loader, CheckCircle, AlertTriangle, GripVertical } from 'lucide-react';
import { useEffect, useState } from "react";
import { CountryOptions, defaultCountryOptions, format_number } from "./cfg";
import { getServicesManager } from "services";
import { MultiDialerQuotaInfo } from "interfaces/services";

export type NumberSearchProps = {
    registeredNumbers: RegisteredTwilioNumber[]
    handlePurchase: (number: OptionalTwilioNumber, countryIsoCode?: string) => void
    purchasingNumbers: string[]
    disabled: boolean
    quota: MultiDialerQuotaInfo | null
}

export function NumberSearch({ registeredNumbers, handlePurchase, disabled, purchasingNumbers, quota }: NumberSearchProps) {
    const [countryOptions, setCountryOptions] = useState<CountryOptions[]>(defaultCountryOptions)
    const [availableNumbers, setAvailableNumbers] = useState<OptionalTwilioNumber[]>([])
    const [searchParams, setSearchParams] = useState({
      countryCode: '+1',
      country: 'United States',
      query: '',
    })

    useEffect(() => {
        if (!quota || !quota.subscription.international_credits_per_month) return
        const additionalOptions = [
          { code: '+44', name: 'United Kingdom'},
          { code: '+52', name: 'Mexico'  }
        ]
        setCountryOptions([...defaultCountryOptions, ...additionalOptions])
    }, [quota])

    useEffect(() => {
        getServicesManager().getOptionsForTwilioNumber({}).then((numbers) => {
            if (!numbers) return
            setAvailableNumbers(numbers)
        })
    }, [])

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault()
        // Implement search logic here using searchParams
        // check if it's digits, then check for area code
        const query: { [key: string]: string } = {}
        if (searchParams.query.match(/^\d+$/)) {
          query['area_code'] = searchParams.query
        } else {
          query['state_or_province'] = searchParams.query
        }
        // canada
        if (searchParams.country === 'Canada') query['iso_country_code'] = 'CA'
        if (searchParams.country === 'United Kingdom') query['iso_country_code'] = 'GB'
        if (searchParams.country === 'Mexico') query['iso_country_code'] = 'MX'
        getServicesManager().getOptionsForTwilioNumber(query).then((numbers) => {
          if (!numbers) return
          setAvailableNumbers(numbers)
        })
    }

    const getCountryCode = (): string | undefined => 
    searchParams.country === 'Canada' ? 'CA' : 
    searchParams.country === 'United Kingdom' ? 'GB' : 
    searchParams.country === 'Mexico' ? 'MX' :
    undefined

    return (
    <div className="w-full flex flex-col gap-2">
    <div className="shadow-sm bg-white border border-gray-200 rounded-lg p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4 text-gray-700 flex items-center">
        <Search className="mr-2" size={20} />
        Search Numbers
        </h2>
        <form onSubmit={handleSearch} className="flex items-center space-x-4">
        <select
            value={searchParams.country}
            onChange={(e) => setSearchParams({...searchParams, country: e.target.value})}
            className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        >
            {countryOptions.map((country) => (
            <option key={country.name} value={country.name} selected={country.name === searchParams.country}>
                {country.name} ({country.code})
            </option>
            ))}
        </select>
        <input
            type="text"
            value={searchParams.query}
            onChange={(e) => setSearchParams({...searchParams, query: e.target.value})}
            placeholder="Enter area code or state (2 letter code)"
            className="flex-grow p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <button type="submit" className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-200 flex items-center justify-center">
            <Search size={20} />
        </button>
        </form>

    <div className="overflow-x-auto mt-2">
      <table className="w-full">
        <thead>
          <tr className="bg-gray-50">
            <th className="p-2 text-left font-semibold">Number</th>
            <th className="p-2 text-left font-semibold">City</th>
            <th className="p-2 text-left font-semibold">State</th>
            <th className="p-2 text-left font-semibold">Action</th>
          </tr>
        </thead>
        <tbody>
          {availableNumbers.map((v) => {
            if (registeredNumbers.find((r) => format_number(r.rep_phone_value) === format_number(v.phone_number))) return null
            const { friendly_name, locality, region, phone_number } = v
            return (<tr key={friendly_name} className="border-b hover:bg-gray-50">
              <td className="p-2">{friendly_name}</td>
              <td className="p-2">{locality}</td>
              <td className="p-2">{region}</td>
              <td className="p-2">
                <button
                  onClick={() => handlePurchase(v, getCountryCode())}
                  disabled={purchasingNumbers.includes(phone_number) || disabled}
                  className={`bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600 transition duration-200 flex items-center ${
                    (purchasingNumbers.includes(phone_number) || disabled) ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {purchasingNumbers.includes(phone_number) ? (
                    <>
                      <Loader className="mr-1 animate-spin" size={16} />
                      Purchasing...
                    </>
                  ) : (
                    <>
                      <Phone className="mr-1" size={16} />
                      Purchase
                    </>
                  )}
                </button>
              </td>
            </tr>)
          })}
        </tbody>
      </table>
    </div>
  </div>
  </div>)
}