import React from 'react'
import { Outlet } from "react-router-dom";
import { Loader } from 'components/Loader';
import { SideBar } from 'components/SideBar';

export class App extends React.Component {
  
  render(): JSX.Element {
    return (
      <div className='flex select-none flex-col w-screen h-screen overflow-x-hidden overflow-hidden pl-1.5 pr-1.5' style={{'backgroundColor': "#FBFBFB"}}>
        <SideBar />
        <React.Suspense fallback={<><Loader /></>}>
          <div className='flex-grow w-full h-full overflow-auto'>
            <Outlet />
          </div>
        </React.Suspense>
      </div>
    )
  }
}
