import { Loader } from "components/Loader"
import { NumberManagementAdmin } from "./NumberManagementAdmin"
import { NumberManagementIndividual } from "./NumberManagementIndividual"
import { connect } from "react-redux"
import { RootState } from "store"

type NumberRegistrationProps = {
    isAdmin: boolean | null
}

function NumberRegistrationImpl({ isAdmin }: NumberRegistrationProps) {
    if (isAdmin === null) return <Loader />
    return isAdmin ? <NumberManagementAdmin /> : <NumberManagementIndividual />
}

const ReduxWrapped = connect((state: RootState) => {
    return {
        isAdmin: state.adminWrite.value ? state.adminWrite.value.team_ids.length > 0 : null,
    }
  })(NumberRegistrationImpl)
  
  export { ReduxWrapped as NumberRegistration}
  