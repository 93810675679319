import { Filter, FilterOption, FilterType } from "components/Filter";
import { Foldy } from "components/Foldy";
import { UserGroup, UserGroupMembership } from "interfaces/db";
import { UserDataResult, VisibleAccountsResult } from "interfaces/services";
import { Typography } from "interfaces/typography";
import React, { DragEvent, MouseEventHandler, ReactElement, createRef } from "react";
import uuid from "react-uuid";
import { getServicesManager } from "services";
import { xIcon, vdotsIcon } from "icons";
import { DropDownMenu, DropDownMenuEntry } from "components/DropDownMenu";
import { HoverWrapper } from "components/Label";
import { SearchBox } from "components/SearchBox";
import { Button } from "components/Button";

import './groups.css';
import { Action, AnyAction, Dispatch } from "@reduxjs/toolkit";
import { convertFromReduxSafeVisibleAccounts } from "lib/redux/store";
import { connect } from "react-redux";
import { RootState } from "store";
import { Loader } from "components/Loader";
import { stat } from "fs";
import { sleep } from "core";
import { SEC_TO_MS } from "cfg/const";

///////////
// Icons //
///////////

const undoIcon = <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M 2.0957031 0 L 0.66796875 6.7070312 L 7.34375 8.1269531 L 4.8925781 4.3300781 C 7.0468183 2.3064602 10.090193 1.415198 13.011719 2.0371094 C 17.919939 3.0819323 21.054567 7.9620211 20 12.916016 C 18.945451 17.869923 14.09572 21.050682 9.1875 20.005859 C 8.8567725 19.935457 8.5341005 19.842246 8.21875 19.738281 C 8.0324346 20.322381 7.7923977 20.881837 7.5039062 21.410156 C 7.9293488 21.558042 8.3638413 21.684622 8.8085938 21.779297 C 14.686388 23.030516 20.496911 19.223475 21.759766 13.291016 C 23.022643 7.3584478 19.266466 1.512937 13.388672 0.26171875 C 11.335048 -0.17544038 9.2167772 -0.007875483 7.2597656 0.74414062 C 6.0197479 1.2207036 4.8829096 1.9166387 3.8984375 2.7929688 L 2.0957031 0 z " fill="currentColor" />
</svg>

const redoIcon = <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="m 19.908492,0 1.427734,6.7070312 -6.675781,1.4199219 2.451172,-3.796875 C 14.957377,2.3064602 11.914002,1.415198 8.9924764,2.0371094 4.0842564,3.0819323 0.94962835,7.9620211 2.0041954,12.916016 c 1.054549,4.953907 5.90428,8.134666 10.8124996,7.089843 0.330728,-0.0704 0.6534,-0.163613 0.96875,-0.267578 0.186315,0.5841 0.426352,1.143556 0.714844,1.671875 -0.425443,0.147886 -0.859935,0.274466 -1.304688,0.369141 C 7.3178074,23.030516 1.5072844,19.223475 0.24442933,13.291016 -1.0184477,7.3584478 2.7377294,1.512937 8.6155234,0.26171875 10.669147,-0.17544038 12.787418,-0.00787548 14.744429,0.74414062 15.984447,1.2207036 17.121285,1.9166387 18.105758,2.7929688 Z" fill="currentColor" />
</svg>

//////////////////
// Undo history //
//////////////////

enum Verb {
    ADD_GROUP,
    RENAME_GROUP,
    DELETE_GROUP,
    ADD_MEMBERSHIP,
    DELETE_MEMBERSHIP,
}

type AddGroupAction = {
    verb: Verb.ADD_GROUP,
    user_group_id: string,
    user_group_name: string,
}

type DeleteGroupAction = {
    verb: Verb.DELETE_GROUP,
    user_group_id: string,
    user_group_name: string,
    implied: HistoryAction[],
}

type RenameGroupAction = {
    verb: Verb.RENAME_GROUP,
    user_group_id: string,
    new_name: string,
    old_name: string,
}

type AddMembershipAction = {
    verb: Verb.ADD_MEMBERSHIP,
    user_group_id: string,
    member_id: string,
    member_is_group: boolean,
}

type DeleteMembershipAction = {
    verb: Verb.DELETE_MEMBERSHIP,
    user_group_id: string,
    member_id: string,
    member_is_group: boolean,
}

type HistoryAction = AddGroupAction | DeleteGroupAction | RenameGroupAction | AddMembershipAction | DeleteMembershipAction

function invertHistoryAction(action: HistoryAction): HistoryAction {
    switch (action.verb) {
        case Verb.ADD_GROUP:
            return {...action, 'verb': Verb.DELETE_GROUP, 'implied': []}
        case Verb.DELETE_GROUP:
            return {...action, 'verb': Verb.ADD_GROUP}
        case Verb.RENAME_GROUP:
            return {...action, 'new_name': action.old_name, 'old_name': action.new_name}
        case Verb.ADD_MEMBERSHIP:
            return {...action, 'verb': Verb.DELETE_MEMBERSHIP}
        case Verb.DELETE_MEMBERSHIP:
            return {...action, 'verb': Verb.ADD_MEMBERSHIP}
    }
}

function renameHistoryActionGroupIds(action: HistoryAction, oldId: string, newId: string): HistoryAction {
    const result = {...action}
    if (result.user_group_id === oldId) {
        result.user_group_id = newId
    }
    if (result.verb === Verb.ADD_MEMBERSHIP || result.verb === Verb.DELETE_MEMBERSHIP) {
        if (result.member_is_group && result.member_id === oldId) {
            result.member_id = newId
        }
    }
    if (result.verb === Verb.DELETE_GROUP) {
        result.implied = result.implied.map(a => renameHistoryActionGroupIds(a, oldId, newId))
    }
    return result
}

/////////////////////////////
// Interaction with server //
/////////////////////////////

class UndoHistory {
    private actions: HistoryAction[] = []
    private stops: number[] = []
    private position: number = 0
    private latestIdlePromise: Promise<void> = Promise.resolve()

    ///////////////
    // internals //
    ///////////////

    private async doAction(action: HistoryAction): Promise<UserGroup | boolean | null> {
        try {
            switch (action.verb) {
                case Verb.ADD_GROUP:
                    return await getServicesManager().putUserGroup(action.user_group_name, null, null)
                case Verb.DELETE_GROUP:
                    return await getServicesManager().putUserGroup(null, action.user_group_id, true)
                case Verb.RENAME_GROUP:
                    return await getServicesManager().putUserGroup(action.new_name, action.user_group_id, null)
                case Verb.ADD_MEMBERSHIP:
                    return await getServicesManager().putUserGroupMembership(action.user_group_id, action.member_id, action.member_is_group, null)
                case Verb.DELETE_MEMBERSHIP:
                    return await getServicesManager().putUserGroupMembership(action.user_group_id, action.member_id, action.member_is_group, true)
            }
        } catch (e) {
            console.log(e)  // dunno what else to do
            return false
        }
    }

    private async runForward(destPosition: number): Promise<void> {
        for (; this.position < destPosition; ++this.position) {
            const action = this.actions[this.position]
            const result = await this.doAction(action)
            if (result === false || result === null) {
                this.stops = this.stops.filter(x => x < this.position).concat([this.position])
                this.actions.splice(this.position)
                return  // don't run any more loop iterations
            }
            if (result !== true) {
                const old_id = action.user_group_id
                this.actions.splice(
                    this.position,
                    this.actions.length - this.position,
                    ...this.actions.slice(this.position).map(a => renameHistoryActionGroupIds(a, old_id, (result as UserGroup).user_group_id)),
                )
            }
        }
    }

    private async runBackward(destPosition: number): Promise<void> {
        for (; this.position > destPosition; --this.position) {
            const action = invertHistoryAction(this.actions[this.position - 1])
            const result = await this.doAction(action)
            if (result === false || result === null) {
                this.stops = this.stops.map(x => x - this.position).filter(x => x >= 0)
                this.actions.splice(0, this.position)
                this.position = 0
                return  // don't run any more loop iterations
            }
            if (result !== true) {
                const old_id = action.user_group_id
                this.actions.splice(
                    0,
                    this.position,
                    ...this.actions.slice(0, this.position).map(a => renameHistoryActionGroupIds(a, old_id, (result as UserGroup).user_group_id)),
                )
                // created a group in the process of undoing; might have to re-create implied connections
                const updatedDelete = this.actions[this.position - 1]
                if (updatedDelete.verb === Verb.DELETE_GROUP && updatedDelete.implied.length > 0) {
                    const keepImplied: HistoryAction[] = []
                    for (const impliedAction of updatedDelete.implied) {
                        if (await this.doAction(invertHistoryAction(impliedAction))) {
                            keepImplied.push(impliedAction)
                        }
                    }
                    updatedDelete.implied = keepImplied
                }
            }
        }
    }

    private async idle(): Promise<{'resolve': (value: void | PromiseLike<void>) => void}> {
        const result = {'resolve': () => {}}
        const oldPromise = this.latestIdlePromise
        this.latestIdlePromise = new Promise((resolve, _reject) => { result.resolve = resolve })
        await oldPromise
        return result
    }

    ////////////////
    // public api //
    ////////////////

    async doActions(actions: HistoryAction[]): Promise<void> {
        const idle = await this.idle()
        try {
            this.actions.splice(this.position)
            this.actions.push(...actions)
            this.stops = this.stops.filter(p => p < this.position)
            this.stops.push(this.position, this.actions.length)
            return await this.runForward(this.actions.length)
        } finally {
            idle.resolve()
        }
    }

    async undo(): Promise<void> {
        const idle = await this.idle()
        try {
            const destIdx = this.stops.findIndex(p => p >= this.position) - 1
            if (destIdx >= 0) {
                return await this.runBackward(this.stops[destIdx])
            }
        } finally {
            idle.resolve()
        }
    }

    async redo(): Promise<void> {
        const idle = await this.idle()
        try {
            const destIdx = this.stops.findIndex(p => p > this.position)
            if (destIdx !== -1) {
                return await this.runForward(this.stops[destIdx])
            }
        } finally {
            idle.resolve()
        }
    }

    canUndo(): boolean {
        return this.position > 0
    }

    canRedo(): boolean {
        return this.position < this.actions.length
    }
}

/////////////
// Widgets //
/////////////

const DATE_FORMATTER = new Intl.DateTimeFormat('en', {'year': 'numeric', 'month': 'short', 'day': '2-digit'})

interface DraggableUserProps {
    user: UserDataResult,
    show_date?: boolean,
    source_group_id?: string,
    show_remove_button?: MouseEventHandler,
    other_group_names?: string[],
    highlight?: boolean,
}

class DraggableUser extends React.Component<DraggableUserProps, {}> {
    render(): JSX.Element {
        // it'd be more elegant to make this <li> but then harder to use HoverWrapper (which renders as a div) with it
        const contents = <div
            className={[
                "text-xs flex flex-row items-center gap-2 pl-2",
                (this.props.show_remove_button ? "pr-1" : "pr-2"),
                "py-1 rounded cursor-grab",
                "hover:bg-emerald-200 text-black",
                (this.props.highlight ? "bg-emerald-300" : "bg-slate-200")
            ].join(" ")}
            draggable={true}
            onDragStart={(e) => {
                e.dataTransfer.setData('application/vnd.trellus.user-id', this.props.user.user_id)
                if (this.props.source_group_id) {
                    e.dataTransfer.setData('application/vnd.trellus.source-user-group-id', this.props.source_group_id)
                }
            }}
            onMouseEnter={(e) => {
                e.target.dispatchEvent(new CustomEvent('draggable-user-mouse-enter', {detail: this.props.user.user_id, bubbles: true}))
            }}
            onMouseLeave={(e) => {
                e.target.dispatchEvent(new CustomEvent('draggable-user-mouse-leave', {detail: this.props.user.user_id, bubbles: true}))
            }}
        >
            <div>
                {this.props.user.user_name}
                {this.props.show_date
                    ? <span className="ml-2 text-gray-400">joined {DATE_FORMATTER.format(this.props.user.created_at)}</span>
                    : undefined}
                {this.props.user.team_is_active
                    ? undefined
                    : <span className="ml-2 text-gray-400">inactive</span>}
                {this.props.other_group_names?.length
                    ? <sup>+{this.props.other_group_names.length}</sup>
                    : undefined}
            </div>
            {this.props.show_remove_button
                ? <div className="cursor-pointer h-3 rounded-full hover:text-white hover:bg-red-500" onClick={this.props.show_remove_button}>
                    {React.cloneElement(xIcon, {className: "w-3 h-3"})}</div>
                : undefined}
        </div>
        return (this.props.other_group_names?.length
            ? <HoverWrapper
                labelOrientation="bottom"
                labelClasses="bg-amber-100 border-amber-100 text-center"
                labelHeader="also a member of"
                labelText={this.props.other_group_names.join(', ')}>{contents}</HoverWrapper>
            : contents)
    }
}

/////////////////////
// Everything else //
/////////////////////

const GROUPS_FILTER_HEADER = 'Teams'

// visible state
type UserGroupSettingsState = {
    groups: UserGroup[] | null,
    memberships: UserGroupMembership[] | null,
    creatingGroup: { parentGroupId: string | null } | null,
    renamingGroupId: string | null,
    addingUsersToGroupId: string | null,
    outOfSync: boolean,
    undoEnabled: boolean,
    redoEnabled: boolean,
    draggingFrom: { parentGroupId: string | null, draggingGroupId: string | null } | null,
    highlightedUserId: string | null,
    draggingToGroupId: string | null,
    unassignedUserSearch: string,
}

type UserGroupProps<A extends Action = AnyAction> = {
    visibleAccounts: VisibleAccountsResult | null,
}

class UserGroupImpl extends React.Component<UserGroupProps, UserGroupSettingsState> {

    // handles to stuff
    createGroupInput: React.RefObject<HTMLInputElement> = createRef()
    renameGroupInput: React.RefObject<HTMLInputElement> = createRef()

    // other invisible state
    undoHistory: UndoHistory = new UndoHistory()

    constructor(props: UserGroupProps) {
        super(props)
        this.state = {
            'groups': null,
            'memberships': null,
            'creatingGroup': null,
            'renamingGroupId': null,
            'addingUsersToGroupId': null,
            'outOfSync': true,
            'undoEnabled': false,
            'redoEnabled': false,
            'draggingFrom': null,
            'highlightedUserId': null,
            'draggingToGroupId': null,
            'unassignedUserSearch': '',
        }
    }

    //////////////////////////////////////////////
    // data retrieval and global event handlers //
    //////////////////////////////////////////////

    componentDidMount(): void {
        this.reloadInfo()
        document.addEventListener('dragend', this.onDragEnd)
        document.addEventListener('draggable-user-mouse-enter', this.onDraggableUserMouseEnter)
        document.addEventListener('draggable-user-mouse-leave', this.onDraggableUserMouseLeave)
    }

    reloadInfo() {
        getServicesManager().getUserGroups(true).then((v) => {
            if (v !== null) {
                this.setState({'groups': v.groups, 'memberships': v.memberships})
            }
            this.setState({outOfSync: false})
        })
    }

    componentDidUpdate(prevProps: Readonly<UserGroupProps>, prevState: Readonly<UserGroupSettingsState>, snapshot?: any): void {
        if (this.state.outOfSync && !prevState.outOfSync) this.reloadInfo()
    }

    componentWillUnmount() {
        document.removeEventListener('dragend', this.onDragEnd)
        document.removeEventListener('draggable-user-mouse-enter', this.onDraggableUserMouseEnter)
        document.removeEventListener('draggable-user-mouse-leave', this.onDraggableUserMouseLeave)
    }

    // these handlers are written as (class members with default) arrow functions
    // so that `this` is captured by defaults being evaluated in the constructor scope
    // and resists being repointed by the event listener infrastructure
    onDragEnd = () => this.setState({'draggingFrom': null, 'draggingToGroupId': null, 'highlightedUserId': null})
    onDraggableUserMouseEnter = (e: Event) => this.setState({'highlightedUserId': (e as CustomEvent).detail ?? null})
    onDraggableUserMouseLeave = () => this.setState({'highlightedUserId': null})

    /////////////////////////////////////
    // pass actions to history manager //
    /////////////////////////////////////

    previewAction(action: HistoryAction): boolean {
        switch (action.verb) {
            case Verb.ADD_MEMBERSHIP:
                this.setState(state => {
                    return {
                        'memberships': state.memberships
                            ?.filter(m => !(m.user_group_id === action.user_group_id && m.member_is_group === action.member_is_group && m.member_id === action.member_id))
                            .concat([{
                                'user_group_id': action.user_group_id,
                                'member_id': action.member_id,
                                'member_is_group': action.member_is_group,
                                'team_id': '',
                            }]) ?? null,
                    }
                })
                return true
            case Verb.DELETE_MEMBERSHIP:
                this.setState(state => {
                    return {
                        'memberships': state.memberships?.filter(
                            m => !(m.user_group_id === action.user_group_id && m.member_is_group === action.member_is_group && m.member_id === action.member_id)
                        ) ?? null,
                    }
                })
                return true
            case Verb.ADD_GROUP:
                // better not to give instant feedback in this case
                // if we do, then there's a flash where the group first appears unattached
                // if we don't, there's no risk of double action because the user has to go click the button again
                return true
            case Verb.RENAME_GROUP:
                this.setState(state => {
                    const oldGroup = state.groups?.filter(g => g.user_group_id === action.user_group_id)[0] ?? null
                    const bah = {
                        'groups': oldGroup === null
                            ? state.groups
                            : state.groups?.filter(g => g.user_group_id !== action.user_group_id).concat([{
                                ...oldGroup,
                                user_group_name: action.new_name,
                            }]) ?? null,
                    }
                    return bah
                })
                return true
            case Verb.DELETE_GROUP:
                this.setState(state => {
                    return {
                        'groups': state.groups?.filter(g => g.user_group_id !== action.user_group_id) ?? null,
                        'memberships': state.memberships?.filter(m =>
                            m.user_group_id !== action.user_group_id
                            || !(m.member_is_group && m.member_id === action.user_group_id)
                        ) ?? null,
                    }
                })
                return true
        }
    }

    onActionCompleted() {
        this.setState({
            'outOfSync': true,
            'undoEnabled': this.undoHistory.canUndo(),
            'redoEnabled': this.undoHistory.canRedo(),
        })
    }

    async doActions(actions: HistoryAction[]): Promise<void> {
        actions.forEach(a => this.previewAction(a))
        await this.undoHistory.doActions(actions)
        this.onActionCompleted()
    }

    async undo(): Promise<void> {
        await this.undoHistory.undo()
        this.onActionCompleted()
    }

    async redo(): Promise<void> {
        await this.undoHistory.redo()
        this.onActionCompleted()
    }

    /////////////
    // widgets //
    /////////////

    renderUserPicker(unassignedUsers: UserDataResult[], unassignedGroups: UserGroup[], parentGroupId: string): ReactElement {
        const usersToSelect: FilterOption[] = unassignedUsers
            .map(u => {return {'label': u.user_name, 'selected': false, 'value': u.user_id, 'id': 'Users'}})
            .sort((u, v) => u.label.localeCompare(v.label))
        const subgroupsToSelect: FilterOption[] = unassignedGroups
            .map(g => {return {'label': g.user_group_name, 'selected': false, 'value': g.user_group_id, 'id': GROUPS_FILTER_HEADER}})
            .sort((g, h) => g.label.localeCompare(h.label))
        const createSubgroupOption: FilterOption = {'label': '(create new...)', 'selected': false, 'value': '', 'id': GROUPS_FILTER_HEADER}
        return <Filter
            filterType={FilterType.SINGLE}
            variant={'largeParagraph'}
            closeOnSelect={false}
            selectableFilterProps={{
                'openFilterDefault': true,
                'usesRelativeAtParent': true,
                'headerElement': <Button text="select member..." onClick={() => {}} />,
                'noMaxHeight': true,
                'filterOptions': usersToSelect.concat(subgroupsToSelect, [createSubgroupOption]),
                'groupById': true,
                'clickoutIsEmptyUpdate': true,
                'onFilterUpdate': (updatedFilters: FilterOption[]) => {
                    if (updatedFilters.some(v => v.selected && v.id === GROUPS_FILTER_HEADER && v.value === '')) {
                        this.setState({'creatingGroup': {'parentGroupId': parentGroupId}, 'addingUsersToGroupId': null})
                    } else {
                        const actions: HistoryAction[] = updatedFilters.map(v => {
                            return {
                                verb: v.selected ? Verb.ADD_MEMBERSHIP : Verb.DELETE_MEMBERSHIP,
                                user_group_id: parentGroupId,
                                member_is_group: v.id === GROUPS_FILTER_HEADER,
                                member_id: v.value,
                            }
                        })
                        if (actions.length > 0) { this.doActions(actions) }
                        if (updatedFilters.length === 0) {
                            this.setState({'addingUsersToGroupId': null})
                        }
                    }
                }
            }}
        />
    }

    ///////////
    // forms //
    ///////////

    async submitPendingGroup(): Promise<void> {
        const target = this.createGroupInput.current
        if (target !== null && target.value.length > 0) {
            const tempGroupId = uuid()
            const actions: HistoryAction[] = [{
                verb: Verb.ADD_GROUP,
                user_group_id: tempGroupId,
                user_group_name: target.value,
            }]
            if (this.state.creatingGroup !== null && this.state.creatingGroup.parentGroupId !== null) {
                actions.push({
                    verb: Verb.ADD_MEMBERSHIP,
                    user_group_id: this.state.creatingGroup.parentGroupId,
                    member_is_group: true,
                    member_id: tempGroupId,
                })
            }
            this.doActions(actions)
        }
        this.setState({'creatingGroup': null})
    }

    renderPendingGroup(): JSX.Element {
        return <Foldy
            className={this.state.creatingGroup?.parentGroupId ? 'user-groups-inner-foldy' : 'user-groups-outer-foldy'}
            head={<form method="post" action="#" onSubmit={(e) => { e.preventDefault(); this.submitPendingGroup() }}>
                <input
                    ref={this.createGroupInput}
                    type="text"
                    placeholder="Group name"
                    autoFocus={true}
                    className="text-xs ml-1 py-3 px-2 rounded-lg"
                    onBlur={() => this.submitPendingGroup()} />
            </form>}
        />
    }

    submitRenameGroup(userGroupId: string, oldName: string): void {
        const target = this.renameGroupInput.current
        if (userGroupId !== null && target !== null && target.value.length > 0 && target.value !== oldName) {
            this.doActions([{
                verb: Verb.RENAME_GROUP,
                user_group_id: userGroupId,
                new_name: target.value,
                old_name: oldName,
            }])
        }
        this.setState({'renamingGroupId': null})
    }

    renderRenameGroup(userGroupId: string, initialValue: string): JSX.Element {
        return <form method="post" action="#" onSubmit={(e) => { e.preventDefault(); this.submitRenameGroup(userGroupId, initialValue) }}>
            <input
                ref={this.renameGroupInput}
                type="text"
                placeholder="Group name"
                autoFocus={true}
                className="text-xs py-1 px-2 rounded"
                defaultValue={initialValue}
                onBlur={() => this.submitRenameGroup(userGroupId, initialValue)}
            />
        </form>
    }

    ///////////////////
    // drag and drop //
    ///////////////////

    enableDrop(e: DragEvent, destinationGroupId: string | null, invalid?: boolean): void {
        e.preventDefault()
        e.stopPropagation()  // drag targets might be nested
        if (
            // exclude recursively invalid targets
            !invalid
            // require data that identifies what's being dragged
            && e.dataTransfer !== null
            && (e.dataTransfer.types.indexOf('application/vnd.trellus.user-id') !== -1
                || e.dataTransfer.types.indexOf('application/vnd.trellus.user-group-id') !== -1)
            // exclude drags from null to null
            && (e.dataTransfer.types.indexOf('application/vnd.trellus.source-user-group-id') !== -1
                || destinationGroupId)
            // exclude copies to null
            && !(e.ctrlKey && destinationGroupId === null)
        ) {
            e.dataTransfer.dropEffect = e.ctrlKey ? 'copy' : 'link'
            this.setState({'draggingToGroupId': destinationGroupId})
        } else {  // detected drop as invalid so hint at it in the UI
            e.dataTransfer.dropEffect = 'none'
            this.setState({'draggingToGroupId': null})
        }
    }

    activateDrop(e: DragEvent, destinationGroupId: string | null, invalid?: boolean): void {
        e.preventDefault()
        e.stopPropagation()  // drag targets might be nested
        this.onDragEnd()
        if (invalid) { return }  // child wants to prevent drop from reaching parent (e.g. invalid target over hot background)
        const userId = e.dataTransfer.getData('application/vnd.trellus.user-id')
        const subgroupId = e.dataTransfer.getData('application/vnd.trellus.user-group-id')
        const sourceGroupId = e.dataTransfer.getData('application/vnd.trellus.source-user-group-id')
        const actions: HistoryAction[] = []
        if (destinationGroupId === (sourceGroupId || null)) { return }  // no-op shortcut
        if (userId && this.props.visibleAccounts?.users.some(u => u.user_id === userId)) {
            if (sourceGroupId && !(e.dataTransfer.dropEffect === 'copy' || e.ctrlKey)) {
                actions.push({
                    verb: Verb.DELETE_MEMBERSHIP,
                    user_group_id: sourceGroupId,
                    member_is_group: false,
                    member_id: userId,
                })
            }
            if (destinationGroupId !== null) {
                actions.push({
                    verb: Verb.ADD_MEMBERSHIP,
                    user_group_id: destinationGroupId,
                    member_is_group: false,
                    member_id: userId,
                })
            }
        } else if (subgroupId && subgroupId !== destinationGroupId) {
            // remove from other parent groups (for now, we insist the graph is a tree)
            Array.prototype.push.apply(
                actions,
                this.state.memberships
                    ?.filter(m => m.member_is_group && m.member_id === subgroupId)
                    .map(m => { return {verb: Verb.DELETE_MEMBERSHIP, user_group_id: m.user_group_id, member_is_group: true, member_id: subgroupId} }) ?? [],
            )
            if (destinationGroupId !== null) {
                actions.push({
                    verb: Verb.ADD_MEMBERSHIP,
                    user_group_id: destinationGroupId,
                    member_is_group: true,
                    member_id: subgroupId,
                })
            }
        }
        if (actions.length > 0) { this.doActions(actions) }
    }

    //////////////////////////
    // monster render sorry //
    //////////////////////////

    render() {
        if (this.props.visibleAccounts === null || this.state.groups === null || this.state.memberships === null) return <Loader />
        const groupIdToGroup: Record<string, UserGroup> = Object.fromEntries(this.state.groups.map(g => [g.user_group_id, g]))
        const groupIdToSubgroups: Record<string, string[]> = Object.fromEntries(this.state.groups.map(g => [g.user_group_id, []]))
        const groupIdToUserIds: Record<string, string[]> = Object.fromEntries(this.state.groups.map(g => [g.user_group_id, []]))
        const groupIdToParents: Record<string, string[]> = Object.fromEntries(this.state.groups.map(g => [g.user_group_id, []]))
        const userIdToGroups: Record<string, string[]> = Object.fromEntries(this.props.visibleAccounts.users.map(u => [u.user_id, []]))
        const userIdToUser: Record<string, UserDataResult> = Object.fromEntries(this.props.visibleAccounts.users.map(u => [u.user_id, u]))
        const userNameToCount: Record<string, number> = Object.fromEntries(this.props.visibleAccounts.users.map(u => [u.user_name, 0]))
        this.state.memberships.forEach(m => {
            if (m.member_is_group) {
                groupIdToSubgroups[m.user_group_id]?.push(m.member_id)
                groupIdToParents[m.member_id]?.push(m.user_group_id)
            } else {
                groupIdToUserIds[m.user_group_id]?.push(m.member_id)
                userIdToGroups[m.member_id]?.push(m.user_group_id)
            }
        })

        this.props.visibleAccounts.users.forEach(u => userNameToCount[u.user_name] += 1)
        const unassignedUsers = Object.entries(userIdToGroups)
            .filter(([_userId, groups]) => groups.length === 0)
            .map(([userId, _groups]) => userIdToUser[userId])
            .filter(u => typeof u !== 'undefined')
            .filter(u => u.team_is_active)
            .sort((a, b) => a.user_name.localeCompare(b.user_name) || (a.created_at.valueOf() - b.created_at.valueOf()))
        const unassignedGroups = Object.entries(groupIdToParents)
            .filter(([_userGroupId, parents]) => parents.length === 0)
            .map(([userGroupId, _parents]) => groupIdToGroup[userGroupId])
            .filter(g => typeof g !== 'undefined')
            .sort((a, b) => a.user_group_name.localeCompare(b.user_group_name))
        const root = this

        function _renderGroupFoldyById(userGroupId: string, parentGroupId: string | null, rootGroupId: string, depth: number, isRecursingDropTarget: boolean): ReactElement | undefined {
            const group = groupIdToGroup[userGroupId]
            if (typeof group === 'undefined') { return undefined; }
            const memberUsers: UserDataResult[] = groupIdToUserIds[userGroupId]
                ?.map(userId => userIdToUser[userId])
                .filter(u => typeof u !== 'undefined')
                .sort((a, b) => a.user_name.localeCompare(b.user_name) || (a.created_at.valueOf() - b.created_at.valueOf())) ?? []
            const subgroups = groupIdToSubgroups[userGroupId]
                ?.map(subgroupId => groupIdToGroup[subgroupId])
                .filter(g => typeof g !== 'undefined')
                .sort((g, h) => g.user_group_name.localeCompare(h.user_group_name))
            const userList = <div className="flex flex-row flex-wrap gap-2 grow">
                {memberUsers.map(u => <DraggableUser
                    key={u.user_id} user={u}
                    show_date={(userNameToCount[u.user_name] ?? 1) > 1}
                    source_group_id={userGroupId}
                    show_remove_button={() => root.doActions([{
                        verb: Verb.DELETE_MEMBERSHIP,
                        user_group_id: userGroupId,
                        member_is_group: false,
                        member_id: u.user_id,
                    }])}
                    highlight={u.user_id === root.state.highlightedUserId}
                    other_group_names={userIdToGroups[u.user_id]
                        ?.filter(g => g !== userGroupId)
                        .map(g => groupIdToGroup[g]?.user_group_name)
                        .filter(g => g) ?? []}
                />)}
                {root.state.addingUsersToGroupId === userGroupId
                    ? root.renderUserPicker(unassignedUsers, unassignedGroups.filter(g => g.user_group_id !== rootGroupId), userGroupId)
                    : <Button text={memberUsers.length > 0 ? "+" : "Add members..."}
                        hoverText="add users or subgroups"
                        onClick={() => {root.setState({'addingUsersToGroupId': userGroupId})}} />}
            </div>
            const menuActions: DropDownMenuEntry[] = [
                {label: "rename team", onClick: () => root.setState({'renamingGroupId': userGroupId})},
                {label: "create nested subteam", onClick: () => root.setState({'creatingGroup': {'parentGroupId': userGroupId}})},
                ...(parentGroupId !== null ? [{
                    label: 'detach from parent team',
                    onClick: () => root.doActions([{
                        verb: Verb.DELETE_MEMBERSHIP,
                        user_group_id: parentGroupId,
                        member_is_group: true,
                        member_id: userGroupId
                    }]),
                }] : []),
                {label: "delete team", onClick: () => {
                    const impliedDetachUsers: DeleteMembershipAction[] = memberUsers.map(u => { return {verb: Verb.DELETE_MEMBERSHIP, user_group_id: userGroupId, member_id: u.user_id, member_is_group: true} })
                    const impliedDetachSubgroups: DeleteMembershipAction[] = subgroups.map(g => { return {verb: Verb.DELETE_MEMBERSHIP, user_group_id: userGroupId, member_id: g.user_group_id, member_is_group: true} })
                    const impliedDetachParents: DeleteMembershipAction[] = (groupIdToParents[userGroupId] ?? []).map(p => { return {verb: Verb.DELETE_MEMBERSHIP, user_group_id: p, member_id: userGroupId, member_is_group: true} })
                    root.doActions([{
                        verb: Verb.DELETE_GROUP,
                        user_group_id: group.user_group_id,
                        user_group_name: group.user_group_name,
                        implied: [...impliedDetachUsers, ...impliedDetachSubgroups, ...impliedDetachParents],
                    }])
                }},
            ]
            isRecursingDropTarget = isRecursingDropTarget || (root.state.draggingFrom !== null && root.state.draggingFrom.draggingGroupId === userGroupId)
            const isValidDropTarget = !isRecursingDropTarget && root.state.draggingFrom && root.state.draggingFrom.parentGroupId !== userGroupId
            return <Foldy
                key={userGroupId}
                className={depth > 0 ? 'user-groups-inner-foldy' : 'user-groups-outer-foldy'}
                startOpen={true}
                onlyArrowClickToFold={true}
                head={<div
                    className={`grow flex flex-row gap-4 px-2 py-2 ml-1 items-baseline justify-start border ${
                        root.state.draggingFrom === null ? 'bg-white border-white'
                        : !isValidDropTarget ? 'bg-gray-100 border-gray-100'
                        : root.state.draggingToGroupId === userGroupId ? 'bg-emerald-200 border-emerald-300'
                        : 'bg-white border-emerald-300'}`}
                    style={{
                        'borderRadius': '10px',
                        'boxShadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                    }}
                    draggable={userGroupId !== root.state.renamingGroupId}
                    onDragStart={(e) => {
                        if (
                            e.dataTransfer.getData('application/vnd.trellus.user-id')
                            || e.dataTransfer.getData('application/vnd.trellus.user-group-id')
                        ) {
                            return  // dragging something from inside the group so don't clobber it
                        }
                        e.dataTransfer.setData('application/vnd.trellus.user-group-id', userGroupId)
                        if (parentGroupId) {
                            e.dataTransfer.setData('application/vnd.trellus.source-user-group-id', parentGroupId)
                        }
                    }}
                    onDragOver={(e) => root.enableDrop(e, userGroupId, !isValidDropTarget)}
                    onDrop={(e) => root.activateDrop(e, userGroupId, !isValidDropTarget)}
                >
                    <div
                        className="cursor-grab whitespace-nowrap"
                        onDoubleClick={(e) => root.setState({'renamingGroupId': userGroupId})}
                    >
                        {userGroupId === root.state.renamingGroupId
                            ? root.renderRenameGroup(userGroupId, group.user_group_name)
                            : <Typography variant="h2">{group.user_group_name}</Typography>}
                    </div>
                    {userList}
                    <div className="self-center">
                        <DropDownMenu
                            alignmentClassName="top-0 right-0"
                            activator={<div className="cursor-pointer p-1 rounded-lg text-gray-500 bg-white hover:text-black hover:bg-slate-200">{vdotsIcon}</div>}
                            entries={menuActions}/>
                    </div>
                </div>}
            >
                {subgroups.length > 0 || (root.state.creatingGroup !== null && root.state.creatingGroup.parentGroupId === userGroupId)
                    ? <div className="flex flex-col gap-4 pt-4 justify-start">
                            {subgroups.map(subgroup => _renderGroupFoldyById(subgroup.user_group_id, userGroupId, rootGroupId, depth + 1, isRecursingDropTarget))}
                            {root.state.creatingGroup !== null && root.state.creatingGroup.parentGroupId === userGroupId
                                ? root.renderPendingGroup()
                                : undefined}
                        </div>
                    : undefined}
            </Foldy>
        }

        const searchCaseSensitive = this.state.unassignedUserSearch.toLocaleLowerCase() !== this.state.unassignedUserSearch
        return <div
            className="flex-grow min-w-fit w-full min-h-fit h-full flex flex-row px-2 pb-2 gap-2 overflow-auto"
            style={{'userSelect': 'text'}}
            onDragStart={(e) => this.setState({'draggingFrom': {
                'draggingGroupId': e.dataTransfer.getData('application/vnd.trellus.user-group-id') || null,
                'parentGroupId': e.dataTransfer.getData('application/vnd.trellus.source-user-group-id') || null,
            }})}
            onDragOver={(e) => this.enableDrop(e, null)} onDrop={(e) => this.activateDrop(e, null)}
        >
            <div
                className="h-full grow flex flex-col gap-4 relative"
                style={{'minWidth': '250px'}}
            >
                {/* pb-16 makes some space signifying the end (also space for hovertext) */}
                <div className="grow flex flex-col gap-4 justify-start overflow-y-auto pb-16 pt-2 px-2">
                    <Typography variant="h1">
                        Subteams
                    </Typography>
                    {unassignedGroups.map(g => _renderGroupFoldyById(g.user_group_id, null, g.user_group_id, 0, false))}
                    {this.state.creatingGroup !== null && this.state.creatingGroup.parentGroupId === null
                        ? this.renderPendingGroup()
                        : <Foldy
                            head={<Button
                                text="Create team"
                                className="ml-1"
                                onClick={(e) => { this.setState({ 'creatingGroup': { parentGroupId: null } }) }} />}
                            className="user-groups-outer-foldy"
                            />}
                </div>
                {/* float undo buttons */}
                <div className="absolute right-2 top-2 w-20 h-8 flex flex-row gap-2 justify-end">
                <Button text={undoIcon} hoverText="Undo" onClick={() => this.undo()} disabled={!this.state.undoEnabled} />
                <Button text={redoIcon} hoverText="Redo" onClick={() => this.redo()} disabled={!this.state.redoEnabled} />
                </div>
            </div>
            <div className="h-full flex flex-col py-4 gap-4 px-2 bg-white"
                onDragOver={(e) => this.enableDrop(e, null)} onDrop={(e) => this.activateDrop(e, null)}
                style={{
                    'minWidth': '250px',
                    'borderRadius': '10px',
                    'boxShadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                }}
            >
                <Typography variant="h1">
                    Unassigned users
                </Typography>
                <SearchBox
                    value={this.state.unassignedUserSearch}
                    onChange={(e) => this.setState({'unassignedUserSearch': e.target.value})}
                    placeholder="Filter" />
                <div className="grow flex flex-col justify-start gap-2 overflow-y-auto">
                    {unassignedUsers
                        .filter(u => searchCaseSensitive
                            ? u.user_name.includes(this.state.unassignedUserSearch)
                            : u.user_name.toLocaleLowerCase().includes(this.state.unassignedUserSearch))
                        .map(u => <DraggableUser key={u.user_id} user={u} show_date={(userNameToCount[u.user_name] ?? 1) > 1} />)}
                </div>
            </div>
        </div>
    }
}


const ReduxWrapped = connect((state: RootState) => {
    return {
        visibleAccounts: convertFromReduxSafeVisibleAccounts(state.visibleAccounts),
    }
})(UserGroupImpl)

export { ReduxWrapped as UserGroupSettings}