import { convertFromReduxSafeUserState, convertFromReduxSafeVisibleAccounts, reloadAdminWrite, reloadAuthorizationInfoInitialState, reloadDispositionOptions, reloadExternalAccounts, reloadGroups, reloadProspectInfoOptions, reloadTeam, reloadUser, reloadUserSettings, reloadVisibleAccounts } from 'lib/redux/store';
import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { AnyAction } from 'redux';
import { RootState } from 'store';
import { connect } from 'react-redux';
import { UserDataResult, VisibleAccountsResult, SettingsResult, TeamResult } from 'interfaces/services';
import { AuthorizationInfo } from 'components/Authentication/utils';
import { Loader } from 'components/Loader';

// Define the types for the props
interface PrivateRouteProps {
    onlyLoadAuthenticationInfo?: boolean;
    onlyProgressOnAuthenticationLoad?: boolean;
    authentication_info: AuthorizationInfo | null;
    dispatch: Dispatch<AnyAction>;
    component: React.ComponentType<any>;
    user: UserDataResult | null
    visibleAccounts: VisibleAccountsResult | null
    userSettings: SettingsResult | null
    team: TeamResult | null
}

const PrivateRouteImpl: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }: PrivateRouteProps) => {
    // Effect to run only once on mount
    useEffect(() => {
        if (!rest.authentication_info) { 
            reloadAuthorizationInfoInitialState(rest.dispatch);
        }
        if (!rest.onlyLoadAuthenticationInfo) {
            if (!rest.user) reloadUser(rest.dispatch);
            if (rest.userSettings === null) reloadUserSettings(rest.dispatch);
            if (!rest.visibleAccounts) reloadVisibleAccounts(rest.dispatch);
            if (!rest.team) reloadTeam(rest.dispatch);
        }
    }, []); // Empty array ensures this runs only once on mount
    if (rest.onlyProgressOnAuthenticationLoad && !rest.authentication_info) return <Loader />

    return <Component {...rest} />;
};

const mapStateToProps = (state: RootState) => {
    return {
        authentication_info: state.authenticationInfo.value,
        visibleAccounts: convertFromReduxSafeVisibleAccounts(state.visibleAccounts, (u) => u.team_is_active && u.can_dial),
        user: convertFromReduxSafeUserState(state.user),
        userSettings: state.settings.value,
        team: state.team.value
    };
  };
  
const ReduxWrapped = connect(mapStateToProps)(PrivateRouteImpl)

export { ReduxWrapped as ExtensionPageWrap}