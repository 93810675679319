import React from "react";

type SwitchElementProps = {
    isOn: boolean
    onClick: () => void
}

export class SwitchElement extends React.PureComponent<SwitchElementProps, {}> {
    render () {
        return <div>
            <div className="relative" onClick={this.props.onClick} style={{'width': '30px', 'height': '15px', 'backgroundColor': this.props.isOn ? '#4CAF50' : '#ccc', 'borderRadius': '15px', 'cursor': 'pointer'}}>
                <div style={{'width': '13px', 'height': '13px', 'backgroundColor': '#fff', 'borderRadius': '50%', 'position': 'absolute', 'top': '1px', 'left': this.props.isOn ? '16px': '1px', 'boxShadow': '0 0 5px rgba(0, 0, 0, 0.3)', 'transition': 'left 0.3s' }}>
                </div>
            </div>
        </div>
    }
}
