import { EditableAutosizedInput } from "components/EditableList";
import { Filter, FilterOption, FilterType } from "components/Filter";
import { DEFAULT_ID, SyncEngine, SyncGroup, SyncGroupMembership, SyncItem, SyncSubItem } from "components/SyncEngine";
import { PartyRole } from "interfaces/db";
import { DEFAULT_LANGUAGE_SETTING, Keyword, KeywordGroup, KeywordGroupMembership, KeywordPhrase } from "interfaces/services";
import { Typography } from "interfaces/typography";
import React from "react";
import uuid from "react-uuid";
import { getServicesManager } from "services";

export class Keywords extends React.Component<{}, {}> {
    _transformToSyncGroup(group: KeywordGroup): SyncGroup {
        return {
            'groupId': group.keyword_group_id,
            'groupName': group.keyword_group_name,
            'tempGroupId': group.temp_group_id
        }
    }

    async _getGroups(): Promise<SyncGroup[] | null> {
        const groups = await getServicesManager().getKeywordGroups()
        if (!groups) return null
        return groups.map((v) => this._transformToSyncGroup(v))
    }

    async _addGroup(group: SyncGroup): Promise<SyncGroup | null> {
        const newGroup = await getServicesManager().updateKeywordGroup(null, group.groupName, null)
        if (!newGroup || typeof newGroup === 'boolean') return null
        return this._transformToSyncGroup(newGroup)
    }

    async _updateGroup(group: SyncGroup): Promise<boolean> {
        const success = await getServicesManager().updateKeywordGroup(group.groupId, group.groupName, null)
        return success ? true : false
    }

    async _removeGroup(group: SyncGroup): Promise<boolean> {
        const success = await getServicesManager().updateKeywordGroup(group.groupId, null, true)
        return success ? true : false
    }

    _compareGroupProperties(a: SyncGroup, b: SyncGroup): boolean {
        return a.groupName === b.groupName
    }

    _constructDefaultGroup(): SyncGroup {
        return {
            'groupId': DEFAULT_ID,
            'groupName': 'Default',
            'tempGroupId': uuid()
        }
    }

    _renderGroupInfo(group: SyncGroup, onGroupModification: (groupName: string) => void): JSX.Element {
        return (
        <div className="w-full flex flex-row gap-2 items-center">
            <Typography variant="largeParagraph" style={{'fontWeight': 650}}>
                Group Name:
            </Typography>
            <div style={{'width': '120px', 'maxWidth': '120px'}}>
                <EditableAutosizedInput 
                prompt={group.groupName} 
                maxNumber={1}
                blurOnEnter={true}
                placeholder={"Add group name"}
                editFunctions={{
                addEntry: () => {},
                updateEntry: (_: number, updatedEntry: string) => {onGroupModification(updatedEntry)
                },
                removeEntry: () => {}
                }} idx={0} numberLeft={0} />
            </div>
        </div>)
    }

    _transformToSyncGroupMembership(membership: KeywordGroupMembership): SyncGroupMembership {
        return {
            'groupId': membership.keyword_group_id,
            'itemId': membership.keyword_id,
            'tempGroupId': membership.temp_group_id,
            'tempItemId': membership.temp_keyword_id
        }
    }

    async _getGroupMemberships(): Promise<SyncGroupMembership[] | null> {
        const groupMemberships = await getServicesManager().getKeywordGroupMemberships()
        if (!groupMemberships) return null
        return groupMemberships.map((v) => this._transformToSyncGroupMembership(v))
    }

    async _addGroupMembership(groupMembership: SyncGroupMembership): Promise<boolean> {
        return await getServicesManager().updateKeywordGroupMemberships(groupMembership.itemId, groupMembership.groupId, null)
    }

    async _removeGroupMembership(groupMembership: SyncGroupMembership): Promise<boolean> {
        return await getServicesManager().updateKeywordGroupMemberships(groupMembership.itemId, groupMembership.groupId, true)
    }

    _constructDefaultGroupMemberships(group: SyncGroup, item: SyncItem): SyncGroupMembership { 
        return {
            'groupId': group.groupId,
            'itemId': item.itemId,
            'tempGroupId': group.tempGroupId,
            'tempItemId': item.tempItemId
        }
    }

    _transformToSyncItem(keyword: Keyword): SyncItem {
        return {
            'itemId': keyword.keyword_id,
            'properties': {
                'keyword_name': keyword.keyword_name,
                'party_role': keyword.party_role
            },
            'tempItemId': keyword.temp_keyword_id
        }
    } 

    _transformToKeyword(item: SyncItem): Keyword { 
        return {
            'keyword_id': item.itemId,
            'temp_keyword_id': item.tempItemId,
            'keyword_name': item.properties['keyword_name'],
            'party_role': item.properties['party_role']
        }
    }

    async _getSyncItems(): Promise<SyncItem[] | null> {
        const keywords = await getServicesManager().getKeywords()
        if (!keywords) return null
        return keywords.map((v) => this._transformToSyncItem(v)) 
    }

    async _addSyncItem(item: SyncItem): Promise<SyncItem | null> {
        const keyword = this._transformToKeyword(item)
        const newKeyword = await getServicesManager().updateKeyword(null, keyword.keyword_name, keyword.party_role, null)
        if (!newKeyword || typeof newKeyword === 'boolean') return null
        return this._transformToSyncItem(newKeyword)
    }

    async _updateSyncItem(item: SyncItem): Promise<boolean> {
        const keyword = this._transformToKeyword(item)
        const success = await getServicesManager().updateKeyword(keyword.keyword_id, keyword.keyword_name, keyword.party_role, null)
        return success ? true : false
    }

    async _removeSyncItem(item: SyncItem): Promise<boolean> {
        const keyword = this._transformToKeyword(item)
        const success = await getServicesManager().updateKeyword(keyword.keyword_id, keyword.keyword_name, keyword.party_role, true)
        return success ? true : false
    }

    _compareSyncItems(a: SyncItem, b: SyncItem): boolean {
        const keywordA = this._transformToKeyword(a)
        const keywordB =  this._transformToKeyword(b)
        return keywordA.keyword_name === keywordB.keyword_name && keywordA.party_role === keywordB.party_role
    }

    _constructDefaultSyncItem(group: SyncGroup): SyncItem {
        const defaultKeyword: Keyword = {
            'keyword_id': DEFAULT_ID,
            'keyword_name': '',
            'party_role': null,
            'temp_keyword_id': uuid()
        }
        return this._transformToSyncItem(defaultKeyword)
    }

    _renderFilterElement(text: string) {
        return <div className="flex justify-center items-center text-center w-fit pl-2 pr-2 pt-1 pb-1 bg-white cursor-pointer" style={
        {
            'backgroundColor': 'white',
            'borderRadius': '10px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
        }
        }>
        <Typography variant="largeParagraph">
            {text !== '' ? text : `None selected`}
        </Typography>
        </div>
    }

    _renderItemInfo(item: SyncItem, onItemModification: (properties: any) => void, subItems: SyncSubItem[],  onSubItemModification: (subItemId: string, properties: any) => void): JSX.Element {
        const keyword = this._transformToKeyword(item)
        const keywordPhrases = subItems.map((v) => this._transformToKeywordPhrase(v))
        const filters: FilterOption[] = [
            {'label': 'All text', 'value': 'ALL', 'selected': keyword.party_role === null}, 
            {'label': 'Rep text', 'value': PartyRole.REP, 'selected': keyword.party_role === PartyRole.REP}, 
            {'label': 'Prospect text', 'value': PartyRole.PROSPECT, 'selected': keyword.party_role === PartyRole.PROSPECT }]
        const keywordPhraseFilters: FilterOption[] = keywordPhrases.map((v) => {return {'label': v.keyword_phrase, 'selected': v.boost, 'value': v.keyword_phrase_id !== DEFAULT_ID ? v.keyword_phrase_id : v.temp_keyword_phrase_id ?? ''}})
        return (
        <div className="flex flex-col gap-3 items-center flex-wrap">
            <div className="w-full flex flex-row gap-2 items-center flex-start">
                <Typography variant="largeParagraph">
                    Keyword:
                </Typography>
                <div style={{'width': '120px', 'maxWidth': '120px'}}>
                    <EditableAutosizedInput 
                    prompt={keyword.keyword_name} 
                    fontWeight={400}
                    maxNumber={1}
                    blurOnEnter={true}
                    placeholder={"Add name"}
                    editFunctions={{
                    addEntry: () => {},
                    updateEntry: (_: number, updatedEntry: string) => onItemModification({
                        ...item.properties,
                        'keyword_name': updatedEntry
                    }),
                    removeEntry: () => {}
                    }} idx={0} numberLeft={0} />
                </div>
            </div>
            <div className="flex flex-row gap-3 items-center">
            <div className="flex flex-row gap-2 items-center">
                <Typography variant="largeParagraph">Matches on:</Typography>
                <Filter
                filterType={FilterType.SINGLE}
                closeOnSelect={true}
                variant={'largeParagraph'}
                selectableFilterProps={{
                    headerElement: this._renderFilterElement(filters.filter((v) => v.selected)[0].label.toString() ?? ''),
                    filterOptions: filters,
                    onFilterUpdate: (updatedFilters: FilterOption[]) => {
                        const selectedFilter = updatedFilters.find((v) => v.selected)
                        if (!selectedFilter) return
                        const partyRole = selectedFilter.value === 'ALL' ? null : selectedFilter.value as PartyRole   
                        onItemModification.bind(this)({
                            ...item.properties,
                            'party_role': partyRole
                        })
                    }
                }}
                />
            </div>

            <div className="flex flex-row gap-2 items-center">
                <Typography variant="largeParagraph">Boost phrase:</Typography>
                <Filter
                filterType={FilterType.MULTI}
                closeOnSelect={keywordPhraseFilters.length === 1}
                variant={'largeParagraph'}
                selectableFilterProps={{
                    headerElement: this._renderFilterElement(keywordPhrases.filter((v) => v.boost).map((v) => v.keyword_phrase).join(', ')),
                    filterOptions: keywordPhraseFilters,
                    onFilterUpdate: (updatedFilters: FilterOption[]) => {
                        for (const filter of updatedFilters) {
                            const matchingSubItem = subItems.find((v) => filter.value === v.subItemId || filter.value === v.tempSubItemId)
                            if (!matchingSubItem) continue
                            onSubItemModification(filter.value, {...matchingSubItem.properties, 'boost': filter.selected})
                        }
                    }
                }}
                />
                </div>
            </div>
        </div>)
    }

    _transformToSyncSubItem(keywordPhrase: KeywordPhrase): SyncSubItem {
        return {
            'itemId': keywordPhrase.keyword_id,
            'subItemId': keywordPhrase.keyword_phrase_id,
            'tempItemId': keywordPhrase.temp_keyword_id,
            'tempSubItemId': keywordPhrase.temp_keyword_phrase_id,
            'properties': {
                'keyword_phrase': keywordPhrase.keyword_phrase,
                'language': keywordPhrase.language,
                'boost': keywordPhrase.boost 
            }
        }
    }

    _transformToKeywordPhrase(subItem: SyncSubItem): KeywordPhrase {
        return {
            'keyword_id': subItem.itemId,
            'keyword_phrase_id': subItem.subItemId,
            'temp_keyword_id': subItem.tempItemId,
            'temp_keyword_phrase_id': subItem.tempSubItemId,
            'keyword_phrase': subItem.properties['keyword_phrase'],
            'language': subItem.properties['language'],
            'boost': subItem.properties['boost']
        }
    }
    
    async _getSyncSubItems(): Promise<SyncSubItem[] | null> {
        const keywordPhrases = await getServicesManager().getKeywordPhrases()
        if (!keywordPhrases) return null
        return keywordPhrases.map((v) => this._transformToSyncSubItem(v))
    }

    async _addSyncSubItem(item: SyncSubItem): Promise<SyncSubItem | null> {
        const keywordPhrase = this._transformToKeywordPhrase(item)
        const newKeywordPhrase = await getServicesManager().updateKeywordPhrase(keywordPhrase.keyword_id, null, keywordPhrase.keyword_phrase, keywordPhrase.boost, keywordPhrase.language, null)
        if (!newKeywordPhrase || typeof newKeywordPhrase === 'boolean') return null
        return this._transformToSyncSubItem(newKeywordPhrase)
    }

    async _updateSyncSubItem(item: SyncSubItem, previousSubItem: SyncSubItem): Promise<boolean> {
        const keywordPhrase = this._transformToKeywordPhrase(item)
        const success = await getServicesManager().updateKeywordPhrase(keywordPhrase.keyword_id, keywordPhrase.keyword_phrase_id, keywordPhrase.keyword_phrase, keywordPhrase.boost, keywordPhrase.language, null)
        return success ? true : false
    }

    async _removeSyncSubItem(item: SyncSubItem): Promise<boolean> {
        const keywordPhrase = this._transformToKeywordPhrase(item)
        const success = await getServicesManager().updateKeywordPhrase(keywordPhrase.keyword_id, keywordPhrase.keyword_phrase_id, keywordPhrase.keyword_phrase, keywordPhrase.boost, keywordPhrase.language, true)
        return success ? true : false
    }

    _compareSyncSubItems(a: SyncSubItem, b: SyncSubItem): boolean {
        const keywordPhraseA = this._transformToKeywordPhrase(a)
        const keywordPhraseB = this._transformToKeywordPhrase(b)
        return keywordPhraseA.keyword_phrase === keywordPhraseB.keyword_phrase && keywordPhraseA.language === keywordPhraseB.language && keywordPhraseA.boost === keywordPhraseB.boost
    }

    _constructDefaultSyncSubItem(item: SyncItem): SyncSubItem {
        const defaultKeywordPhrase: KeywordPhrase = {
            'keyword_id': item.itemId,
            'temp_keyword_id': item.tempItemId,
            'keyword_phrase_id': DEFAULT_ID,
            'temp_keyword_phrase_id': uuid(),
            'keyword_phrase': '',
            'boost': false,
            'language': DEFAULT_LANGUAGE_SETTING.ENGLISH,
        }
        return this._transformToSyncSubItem(defaultKeywordPhrase)
    }

    _renderAddKeywordPhrase(onSubItemAddition: () => void): JSX.Element {
        return <div onClick={onSubItemAddition} className={"cursor-pointer bg-white w-fit h-fit pt-1 pb-1 pl-2 pr-2 opacity-80 hover-opacity-100 flex justify-center items-center text-center hover:bg-slate-200"} 
        style={{
            'boxShadow': 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            'borderRadius': '10px',
        }}
        >
            <Typography variant="largeParagraph">
                {"+"}
            </Typography>
        </div>
    }

    _renderKeywordPhrase(subItem: SyncSubItem, onSubItemModification: (subItemId: string, properties: any) => void, onSubItemRemoval: (subItemId: string) => void): JSX.Element | null {
        const subItemId = subItem.subItemId !== DEFAULT_ID ? subItem.subItemId : subItem.tempSubItemId
        if (!subItemId) return null
        const keywordPhrase = this._transformToKeywordPhrase(subItem)
        return <div  style={{'width': '110x', 'maxWidth': '120px'}}><EditableAutosizedInput 
            prompt={keywordPhrase.keyword_phrase} 
            maxNumber={1}
            blurOnEnter={true}
            placeholder={"Add word/phrase"}
            fontWeight={400}
            editFunctions={{
            addEntry: () => {},
            updateEntry: (_: number, updatedEntry: string) => onSubItemModification(subItemId!, {...subItem.properties, 'keyword_phrase': updatedEntry}),
            removeEntry: (_: number) => onSubItemRemoval.bind(this)(subItemId!)
            }}
            idx={0} numberLeft={2} /></div>
    }

    _renderSubItems(item: SyncItem, subItems: SyncSubItem[], 
        onSubItemModification: (subItemId: string, properties: any) => void, onSubItemRemoval: (subItemId: string) => void, onSubItemAddition: () => void): JSX.Element {
        return <div className="w-full flex flex-row flex-wrap gap-2 items-center">
        <Typography variant="largeParagraph">
            Phrases:
        </Typography>
        {subItems.map((v) => this._renderKeywordPhrase(v, onSubItemModification.bind(this), onSubItemRemoval.bind(this)))}
        {this._renderAddKeywordPhrase(onSubItemAddition.bind(this))}
        </div>
    }

    render() {
        return <SyncEngine 
            getGroups={this._getGroups.bind(this)}
            getGroupMemberships={this._getGroupMemberships.bind(this)}
            getItems={this._getSyncItems.bind(this)}
            getSubItems={this._getSyncSubItems.bind(this)}

            addGroup={this._addGroup.bind(this)}
            addGroupMembership={this._addGroupMembership.bind(this)}
            addItem={this._addSyncItem.bind(this)}
            addSubItem={this._addSyncSubItem.bind(this)}

            updateGroup={this._updateGroup.bind(this)}
            updateItem={this._updateSyncItem.bind(this)}
            updateSubItem={this._updateSyncSubItem.bind(this)}

            removeGroup={this._removeGroup.bind(this)}
            removeGroupMembership={this._removeGroupMembership.bind(this)}
            removeItem={this._removeSyncItem.bind(this)}
            removeSubItem={this._removeSyncSubItem.bind(this)}

            compareGroupProperties={this._compareGroupProperties.bind(this)}
            compareItemProperties={this._compareSyncItems.bind(this)}
            compareSubItemProperties={this._compareSyncSubItems.bind(this)}

            constructDefaultGroup={this._constructDefaultGroup.bind(this)}
            constructDefaultGroupMemberships={this._constructDefaultGroupMemberships.bind(this)}
            constructDefaultItem={this._constructDefaultSyncItem.bind(this)}
            constructDefaultSubItem={this._constructDefaultSyncSubItem.bind(this)}

            renderGroupInfo={this._renderGroupInfo.bind(this)}
            renderItemInfo={this._renderItemInfo.bind(this)}
            renderSubItems={this._renderSubItems.bind(this)}

            showSubItemsOnNewLine={true}

        />
    }
}