import { useEffect, useState } from 'react'
import { AlertTriangle, Info, Plus } from 'lucide-react'
import * as Tooltip from '@radix-ui/react-tooltip'
import { ShakenStirRegistration } from 'components/ShakenStirRegistration';
import { CustomerProfileData } from 'interfaces/twilio';
import { AuthorizationInfo } from 'components/Authentication/utils';
import { convertFromReduxSafeUserState } from 'lib/redux/store';
import { RootState } from 'store';
import { connect } from 'react-redux';
import { UserDataResult } from 'interfaces/services';
import { getServicesManager } from 'services';

export type STIRSHAKENData = {
  name: string;
  type: string;
  industry: string;
  businessRegistrationAuthority: string;
  businessRegistrationNumber: string;
  regionsOfOperation: string;
  websiteURL: string;
  streetAddress: string;
  city: string;
  stateOrRegion: string;
  postalCode: string;
  countryCode: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
  jobLevel: string;
}

type STIRSHAKENComponentProps = {
  authorizationInfo: AuthorizationInfo | null
  user: UserDataResult | null
  isAdmin: boolean
}

function STIRSHAKENComponentImpl({ authorizationInfo, user, isAdmin }: STIRSHAKENComponentProps) {
  const [showStirShakenForm, setShowStirShakenForm] = useState(false)
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false)
  const [stirShakenData, setStirShakenData] = useState<CustomerProfileData | null>(null)
  
  useEffect(() => {
    if (!user?.team_id) return  // not `if (user)` because it confuses the exhaustive-deps eslint checker
    getServicesManager().getCustomerProfileData(user.team_id).then((data) => {
      setStirShakenData(data)
    })
  }, [user?.team_id])  // useEffect runs if Object.is(old, new) is false which doesn't recurse into Objects
  const getStatusColor = (status: string) => {
    if (status === 'pending-review') return 'yellow'
    if (status === 'in-review') return 'blue'
    if (status === 'draft') return 'gray'
    if (status === 'twilio-rejected') return 'red'
    return 'green'
  }

  const getStatusText = (status: string) => {
    if (status === 'pending-review') return 'Pending Review'
    if (status === 'in-review') return 'In Review'
    if (status === 'draft') return 'Draft'
    if (status === 'twilio-rejected') return 'Rejected'
    return 'Approved'
  }

  const renderStirShakenStatus = (status: string) => {
    const color = getStatusColor(status)
    return <span className={`text-${color}-500`}>{getStatusText(status)}</span>
  }

  return (
    <div className="bg-white shadow-sm border border-gray-200 rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-4 text-gray-700 flex items-center justify-between">
        <span className="flex items-center">
          STIR/SHAKEN Status
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <button className="ml-2 text-gray-400 hover:text-gray-600">
                  <Info size={16} />
                </button>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content className="bg-gray-800 text-white p-2 rounded-md text-sm max-w-xs">
                  STIR/SHAKEN is a protocol that helps prevent caller ID spoofing and improves call authentication. Adding this information enhances your credibility and helps prevent your calls from being marked as spam.
                  <Tooltip.Arrow className="fill-gray-800" />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        </span>
        {!stirShakenData && (
          <AlertTriangle className="text-yellow-500" size={20} />
        )}
      </h2>
      {!submittedSuccessfully && stirShakenData ? (
        // if the status is pending-review, in-review, or draft, we should show the status as such
        // if the status is twilio-rejected, we should show the status as such and give the user an ability to resubmit
        // if the status is draft, we should show the status as such, and give the user an ability to submit
        // if the status is twilio-approved, we should show the status as such, and give the user an ability to know that via status color
        <div className="flex flex-col gap-4">
          <p>Status: {renderStirShakenStatus(stirShakenData.profile.status)}</p>
          {isAdmin && stirShakenData.profile.status === 'twilio-rejected' && (
            <button
              onClick={() => setShowStirShakenForm(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200 flex items-center"
            >
              <Plus size={20} className="mr-2" />
              Resubmit STIR/SHAKEN Info
            </button>
          )}
        </div>
      ) : submittedSuccessfully ? (
        <div>
          <p>STIR/SHAKEN information submitted successfully</p>
          <p>Check back later to see the status</p>
        </div>
      ) : (
        <div>
          <p className="mb-4">You haven't submitted STIR/SHAKEN information yet. This is important for call authentication and preventing your calls from being marked as spam. Only admins on your account can submit this.</p>
          {isAdmin && <button
            onClick={() => setShowStirShakenForm(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200 flex items-center"
          >
            <Plus size={20} className="mr-2" />
            Add STIR/SHAKEN Info
          </button>}
        </div>
      )}  

      {showStirShakenForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 overflow-y-auto">
          <div className="bg-white rounded-lg p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
              <ShakenStirRegistration
                  apiKey={authorizationInfo?.apiKey ?? ''}
                  teamId={user?.team_id ?? ''}
                  teamName={'Your team'}
                  onCancel={() => setShowStirShakenForm(false)}
                  onSuccess={() => {
                    setShowStirShakenForm(false)
                    setSubmittedSuccessfully(true)
                  }}
                />
          </div>
        </div>
      )}
    </div>
  )
}

const ReduxWrapped = connect((state: RootState) => {
  return {
      user: convertFromReduxSafeUserState(state.user),
      authorizationInfo: state.authenticationInfo.value,
      isAdmin: state.adminWrite.value ? state.adminWrite.value.team_ids.length > 0 : false,
  }
})(STIRSHAKENComponentImpl)

export { ReduxWrapped as STIRSHAKENComponent}
