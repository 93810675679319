import React, { RefObject } from "react";
import { getServicesManager } from "services";
import { Typography } from "interfaces/typography";
import './input.css'
import { AudioDataResult, AudioResult, SpeechTurn } from "interfaces/services";
import { max as dateFnsMax} from 'date-fns'
import { MS_TO_SEC, SEC_TO_MS } from "cfg/const";
import './input.css'
import { AudioPlayerBar, SPEEDS } from "components/Slider";
import { prospectPartyCode } from "cfg/endpoints";

type ButtonProps = {
  onPlayerClick: () => void
  play: boolean
}


type ProgressBarProps = {
  isMainView?: boolean
  currentTime: number
  updateCurrentTime: (percentageComplete: number) => void, 
  endTime?: number
}

const copySvg = <svg width="100%`" height="100%" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_2837_7104)">
<path d="M199.101 142.2V170.6H426.701C442.401 170.6 455.101 183.4 455.101 199V426.6C455.101 442.3 442.301 455 426.701 455H199.101C183.401 455 170.701 442.2 170.701 426.6V199C170.701 183.3 183.501 170.6 199.101 170.6V142.2V113.8C152.001 113.8 113.801 152 113.801 199.1V426.7C113.801 473.8 152.001 512 199.101 512H426.701C473.801 512 512.001 473.8 512.001 426.7V199.1C512.001 152 473.801 113.8 426.701 113.8H199.101V142.2Z" fill="black"/>
<path d="M398.2 142.2V85.3C398.2 38.2 360 0 312.9 0H85.3C38.2 0 0 38.2 0 85.3V312.9C0 360 38.2 398.2 85.3 398.2H142.2C157.9 398.2 170.6 385.5 170.6 369.8C170.6 354.1 157.9 341.4 142.2 341.4H85.3C69.6 341.4 56.9 328.6 56.9 313V85.4C56.9 69.7 69.7 57 85.3 57H312.9C328.6 57 341.3 69.7 341.3 85.4V142.3C341.3 158 354 170.7 369.7 170.7C385.4 170.7 398.2 157.9 398.2 142.2Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_2837_7104">
<rect width="512" height="512" fill="white"/>
</clipPath>
</defs>
</svg>

const popoutSvg = <svg width="" height="" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.9764 0.735294V0.563725L19.9274 0.465686L19.8783 0.392157L19.7558 0.245098L19.5597 0.0980391L19.4862 0.0490196L19.3881 0H13.7019C13.5731 -2.71314e-09 13.4456 0.0253585 13.3267 0.0746278C13.2077 0.123897 13.0997 0.196112 13.0086 0.28715C12.9176 0.378188 12.8454 0.486266 12.7961 0.605212C12.7468 0.724159 12.7215 0.851645 12.7215 0.980392C12.7215 1.10914 12.7468 1.23663 12.7961 1.35557C12.8454 1.47452 12.9176 1.5826 13.0086 1.67363C13.0997 1.76467 13.2077 1.83689 13.3267 1.88616C13.4456 1.93543 13.5731 1.96078 13.7019 1.96078H16.643L7.84403 10.6863C7.7523 10.778 7.67953 10.8869 7.62988 11.0068C7.58024 11.1266 7.55469 11.2551 7.55469 11.3848C7.55469 11.5145 7.58024 11.643 7.62988 11.7628C7.67953 11.8827 7.7523 11.9916 7.84403 12.0833C7.93576 12.1751 8.04466 12.2478 8.16452 12.2975C8.28437 12.3471 8.41283 12.3727 8.54256 12.3727C8.67229 12.3727 8.80074 12.3471 8.9206 12.2975C9.04045 12.2478 9.14935 12.1751 9.24109 12.0833L18.0401 3.28431V6.22549C18.0401 6.48551 18.1434 6.73487 18.3273 6.91873C18.5111 7.10259 18.7605 7.20588 19.0205 7.20588C19.2805 7.20588 19.5299 7.10259 19.7137 6.91873C19.8976 6.73487 20.0009 6.48551 20.0009 6.22549V0.906863C19.9978 0.849062 19.9896 0.791648 19.9764 0.735294Z" fill="black"/>
<path d="M2.94118 19.9258H14.5588C15.3389 19.9258 16.087 19.6159 16.6386 19.0643C17.1901 18.5127 17.5 17.7647 17.5 16.9846V10.0483C17.5 9.78831 17.3967 9.53895 17.2129 9.35509C17.029 9.17123 16.7796 9.06794 16.5196 9.06794C16.2596 9.06794 16.0102 9.17123 15.8264 9.35509C15.6425 9.53895 15.5392 9.78831 15.5392 10.0483V16.9846C15.5392 17.2446 15.4359 17.494 15.2521 17.6778C15.0682 17.8617 14.8188 17.965 14.5588 17.965H2.94118C2.68116 17.965 2.43179 17.8617 2.24793 17.6778C2.06408 17.494 1.96078 17.2446 1.96078 16.9846V5.36696C1.96078 5.10694 2.06408 4.85757 2.24793 4.67372C2.43179 4.48986 2.68116 4.38657 2.94118 4.38657H9.87745C10.1375 4.38657 10.3868 4.28327 10.5707 4.09942C10.7546 3.91556 10.8578 3.66619 10.8578 3.40617C10.8578 3.14616 10.7546 2.89679 10.5707 2.71293C10.3868 2.52907 10.1375 2.42578 9.87745 2.42578H2.94118C2.16113 2.42578 1.41303 2.73565 0.861451 3.28723C0.309873 3.83881 0 4.58691 0 5.36696V16.9846C0 17.7647 0.309873 18.5127 0.861451 19.0643C1.41303 19.6159 2.16113 19.9258 2.94118 19.9258Z" fill="black"/>
</svg>


const ProgressBar = (props: ProgressBarProps) => {
  const handleProgressChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (props.endTime) { 
      props.updateCurrentTime(parseFloat(evt.target.value)/100)
    }
  };

  function _formatMsToString(time: number) {
    const timeInSecond = (time * MS_TO_SEC)
    const minutes = Math.floor((timeInSecond / 60)).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
    const seconds = Math.round((timeInSecond % 60)).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
    return minutes + ":" + seconds
  }

  const coachingView = () => (
      <div className="flex flex-row gap-2">
        <div className="flex flex-row gap-1">
          <Typography variant="caption">{_formatMsToString(props.currentTime)}</Typography>
          <Typography variant="caption">/</Typography>
          <Typography variant="caption">{_formatMsToString(props.endTime ?? 0)}</Typography>
        </div>
        <input className="cursor-pointer"
          type="range"
          defaultValue={0}
          value={props.endTime ? (100*props.currentTime / props.endTime) : 0}
          onChange={handleProgressChange.bind(this)}
        />
      </div>)

  const mainView = () => (
    <div>
        <input className="cursor-pointer w-full range" style={{'height': '1px'}}
          type="range"
          defaultValue={0}
          value={props.endTime ? (100*props.currentTime / props.endTime) : 0}
          onChange={handleProgressChange.bind(this)}
        />
    </div>
  )

  return props.isMainView ? mainView() : coachingView()

}

const Pause = <svg className="w-5/6 h-5/6" viewBox="0 0 40 60">
<polygon points="0,0 15,0 15,60 0,60" />
<polygon points="25,0 40,0 40,60 25,60" />
</svg>

const Play = <svg className="w-5/6 h-5/6" viewBox="0 0 50 60">
<polygon points="0,0 50,30 0,60" />
</svg>

function Button (props: ButtonProps){
  return (
    <div onClick={() => props.onPlayerClick()} className={"hover:bg-slate-300 cursor-pointer"} style={{'width': '100%', 'height': '100%', 'padding': '8px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'border': "1px solid rgba(91, 101, 124, 0.25)", 'borderRadius': '50%', 'boxShadow': '0px 3px 3px rgb(0 0 0 / 10%)'}}>
      {props.play ? Pause : Play}
    </div>
  )
}

type AudioElements = {
  start: number,
  duration: number,
  ref: RefObject<HTMLAudioElement>
  element: JSX.Element
}


type AudioPlayerState = {
  timer?: NodeJS.Timer
  audioElements?: AudioElements[]
  durationInMs?: number,
  speedIdx: number,
  prospectNotRecorded?: boolean,
}

export type AudioPlayerDetails = {
  data: AudioDataResult | null
  sessionId?: string,
  isInView?: boolean,
}


export type AudioPlayerProps = {
  isPureTranscriptView?: boolean
  isMainView?: boolean
  details: AudioPlayerDetails,
  turns: SpeechTurn[],
  currentTimeInMS: number,
  play: boolean,
  previousPlayState: boolean,
  updateTime: (currentTime?: number, isActive?: boolean, prevActive?: boolean) => void
  copyTranscriptOnClick?: () => void
}

async function fetchAndDecodeAudio(context: AudioContext, url: string): Promise<AudioBuffer | null> {
  try {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      return await context.decodeAudioData(arrayBuffer);
  } catch (error) {
      console.error('Failed to fetch or decode audio:', error);
      return null;
  }
}

async function combineAndDownloadAudio(filename: string, audioElements: AudioElements[]): Promise<void> {
  const audioContext = new (window.AudioContext)();

  // Fetch and decode both audio files, or use silence if null
  type AudioSegment = {'buffer': AudioBuffer, 'offset': number};
  const audioSegments: AudioSegment[] = (await Promise.all(audioElements.map(async a => {
      const url = a.ref.current?.src;
      const buffer = url?.length ? await fetchAndDecodeAudio(audioContext, url) : null;
      return buffer === null ? null : {
        'buffer': buffer,
        'offset': Math.round(audioContext.sampleRate * a.start / 1000),
      };
  }))).filter((a): a is AudioSegment => a !== null);

  // If both are null, nothing to do
  if (!audioSegments.length) {
      console.error('All audio sources are null. No audio to combine.');
      return;
  }

  // Determine the max length of both buffers
  // The AudioContext API guarantees that .decodeAudioData() produced buffers in audioContext's native sample rate.
  const maxLength = Math.max(...audioSegments.map(a => a.buffer.length + a.offset));
  const numberOfChannels = Math.max(...audioSegments.map(a => a.buffer.numberOfChannels || 1));

  // Create a new AudioBuffer with the combined length and channels
  const combinedBuffer = audioContext.createBuffer(numberOfChannels, maxLength, audioContext.sampleRate);

  // Function to mix audio data into the combined buffer
  function mixBuffer(sourceBuffer: AudioBuffer | null, targetBuffer: AudioBuffer, channelOffset: number = 0, sampleOffset: number = 0): void {
      if (!sourceBuffer) return;
      for (let channel = 0; channel < sourceBuffer.numberOfChannels; channel++) {
          const sourceData = sourceBuffer.getChannelData(channel);
          const targetData = targetBuffer.getChannelData(channel + channelOffset);
          for (let i = 0; i < sourceData.length; i++) {
              targetData[i + sampleOffset] += sourceData[i];
          }
      }
  }

  // Mix audioSegments into combinedBuffer
  for (const audioSegment of audioSegments) {
    mixBuffer(audioSegment.buffer, combinedBuffer, 0, audioSegment.offset);
  }

  // Encode the combined buffer to a WAV file
  const wavBlob = await encodeWAV(combinedBuffer);

  // Create a downloadable link
  const url = URL.createObjectURL(wavBlob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = `${filename}.wav`;
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(url);
}

function writeUTFBytes(view: DataView, offset: number, string: string): void {
  for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
  }
}

async function encodeWAV(audioBuffer: AudioBuffer): Promise<Blob> {
  const numberOfChannels = audioBuffer.numberOfChannels;
  const length = audioBuffer.length * numberOfChannels * 2 + 44;
  const buffer = new ArrayBuffer(length);
  const view = new DataView(buffer);

  writeUTFBytes(view, 0, 'RIFF');
  view.setUint32(4, length - 8, true);
  writeUTFBytes(view, 8, 'WAVE');
  writeUTFBytes(view, 12, 'fmt ');
  view.setUint32(16, 16, true);
  view.setUint16(20, 1, true);
  view.setUint16(22, numberOfChannels, true);
  view.setUint32(24, audioBuffer.sampleRate, true);
  view.setUint32(28, audioBuffer.sampleRate * numberOfChannels * 2, true);
  view.setUint16(32, numberOfChannels * 2, true);
  view.setUint16(34, 16, true);
  writeUTFBytes(view, 36, 'data');
  view.setUint32(40, length - 44, true);

  let offset = 44;
  for (let i = 0; i < audioBuffer.length; i++) {
      for (let channel = 0; channel < numberOfChannels; channel++) {
          const sample = Math.max(-1, Math.min(1, audioBuffer.getChannelData(channel)[i]));
          view.setInt16(offset, sample * 0x7FFF, true);
          offset += 2;
      }
  }

  return new Blob([view], { type: 'audio/wav' });
}


export class AudioPlayer extends React.Component<AudioPlayerProps, AudioPlayerState> {
    _timerInterval: number = 250
    _rangeRef: React.RefObject<HTMLInputElement> = React.createRef()


    constructor(props: AudioPlayerProps) {
        super(props)
        this.state = {
            speedIdx: 0,
            ...this._transformAudioData(this.props.details.data),
        }
    }

    _formatMsToString(time: number) {
      const timeInSecond = (time * MS_TO_SEC)
      const minutes = Math.floor((timeInSecond / 60)).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
      const seconds = Math.round((timeInSecond % 60)).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
      return minutes + ":" + seconds
    }

    _transformAudioData(data: AudioDataResult | null) {
      if (!data || !data.audio) return {'audioElements': undefined, 'durationInMs': undefined, 'prospectNotRecorded': undefined}
      const start = data.session.scheduled_start

      const prospectNotRecorded = data.audio.find((value: AudioResult) => value.party_code === prospectPartyCode) === undefined

      const adjustedAudioMap = data.audio.map((x, idx) => {return {'value': x, 'idx': idx}}).filter((v) => v.value.audio_status === 'UPLOADED' )
      const end = dateFnsMax(adjustedAudioMap.map((x) => x.value.end))
      const audioElements: AudioElements[] = adjustedAudioMap.map((val) => {
        const audioRef: RefObject<HTMLAudioElement> = React.createRef()
        const audioElement: JSX.Element = <audio ref={audioRef} src={data.audio_links![val.idx]} />
        return {
          'start': val.value.start.getTime() - start.getTime(),
          'duration': val.value.end.getTime() - val.value.start.getTime(),
          'ref': audioRef,
          'element': audioElement,
        }
      })
      return {'audioElements': audioElements, 'durationInMs': end.getTime() - start.getTime(), 'prospectNotRecorded': prospectNotRecorded}
    }

    _updateCurrentTimePercentage(percentageComplete: number) {
      this.props.updateTime(percentageComplete*(this.state.durationInMs ?? 0), undefined)
    }

    _updateCurrentTimeNumber(timeInMS: number) {
      this.props.updateTime(timeInMS, this.props.play)
    }

    componentDidUpdate(prevProps: Readonly<AudioPlayerProps>, prevState: Readonly<AudioPlayerState>, snapshot?: any): void {
      // props just updated with the audio data to go fetch - same session
      if (this.props.details.data && !prevProps.details.data && prevProps.details.sessionId === this.props.details.sessionId) {
        this.setState({...this._transformAudioData(this.props.details.data)})
      } else if (this.props.details.sessionId !== prevProps.details.sessionId) {
        // session changes have to clear everything
        clearInterval(this.state.timer)
        this.setState({'speedIdx': 0, ...this._transformAudioData(this.props.details.data)})
      }

      if (prevState.speedIdx !== this.state.speedIdx && this.props.play) {
          this.state.audioElements?.forEach((value: AudioElements) => {
            if (value.ref.current && !value.ref.current.paused) {
              value.ref.current.playbackRate = SPEEDS[this.state.speedIdx]
            }
          })
      }
      
      if (prevProps.currentTimeInMS !== this.props.currentTimeInMS) {
        if (this.props.play) {
          this.state.audioElements?.forEach((value: AudioElements) => {
            if (value.ref.current && value.ref.current.paused && value.start < this.props.currentTimeInMS && (value.duration + value.start) > this.props.currentTimeInMS) {
              value.ref.current.currentTime = (this.props.currentTimeInMS - value.start)*MS_TO_SEC
              value.ref.current.playbackRate = SPEEDS[this.state.speedIdx]
              value.ref.current.play()
            } 
          })
        }
    }

    if (prevProps.details.sessionId === this.props.details.sessionId && prevProps.play && !this.props.play) {
      clearInterval(this.state.timer)
      this.setState((state) => { return {
        'timer': undefined,
        }})
      this.state.audioElements?.forEach((value: AudioElements) => {
        if (value.ref.current) {
          value.ref.current.pause()
      }})
    }

      // stopping/playing elements 
      if (prevProps.details.isInView && !this.props.details.isInView) {
        this._pause()
      } else if (prevProps.details.sessionId === this.props.details.sessionId  && !prevProps.play && this.props.play) {
        const timer = this._startTimer()
        this.setState({'timer': timer})
      }
    }

    _startTimer(): NodeJS.Timer {
      return setInterval(() => {
        if (this.state.durationInMs && this.props.currentTimeInMS > this.state.durationInMs) {
          this._pause(true)
          return null
        } 
        this._updateCurrentTimeNumber(this.props.currentTimeInMS + SPEEDS[this.state.speedIdx]*this._timerInterval)
      }, this._timerInterval)
    }

    _pause(fromEnd?: boolean){
      this.props.updateTime(undefined, false, fromEnd ? false : this.props.play)
    }

    _play() {
      if (this.state.durationInMs && this.props.currentTimeInMS > this.state.durationInMs) {
        this.props.updateTime(0, true)
      } else {
        this.props.updateTime(undefined, true)
      }
    }

    _toggleState() {
      this.props.play ? this._pause() : this._play()
    }

    _downloadAudio() {
      if (this.state.audioElements && this.state.audioElements.length > 1) {
        const filename = `Trellus Audio Call - ${this.props.details.data?.session.session_id ?? ''}`
        const audioElements = this.state.audioElements
        combineAndDownloadAudio(filename, this.state.audioElements)
      }
    }

    _renderMainView() {
      return (
        <div className="w-full flex flex-row items-center justify-center">   
        <AudioPlayerBar 
        downloadAudio={() => this._downloadAudio.bind(this)()}        
        prospectNotRecorded={this.state.prospectNotRecorded} 
        onMouseDown={() => this._pause.bind(this)()} 
        onMouseUp={() => {if(this.props.previousPlayState) this._play.bind(this)()}} 
        turns={this.props.turns} 
        currentTime={this.props.currentTimeInMS} 
        endTime={this.state.durationInMs} 
        updateCurrentTime={this._updateCurrentTimePercentage.bind(this)}
        play={this.props.play}
        toggleState={this._toggleState.bind(this)}
        copyTranscriptOnClick={this.props.copyTranscriptOnClick}
        userId={this.props.details.data?.session.user_id}
        speedIdx={this.state.speedIdx}
        updateSpeedIdx={(idx: number) => this.setState({'speedIdx': idx})}
        />
        <div style={{'display': 'none'}}>
          {this.state.audioElements?.map((value: AudioElements) => {
            return <div>{value.element}</div>})
          }
        </div>
           </div>
   
         );
    }

    _renderCoachingView() {
      return (
      <div className="w-full flex flex-col items-center justify-center">
      <div onMouseDown={() => this._pause.bind(this)()} onMouseUp={() => {if(this.props.previousPlayState) this._play.bind(this)()}}>
        <ProgressBar updateCurrentTime={this._updateCurrentTimePercentage.bind(this)} currentTime={this.props.currentTimeInMS} endTime={this.state.durationInMs}/>
      </div>

     <div className="w-full flex flex-row gap-1 items-center p-1 justify-center">
          <div style={{'width': '30px', 'minWidth': '30px', 'height': '30px', 'minHeight': '30px'}}>
            <Button play={this.props.play} onPlayerClick={this._toggleState.bind(this)} />
          </div>

        <div onClick={() => this.setState((state) => {return {'speedIdx': (state.speedIdx + 1)%SPEEDS.length}})} className={"hover:bg-slate-300 cursor-pointer"} 
        style={{'width': '30px', 'minWidth': '30px', 'height': '30px', 'minHeight': '30px', 'padding': '6px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'border': "1px solid rgba(91, 101, 124, 0.25)", 'borderRadius': '50%', 'boxShadow': '0px 3px 3px rgb(0 0 0 / 10%)'}}>
            <Typography variant="caption">
            {SPEEDS[this.state.speedIdx] + "x"} 
            </Typography>
          </div>

          {this.props.copyTranscriptOnClick ? <div onClick={() => {if (this.props.copyTranscriptOnClick) this.props.copyTranscriptOnClick()}} 
          className={"hover:bg-slate-300 cursor-pointer"} style={{'width': '30px', 'minWidth': '30px', 'height': '30px', 'minHeight': '30px', 'padding': '6px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'border': "1px solid rgba(91, 101, 124, 0.25)", 'borderRadius': '50%', 'boxShadow': '0px 3px 3px rgb(0 0 0 / 10%)'}}>
            <div className="w-full h-full">
              {copySvg}
            </div>
          </div> : null }

          {this.props.details.sessionId ? <div onClick={() => {window.open(`https://app.trellus.ai/transcripts?id=${this.props.details.sessionId}`)}} 
          className={"hover:bg-slate-300 cursor-pointer"} style={{'width': '30px', 'minWidth': '30px', 'height': '30px', 'minHeight': '30px', 'padding': '6px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'border': "1px solid rgba(91, 101, 124, 0.25)", 'borderRadius': '50%', 'boxShadow': '0px 3px 3px rgb(0 0 0 / 10%)'}}>
            <div className="w-full h-full">
              {popoutSvg}
            </div>
          </div> : null}


        <div style={{'display': 'none'}}>
          {this.state.audioElements?.map((value: AudioElements) => {
            return <div>{value.element}</div>})
          }
        </div>
        </div>

      {!this.props.isPureTranscriptView && this.state.prospectNotRecorded ? 
          <div className="w-full items-center justify-center text-center flex">
          <Typography variant="mediumParagraph">
            Prospect not recorded
          </Typography></div>
          : null}
      
      </div>

      );
    }

    render() {
      if (this.state.audioElements === undefined) return null
      return this.props.isMainView ? this._renderMainView() : this._renderCoachingView()
    }
  }

  export type AudioPlayerDataFetcherState = {
    data: AudioDataResult | null
    dataFetcherInterval?: NodeJS.Timer,
    dataFetcherTimeout?: NodeJS.Timeout,
  }


  export class AudioPlayerDataFetcher extends React.Component<AudioPlayerProps, AudioPlayerDataFetcherState> {
    _timerInterval: number = 10
    constructor(props: AudioPlayerProps) {
        super(props)
        this.state = {
          'data': null
        }
    }

    async _dataFetcher(): Promise<AudioDataResult | null> {
      if (!this.props.details.sessionId) return null
      return await getServicesManager().getAudioData(this.props.details.sessionId)
    }

    async _getAudioData() {
      const data = await this._dataFetcher()
      if (data && data.session.session_id !== this.props.details.sessionId) return
      if (!data || !data.audio || !data.audio_links || data.audio.length === 0 || data.audio_links.length === 0 || data.audio.filter((v: AudioResult) => v.audio_status === 'REALTIME_COMPLETE').length !== 0) {
          const dataFetcherInterval = setInterval(async () => {
            const data = await this._dataFetcher()
            if (data && data.audio && data.session.session_id === this.props.details.sessionId && data.audio_links && data.audio.length > 0 && data.audio_links.length > 0 && data.audio.filter((v: AudioResult) => v.audio_status === 'REALTIME_COMPLETE').length === 0) {
              clearInterval(dataFetcherInterval)
              this.setState({'data': data})
            }
          }, 3*SEC_TO_MS)

          const dataFetcherTimeout = setTimeout(() => {
            clearInterval(dataFetcherInterval)
            this.setState({'dataFetcherInterval': undefined, 'dataFetcherTimeout': undefined})
          }, 8*SEC_TO_MS)

          this.setState({'dataFetcherInterval': dataFetcherInterval, 'dataFetcherTimeout': dataFetcherTimeout})          
      } else {
        this.setState({'data': data})
      }
    }

    componentDidMount() {
      this._getAudioData()
    }

    componentWillUnmount(): void {
      clearInterval(this.state.dataFetcherInterval)
      clearTimeout(this.state.dataFetcherTimeout)
    }


    componentDidUpdate(prevProps: Readonly<AudioPlayerProps>, prevState: Readonly<AudioPlayerDataFetcherState>, snapshot?: any): void {
      if (prevProps.details.sessionId !== this.props.details.sessionId) {
        clearInterval(this.state.dataFetcherInterval)
        clearTimeout(this.state.dataFetcherTimeout)
        this.setState({'data': null}, () => this._getAudioData())
      } 
    }
 
    render() {
      return <AudioPlayer 
      copyTranscriptOnClick={this.props.copyTranscriptOnClick} 
      currentTimeInMS={this.props.currentTimeInMS} 
      play={this.props.play} 
      previousPlayState={this.props.previousPlayState} 
      updateTime={this.props.updateTime} 
      turns={this.props.turns}
      details={{...this.props.details, 'data': this.state.data}}
      />
    }
  }

  