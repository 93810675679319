import { OptionalTwilioNumber, RegisteredTwilioNumber } from "interfaces/db"
import { UserDataResult } from "interfaces/services"

export type CountryOptions = {
    code: string,
    name: string,
  }
  
export const defaultCountryOptions: CountryOptions[] = [
    { code: '+1', name: 'United States' },
    { code: '+1', name: 'Canada' },
]
  
  
export function format_number(number: string) {
    // may have country code...
    // get number in raw digits and then format
    number = number.replace(/\D/g, '')
    // format number
    if (number.length === 10) {
        number = `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`
    } else if (number.length === 11) {
        number = `+${number.slice(0, 1)} (${number.slice(1, 4)}) ${number.slice(4, 7)}-${number.slice(7)}`
    }
    return number
}

export function convertOptionalNumberToTwilioNumber(twilio_number: OptionalTwilioNumber, user: UserDataResult): RegisteredTwilioNumber {
    return {
        latitude: twilio_number.latitude,
        locality: twilio_number.locality,
        longitude: twilio_number.longitude,
        postal_code: twilio_number.postal_code,
        region: twilio_number.region,
        rep_phone_value: twilio_number.phone_number,
        user_id: user.user_id,
        nomorobo_spamscore: null,
    }
}