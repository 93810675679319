import React from "react";

export type DropDownMenuEntry = {
    label: string,
    onClick: () => void,
}

interface Props {
    activator: React.ReactNode,
    entries: DropDownMenuEntry[],
    alignmentClassName?: string,  // tailwind classes e.g. top-0 right-0 or top-0 left-0
}

type State = {
    open: boolean,
}

export class DropDownMenu extends React.Component<Props, State> {
    /**
     * A basic drop-down menu, displaying entries when activator is clicked.
     *
     * Uses tailwind's z-10 to raise the drop-down over later elements in the page, but that stops working at the
     * bounds of the nearest ancestor having a transform, a fractional opacity, or (in the case of relative- or
     * absolute-positioned ancestors) its own z-index. Plus some rarer cases. Hopefully you can avoid those. See:
     * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_positioned_layout/Understanding_z-index/Stacking_context
     *
     * TODO: Accessibility 😱
     */
    _wrapperRef : React.RefObject<HTMLDivElement> = React.createRef()

    constructor(props: Props) {
        super(props)
        this.state = {
            open: false,
        }
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    handleClickOutside(event: MouseEvent) {
        if (this._wrapperRef && this._wrapperRef.current && event.target && !this._wrapperRef.current.contains(event.target as Node)) {
            this.setState({'open': false})
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside)
    }

    private selectEntry(entry: DropDownMenuEntry) {
        this.setState({'open': false})
        entry.onClick()
    }

    render(): React.ReactElement {
        return <div ref={this._wrapperRef} className="relative">
            <div onClick={() => this.setState({'open': !this.state.open})}>{this.props.activator}</div>
            {this.state.open
                ? <ul className={"absolute text-xs border border-slate-300 whitespace-nowrap shadow-lg z-10 " + (this.props.alignmentClassName ?? "top-0 left-0")}>
                    {this.props.entries.map(item =>
                        <li
                            key={item.label}
                            onClick={(e) => { e.stopPropagation(); this.selectEntry(item) }}
                            className="bg-white hover:bg-slate-100 text-black py-1 px-2 cursor-pointer"
                        >
                            {item.label}
                        </li>
                    )}
                </ul>
                : undefined}
        </div>
    }
}
